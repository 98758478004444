import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import _ from 'lodash';
import { getThumbnail, getPreview } from '../../../utils/api-image-url';
import PlatformUtils from '../../../utils/platform';
import { settingsStore } from '../../../reflux/settingsStore';
import { videoPlayerStore, videoPlayerActions } from '../../../reflux/videoPlayerStore';

const SHOULD_SHOW_PREVIEW_HEADER = PlatformUtils.supportVideoPreviews();

export default class Background extends Reflux.Component {
  constructor(p) {
    super(p);
    this.state = {
      preview: null,
      poster: null,
      posterLoaded: false,
      transparentMode: false
    };

    this.mapStoreToState(videoPlayerStore, function (fromStore) {
      var obj = {
        videoPreviewMode: fromStore.videoPreviewMode,
        isPlaying: fromStore.isPlaying
      };
      return obj;
    });

    this.updateData = this.updateData.bind(this);
    this.playPreview = this.playPreview.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    let wasUnfocused = !prevProps.focused && this.props.focused;
    if (prevProps.item.id !== this.props.item.id || wasUnfocused) {
      this.updateData();
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  clear() {
    // if (this.posterLoader) {
    //   this.posterLoader.onload = null;
    // }
  }

  playPreview(src, poster) {
    videoPlayerActions.playVideoPreview({ src: src, poster: poster });
  }

  updateData() {
    const { item } = this.props;

    this.clear();
    if (
      (item.isLive &&
        (item.content_type === 'live_program' ||
          (item.content_type === 'stop' &&
            _.find(item.links || [], (item) => item.action === 'play')))) ||
      item.isLinear
    ) {
      return this.setState({
        preview: null,
        poster: null,
        posterLoaded: false,
        previewLoaded: false,
        transparentMode: true
      });
    }

    const preview =
      SHOULD_SHOW_PREVIEW_HEADER &&
      settingsStore.state.settings.shouldShowVideoPreview &&
      getPreview(item.resources, item.id);
    const poster = getThumbnail(item.resources, item.id, 'pageBg');

    this.setState({
      preview: preview.url,
      poster: poster,
      posterLoaded: !!poster,
      previewLoaded: !!preview,
      transparentMode: false
    });

    this.playPreview(preview.url, poster);

    if (poster) {
      this.posterLoader = new Image();
      this.posterLoader.src = poster;
      this.posterLoader.onload = () => {
        this.setState({ posterLoaded: true });
      };
    }
  }

  render() {
    const { poster, posterLoaded, preview, isPlaying, videoPreviewMode, transparentMode } =
      this.state;

    const previewLoaded = isPlaying && videoPreviewMode;

    const classNames = cx('home-featured-carousel-module__background', {
      'has-poster': !!poster,
      'poster-loaded': posterLoaded,
      'has-preview': !!preview?.url,
      'preview-loaded': previewLoaded,
      'transparent-mode': transparentMode
    });

    if (transparentMode) {
      return <div className={classNames} />;
    }

    return <div className={classNames}>{poster && !previewLoaded && <img src={poster} />}</div>;
  }
}
