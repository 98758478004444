export default function (item) {
  return !!(item?.isLive || item?.status?.code === 'live');
  // return !!(
  //   item?.isLive ||
  //   (
  //       item?.status?.code === 'live' &&
  //     (
  //       item?.content_type === 'live_event' &&
  //       item?.content_type === 'live_program'
  //     )
  //   )
  // );
}
