import React from 'react';
import { Button } from '../../utils/reactv-navigation';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';

export default class BaseButton extends Button {
  constructor(props) {
    super(props);

    this.wasFocused = props.focused || props.isFocused;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.focused && !prevProps.focused) {
      this.focus();
    }
    this.wasFocused = prevProps.focused || prevProps.isFocused;
    this.checkReadAria();
  }

  checkReadAria() {
    const isFocused = this.props.focused || this.props.isFocused;
    if (isFocused && isFocused !== this.wasFocused) {
      if (this.readAria) {
        var that = this;
        if (this.readTimeout) clearTimeout(this.readTimeout);
        this.readTimeout = setTimeout(function () {
          that.readAria();
        }, 300);
      }
    } else if (!isFocused && this.readTimeout) {
      clearTimeout(this.readTimeout);
      this.readTimeout = 0;
    }
  }

  onEnter(e) {
    console.log('BASE BUTTON ON ENTER');
    ariaTTSServiceStore.readText('', true, true);
    super.onEnter(e);
  }

  render() {
    return <div></div>;
  }
}
