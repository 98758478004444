import React, { useCallback } from 'react';
import PlatformUtils from '../../utils/platform.js';
import { HorizontalMenu } from '../../utils/reactv-menus';
import cardLookup, {
  lookupCardHorizontalOffset,
  getTypeForRow,
  lookupCardHorizontalVisibleItems,
  lookupCardScrollingValues
} from '../../config/cardLookup.js';
import ProgressBar from '../progress-bar/progress-bar';
import classnames from 'classnames';
import { consumptionStore } from '../../reflux/consumptionStore';
import { recentlyWatchedStore } from '../../reflux/recentlyWatchedStore';
import { isEqual, map } from 'lodash';
import { userAccountStore } from '../../reflux/userAccountStore';
import { paginationStore } from '../../reflux/paginationStore';
import { localizationStore } from '../../reflux/localizationStore';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';
import { configDataStore } from '../../reflux/configDataStore';
import { iTrackingStore } from '../../reflux/iTrackingStore';
import { liveChannelsActions } from '../../reflux/liveChannelsStore';
import * as Components from '../index';

const Cards = ({
  menuItems,
  currentFocus,
  rowId,
  homeRailId,
  rowType,
  rowItemType,
  stateRowType,
  type,
  isUserLoggedIn,
  isScheduleChangedToVideoList,
  cardOnEnter,
  isFocused,
  iTrackingRow,
  iTrackingLargest,
  currentFocusedRow,
  offset,
  badgeType,
  cardTypeUIOverride,
  disableFavorite
}) => {
  const style = PlatformUtils.isPS4
    ? { left: `${-offset}px` }
    : { transform: `translateX(${-offset}px)` };

  const getCards = useCallback(() => {
    if (!menuItems || !menuItems.length) {
      return [];
    }

    const focus = currentFocus;

    const threshold = stateRowType === 'LiveCard' ? 5 : 4;
    const idxToStartScrolling = Math.abs(lookupCardScrollingValues(stateRowType)) + threshold;
    const upperLimitPlaceholder = focus + idxToStartScrolling;
    const lowerLimitPlaceholder = focus - idxToStartScrolling;

    return map(menuItems, (card, idx) => {
      let cardType;
      const isPlaceholder = idx > upperLimitPlaceholder || idx < lowerLimitPlaceholder;

      if (card?.type === 'view-more') {
        cardType = cardLookup('view-more');
      } else if (homeRailId === 'continue_watching') {
        cardType = cardLookup('universal');
      } else if (homeRailId === 'hero_channels') {
        cardType = cardLookup('linear-channel');
      } else if (homeRailId === 'hero_cards') {
        cardType = cardLookup('page');
      } else if (homeRailId === 'favorites') {
        cardType = cardLookup('universal', card.type);
      } else if (type === 'generic' && rowItemType === 'mixed') {
        if (card?.type === 'page') {
          cardType = cardLookup('page');
        } else {
          cardType = cardLookup('mixed');
        }
      } else if (type && type !== 'generic') {
        cardType = cardLookup(type, rowType);
      } else {
        cardType = cardLookup(
          rowItemType || card?.type || card?.content_type || type,
          card?.content_type
        );
      }

      if (!cardType || !card) {
        return null;
      }

      const isWatching = card.id === consumptionStore.state.currentAssetID;
      const isFavorite = userAccountStore.isCardInUserFavorites(card.id);
      const focused = isFocused(idx);
      const getProgress = (id, homeRailId) => {
        if (recentlyWatchedStore.getPercentWatched(id)) {
          return recentlyWatchedStore.getPercentWatched(id);
        }
        if (homeRailId === 'continue_watching') {
          if (!recentlyWatchedStore.getPercentWatched(id)) {
            return recentlyWatchedStore.hasUserWatched(id).progress;
          }
        }
        return 0;
      };

      const info = Object.assign(card, {
        key: idx,
        isPlaceholder,
        isWatching,
        isScheduleChangedToVideoList,
        iTrackingRow,
        iTrackingIndex: idx,
        iTrackingLargest,
        currentFocusedRow,
        currentFocus,
        focused,
        isCurrentVOD:
          isWatching && !consumptionStore.state.isLinear && !card.isLinear && !card.isLive,
        progress: getProgress(card.id, homeRailId),
        isFavorite,
        isUserLoggedIn,
        disableMouseEvents: true,
        offleft: idx < currentFocus,
        index: idx,
        onClickHandler: cardOnEnter,
        rowType: stateRowType,
        badgeType,
        cardTypeUIOverride,
        disableFavorite
      });

      const Component = Components[cardType];
      return <Component {...info} />;
    });
  }, [
    menuItems,
    currentFocus,
    currentFocusedRow,
    rowId,
    rowType,
    rowItemType,
    stateRowType,
    type,
    isUserLoggedIn,
    isScheduleChangedToVideoList,
    cardOnEnter,
    badgeType,
    cardTypeUIOverride,
    disableFavorite
  ]);

  return (
    <div className="row" style={style}>
      {getCards()}
    </div>
  );
};

export default class CardMenu extends HorizontalMenu {
  constructor(props) {
    super(props);
    this.stores = [paginationStore, userAccountStore, iTrackingStore];
    this.wasFocused = false;
    this._cardOnEnter = this._cardOnEnter.bind(this);
    this.isItemFocused = this.isItemFocused.bind(this);
    this.header = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.setupNewData(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props, nextProps) ||
      !isEqual(this.state, nextState) ||
      this.props.FORCE_UPDATE === true
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const VIEW_MORE_LIMIT = 20;
    if (!this.props.menuItems || !this.props.menuItems.length) {
      return;
    }
    super.componentDidUpdate(prevProps, prevState);
    if (prevState.currentFocus !== this.state.currentFocus) {
      this.state.initialIndex = this.props.initialIndex;
      this.state.lastFocus = prevState.currentFocus;
      this.calculateCardMenuOffset();
      if (this.props.onChange) {
        this.props.onChange(this.state.currentFocus);
      }
      if (this.props.menuItems && this.props.menuItems.length) {
        const section = this.props.menuItems.slice(
          Math.max(this.state.currentFocus - 1, 0),
          Math.min(this.state.currentFocus + 5, this.props.menuItems.length)
        );
        this.setState({
          rowType: getTypeForRow({
            rowType: this.props.type,
            itemType: this.props.row.item_type,
            items: section,
            rowTypeUIOverride: this.props.row.rowTypeUIOverride
          })
        });
      }
      this._paginationCheck();
    }

    if (
      this.state.currentFocus > this.props.menuItems.length - 1 ||
      (this.state.initialIndex === VIEW_MORE_LIMIT &&
        this.props.menuItems.length > VIEW_MORE_LIMIT &&
        this.props.initialIndex !== VIEW_MORE_LIMIT &&
        this.props.id !== 'home' &&
        this.props.id !== 'featuredCarouselModuleCardsRail')
    ) {
      // This is for if we enter a shorter card row from a longer one.
      this.setState({
        currentFocus: this.props.menuItems.length - 1,
        initialIndex: VIEW_MORE_LIMIT / 2
      });
    }

    if (this.props.focused && this.props.focused !== this.wasFocused) {
      ariaTTSServiceStore.readText(
        this.props.row.label + ' list : ' + this.props.menuItems.length + ' items',
        true,
        true
      );
    }

    if (this.props.focused !== prevProps.focused) {
      if (this.props.focused && this.props.onFocus) this.props.onFocus();
    }

    this.wasFocused = this.props.focused;
    if (this.props.mid !== prevProps.mid) this.setupNewData(this.props);
  }

  calculateCardMenuOffset() {
    const {
      menuItems,
      type,
      row: { item_type: rowItemType },
      additionalOffsetIdx = 0
    } = this.props;
    const { currentFocus, lastFocus, offset } = this.state;
    let currentOffset = offset || 0;

    if (currentFocus >= 0 && currentFocus < menuItems.length) {
      const isNavigatedRight = lastFocus < currentFocus;
      const currentIndex = currentFocus - 1;
      const card = menuItems[currentIndex];
      let typeCardLookup = type === 'generic' ? rowItemType : type;
      switch (this.props.mid) {
        case 'hero_channels':
        case 'linear-channels':
          typeCardLookup = 'linear-channel';
          break;
        case 'favorites':
        case 'continue_watching':
          typeCardLookup = 'universal';
          break;
        default:
          break;
      }

      const cardType = cardLookup(typeCardLookup, card?.content_type);
      const cardWidth = lookupCardHorizontalOffset(cardType || card?.rowType) || 0;

      const idxToStartScrollingRight = lookupCardScrollingValues(cardType);

      if (currentFocus > 0) {
        // handling saving scroll on back action
        const scrollAfterFirstCard = cardType !== 'FeaturedCard' && this.props.noHeader !== true;
        currentOffset = cardWidth * (currentFocus - (scrollAfterFirstCard ? 1 : 0));

        const maxScrollableCards =
          menuItems.length - idxToStartScrollingRight - 1 + additionalOffsetIdx;

        const maxScroll = cardWidth * (maxScrollableCards > 0 ? maxScrollableCards : 0);
        if (currentOffset > maxScroll) currentOffset = maxScroll;
      } else {
        if (isNavigatedRight) {
          let maxScroll =
            cardWidth * (menuItems.length - idxToStartScrollingRight - 1 + additionalOffsetIdx);
          maxScroll = maxScroll > 0 ? maxScroll : 0;

          const allowScrolling =
            currentFocus + additionalOffsetIdx > idxToStartScrollingRight &&
            (currentFocus + additionalOffsetIdx - idxToStartScrollingRight) * cardWidth >=
              currentOffset + cardWidth;

          currentOffset = allowScrolling ? currentOffset + cardWidth : this.state.offset;

          if (currentOffset > maxScroll) currentOffset = maxScroll;
        } else {
          const allowScrolling =
            (currentFocus + additionalOffsetIdx - 1) * cardWidth < currentOffset - cardWidth;

          currentOffset = allowScrolling
            ? currentOffset - cardWidth > 0
              ? currentOffset - cardWidth
              : 0
            : this.state.offset;
          if (currentFocus === 0) currentOffset = 0;
        }
      }
    }

    this.setState({ offset: currentOffset });

    return currentOffset;
  }

  setupNewData(props) {
    if (props.menuItems && props.menuItems.length) {
      const initialIndex = this.props.initialIndex || 0;
      const section = props.menuItems.slice(
        Math.max(initialIndex - 1, 0),
        Math.min(initialIndex + 5, props.menuItems.length)
      );
      const type = getTypeForRow({
        rowType: this.props.type,
        itemType: this.props.row.item_type,
        items: section,
        rowTypeUIOverride: this.props.row.rowTypeUIOverride
      });

      this.state.rowType = type;
      this.state.currentFocus = initialIndex;
      this.calculateCardMenuOffset();
    }
  }

  _paginationCheck() {
    const { row } = this.props;
    if (!row) {
      return;
    }

    const { limit: pageSize, total } = row.meta;
    const pageMax = Math.ceil(total / pageSize);
    const currentPage = Math.ceil((this.state.currentFocus + 1) / pageSize);

    if (currentPage + 1 > pageMax) return;

    const nextPageBegins = currentPage * pageSize;
    const overThreshold = nextPageBegins - (this.state.currentFocus + 1) < pageSize / 2;
    const id = row.id;

    if (!paginationStore.getPageByCollectionId(id, currentPage + 1) && overThreshold) {
      // If we're halfway to the next page and we don't already have it stored, hit the API
      paginationStore.loadCollectionPage(id, pageSize, currentPage + 1, row);
    }
  }

  _cardOnEnter(idx) {
    const item = this.props.menuItems[idx];
    this.props.onEnter(item, idx);
    liveChannelsActions.updateFocusPosition(idx);
  }

  onEnter() {
    // stop tts,
    ariaTTSServiceStore.readText('', true, true);
    super.onEnter();
  }

  isTopResults() {
    return this.props.row.isTopResults;
  }

  getVisibleScrollCount() {
    let visibleSize = lookupCardHorizontalVisibleItems(this.refs);
    const firstItem = this.refs[0];
    if (firstItem && firstItem.state.focused) {
      // Adjust visible scroll size if focus is on 0th item
      visibleSize = visibleSize - 1;
    }
    return visibleSize;
  }

  isItemFocused(idx) {
    return this.props.focused && this.state.currentFocus === idx;
  }

  render() {
    if (!Array.isArray(this.props.menuItems) || this.props.menuItems.length === 0) {
      return null;
    }

    const label = localizationStore._GET_LABEL(this.props.row.label);
    const cx = classnames('row-wrapper', this.state.rowType, {
      LinearChannelCard: this.state.rowType === 'ChannelCard' && this.props.id === 'home',
      focused: this.props.focused
    });

    const rowClass = classnames({
      'card-row': true,
      focused: this.props.focused
    });

    const rowClassTitle = classnames({
      'card-row-header-title': true,
      focused: this.props.focused
    });

    // Used by progress bar to adjust bar's width depending on headers left margin
    const currentCard = this.props.menuItems && this.props.menuItems[this.state.currentFocus];
    return (
      <div className={rowClass}>
        {!this.props.noHeader && (
          <div className="card-row-header">
            <div ref={this.header} className={rowClassTitle}>
              {label}
            </div>
            {this.props.showProgressBar && currentCard && currentCard.type !== 'view-more' && (
              <ProgressBar
                typeCard={this.state.rowType}
                headerRef={this.header}
                currentPosition={this.state.currentFocus}
                focused={this.props.focused}
                totalLength={
                  configDataStore.getConstant('search_top_results_limit')
                    ? this.props.menuItems.length
                    : this.props.row.meta.total || this.props.menuItems.length
                }
              />
            )}
          </div>
        )}
        <div className={cx}>
          <Cards
            menuItems={this.props.menuItems}
            currentFocus={this.state.currentFocus}
            rowId={this.props.row.id}
            rowType={this.props.row.type}
            rowItemType={this.props.row.item_type}
            homeRailId={this.props.row.homeRailId}
            badgeType={this.props.row.badgeType}
            cardTypeUIOverride={this.props.row.cardTypeUIOverride}
            disableFavorite={this.props.row.disableFavorite}
            stateRowType={this.state.rowType}
            type={this.props.type}
            isUserLoggedIn={this.state.isUserLoggedIn}
            isScheduleChangedToVideoList={this.props.isScheduleChangedToVideoList}
            cardOnEnter={this._cardOnEnter}
            iTrackingRow={this.props.iTrackingRow}
            iTrackingLargest={
              this.state.inView[this.props.iTrackingRow] &&
              this.state.inView[this.props.iTrackingRow].largest
            }
            focused={this.props.focused}
            currentFocusedRow={this.props.currentFocusedRow}
            isFocused={this.isItemFocused}
            offset={this.state.offset}
          />
        </div>
      </div>
    );
  }
}
