import API from '../services/api.js';

var promise;
export default class sessionServiceDelegate {
  static getSessionData(localeOverride) {
    if (promise) return promise;
    else {
      promise = API.getSessionData(localeOverride)
        .then(sessionServiceDelegate.onSuccess)
        .catch(sessionServiceDelegate.onError);
    }

    promise.then(() => {
      promise = null;
    });

    return promise;
  }

  static onSuccess(result) {
    return new Promise(function (resolve, reject) {
      resolve(result);
    });
  }

  static onError(err) {
    return new Promise(function (resolve, reject) {
      let error = { errorCode: err };
      reject(error);
    });
  }
}
