import conf from '../../conf';
import Browser from './platforms/base.js';
import SamsungTizen from './platforms/samsung-tizen.js';
import Opera from './platforms/opera.js';
import PS5 from './platforms/ps5.js';
import PS4 from './platforms/ps4.js';
import PS3 from './platforms/ps3.js';
import Xbox from './platforms/xbox.js';
import VIDAA from './platforms/vidaa.js';
import Comcast from './platforms/comcast.js';
import Metrological from './platforms/metrological.js';
import Philips from './platforms/philips.js';
import Vestel from './platforms/vestel.js';
import Magenta from './platforms/magenta.js';
import HBBTV from './platforms/hbbtv.js';

export default function getPlatform(force) {
  if (conf.platform === 'tizen' || (force && force.toLowerCase() === 'tizen')) {
    return new SamsungTizen();
  } else if (conf.platform === 'vidaa' || (force && force.toLowerCase() === 'vidaa')) {
    return new VIDAA();
  } else if (conf.platform === 'opera' || (force && force.toLowerCase() === 'opera')) {
    return new Opera();
  } else if (conf.platform === 'ps5' || (force && force.toLowerCase() === 'ps5')) {
    return new PS5();
  } else if (conf.platform === 'ps4' || (force && force.toLowerCase() === 'ps4')) {
    return new PS4();
  } else if (conf.platform === 'ps3' || (force && force.toLowerCase() === 'ps3')) {
    return new PS3();
  } else if (conf.platform === 'xbox' || (force && force.toLowerCase() === 'xbox')) {
    return new Xbox();
  } else if (conf.platform === 'comcast' || (force && force.toLowerCase() === 'comcast')) {
    return new Comcast();
  } else if (
    conf.platform === 'metrological' ||
    (force && force.toLowerCase() === 'metrological')
  ) {
    return new Metrological();
  } else if (conf.platform === 'philips' || (force && force.toLowerCase() === 'philips')) {
    return new Philips();
  } else if (conf.platform === 'vestel' || (force && force.toLowerCase() === 'vestel')) {
    return new Vestel();
  } else if (conf.platform === 'magenta' || (force && force.toLowerCase() === 'magenta')) {
    return new Magenta();
  } else if (conf.platform === 'hbbtv' || (force && force.toLowerCase() === 'hbbtv')) {
    return new HBBTV();
  } else {
    return new Browser();
  }
}
