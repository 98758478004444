import React from 'react';
import CardRow from '../card-row/card-row';
import { listPageStore, listPageActions } from '../../reflux/listPageStore';
import _ from 'lodash';
import { iTrackingActions } from '../../reflux/iTrackingStore';
import { paginationStore } from '../../reflux/paginationStore';
import Reflux from 'reflux';
import { s } from '../../utils/screen-size';

export default class ListPanel extends Reflux.Component {
  constructor(p) {
    super(p);
    this.state = {
      index: p.backParams && p.backParams.savedIndex ? parseInt(p.backParams.savedIndex, 10) : 0,
      menuId: '',
      prevRow: {},
      currentRow: {},
      nextRow: {},
      prevRowItems: [],
      currentRowItems: [],
      nextRowItems: []
    };
    this.stores = [paginationStore];

    this.listPanelOnDown = this.listPanelOnDown.bind(this);
    this.listPanelOnUp = this.listPanelOnUp.bind(this);
    this.updatePositionIndex = this.updatePositionIndex.bind(this);
    this.onEnter = this.onEnter.bind(this);

    if (this.props.onTopRow) {
      this.props.onTopRow(this.props.focused && this.state.index === 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props, nextProps) ||
      !_.isEqual(this.state, nextState) ||
      this.props.FORCE_UPDATE === true
    );
  }

  listPanelOnDown() {
    const menuIdIndex = _.findIndex(
      this.props.collections,
      (item) => item.id === this.state.menuId
    );
    const currentIndex = menuIdIndex && menuIdIndex >= 0 ? menuIdIndex : this.state.index;
    if (currentIndex < this.props.collections.length - 1) {
      let index = currentIndex + 1;
      const newMenuId = this.props.collections[index].id;
      this.setState({
        index,
        menuId: newMenuId
      });
      this.props.onChange && this.props.onChange(index);
      if (this.props.onShouldMenuCollapse) {
        if (this.state.index === 0) {
          this.props.onShouldMenuCollapse(0);
        } else {
          this.props.onShouldMenuCollapse(1);
        }
      }
    } else {
      this.props.onDown && this.props.onDown();
    }
  }

  listPanelOnUp() {
    const menuIdIndex = _.findIndex(
      this.props.collections,
      (item) => item.id === this.state.menuId
    );
    const currentIndex = menuIdIndex && menuIdIndex >= 0 ? menuIdIndex : this.state.index;
    if (currentIndex > 0) {
      let index = currentIndex - 1;
      const newMenuId = this.props.collections[index].id;
      this.setState({
        index,
        menuId: newMenuId
      });
      this.props.onChange && this.props.onChange(index);
      if (this.props.onShouldMenuCollapse) {
        if (currentIndex === 0) {
          this.props.onShouldMenuCollapse(0);
        } else {
          this.props.onShouldMenuCollapse(1);
        }
      }
    } else {
      this.props.onUp && this.props.onUp();
    }
  }

  updatePositionIndex(index) {
    let id =
      this.state.menuId ||
      (this.props.collections[this.state.index] && this.props.collections[this.state.index].id);
    const pageId = this.props.id;
    listPageActions.updateMenuIndex(pageId, id, index);
  }

  onEnter(item, itemIndex, row, rowIndex) {
    row.selectedId = item.id;
    item.positionIndex = itemIndex;
    row.positionIndex = rowIndex;
    if (this.props.onEnter) {
      this.props.onEnter(item, row);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.resetList && this.props.resetList) {
      this.setState({ index: 0, menuId: '' });
    }

    if (
      Number.isInteger(prevState.index) &&
      this.state.index !== prevState.index &&
      prevState.index - 1 >= 0
    ) {
      const isNavDown = this.state.index > prevState.index;

      if (isNavDown) {
        iTrackingActions.sendBatchFor(this.props.iTrackingRow + prevState.index - 1);
      } else {
        iTrackingActions.sendBatchFor(this.props.iTrackingRow + prevState.index + 1);
      }
    }
    if (
      this.props.id !== prevProps.id ||
      (this.props.backParams &&
        this.props.backParams.savedOffset !== prevProps.backParams.savedOffset)
    ) {
      let index =
        this.props.backParams && this.props.backParams.savedIndex
          ? parseInt(this.props.backParams.savedIndex, 10)
          : 0;
      this.setState({ index, menuId: '' });
    }
    if (!prevProps.hide && this.props.hide) {
      this.setState({ loading: true });
      var self = this;
      setTimeout(() => {
        self.setState({ loading: false });
      }, 1);
    }
  }

  render() {
    if (this.props.hide) return null;

    const { collections } = this.props;

    const menuIdIndex = _.findIndex(
      this.props.collections,
      (item) => item.id === this.state.menuId
    );
    const index = (menuIdIndex >= 0 && menuIdIndex) || this.state.index;

    let rows = this.props.collections.slice(index > 0 ? index - 1 : 0);
    if (this.props.hide || !rows || !rows.length) return null;

    const prevRow = index === 0 ? null : rows[0];
    const currentRow = index === 0 ? rows[0] : rows[1];
    const nextRow = index === 0 ? rows[1] : rows[2];

    let prevRowItems = prevRow && prevRow.items;
    let currentRowItems = currentRow && currentRow.items;
    let nextRowItems = nextRow && nextRow.items;

    let prevRowItemsSliced = false;
    let currentRowItemsSliced = false;
    let nextRowItemsSliced = false;

    const VIEW_MORE_LIMIT = 20;

    if (
      prevRow &&
      prevRow.items &&
      prevRow.items.length > VIEW_MORE_LIMIT &&
      prevRow.homeRailId !== 'hero_channels' &&
      prevRow.id !== 'linear-channels' &&
      prevRow.id !== 'discover-featured'
    ) {
      prevRowItems = prevRow.items.slice(0, VIEW_MORE_LIMIT);
      prevRowItemsSliced = true;
    }
    if (
      currentRow &&
      currentRow.items &&
      currentRow.items.length > VIEW_MORE_LIMIT &&
      currentRow.homeRailId !== 'hero_channels' &&
      currentRow.id !== 'linear-channels' &&
      currentRow.id !== 'discover-featured'
    ) {
      currentRowItems = currentRow.items.slice(0, VIEW_MORE_LIMIT);
      currentRowItemsSliced = true;
    }
    if (
      nextRow &&
      nextRow.items &&
      nextRow.items.length > VIEW_MORE_LIMIT &&
      nextRow.homeRailId !== 'hero_channels' &&
      nextRow.id !== 'linear-channels' &&
      nextRow.id !== 'discover-featured'
    ) {
      nextRowItems = nextRow.items.slice(0, VIEW_MORE_LIMIT);
      nextRowItemsSliced = true;
    }

    if (prevRowItemsSliced) {
      prevRowItems.push({ id: 'view-more', type: 'view-more' });
    }

    if (currentRowItemsSliced) {
      currentRowItems.push({ id: 'view-more', type: 'view-more' });
    }

    if (nextRowItemsSliced) {
      nextRowItems.push({ id: 'view-more', type: 'view-more' });
    }

    const pageId = this.props.id;

    const prevMenuIndex = prevRow ? listPageStore.getIndexForMenu(pageId, prevRow.id) : null;
    const currentMenuIndex = listPageStore.getIndexForMenu(pageId, currentRow && currentRow.id);
    const nextMenuIndex = nextRow ? listPageStore.getIndexForMenu(pageId, nextRow.id) : null;

    if (!collections || !collections.length) {
      return <div className="list-panel" />;
    }

    var that = this;
    let onEnterCurrent = function (item, itemIndex) {
      that.onEnter(item, itemIndex, currentRow, that.state.index);
    };

    const offset = index > 0 ? { marginTop: `${s(-300)}px` } : {};
    return (
      <div ref={this.props.propRef} className="list-panel" style={offset}>
        {prevRow ? (
          <CardRow
            id={this.props.id}
            isContextual={this.props.isContextual}
            row={prevRow}
            key={prevRow ? `${index - 1}-${prevRow.id}` : null}
            focused={false}
            hide={_.isNull(prevRow)}
            initialIndex={prevMenuIndex}
            menuItems={prevRow ? prevRowItems : false}
            type={prevRow ? prevRow.type : false}
            iTrackingRow={this.props.iTrackingRow + index - 1}
            currentFocusedRow={this.props.focused ? index : null}
            mid={prevRow ? prevRow.homeRailId || prevRow.id : false}
          />
        ) : null}
        <CardRow
          id={this.props.id}
          row={currentRow}
          key={`${index}-${currentRow.id}`}
          menuItems={currentRowItems}
          type={currentRow.type}
          mid={currentRow.homeRailId || currentRow.id}
          focused={this.props.focused}
          onLeft={this.props.onLeft}
          onRight={this.props.onRight}
          onUp={this.listPanelOnUp}
          onDown={this.listPanelOnDown}
          onChange={this.updatePositionIndex}
          onEnter={onEnterCurrent}
          onLongPress={this.props.onLongPress}
          displayType={this.props.displayType}
          initialIndex={currentMenuIndex}
          showProgressBar={true}
          iTrackingRow={this.props.iTrackingRow + index}
          currentFocusedRow={this.props.focused ? index : null}
          isContextual={this.props.isContextual}
        />
        {nextRow ? (
          <CardRow
            id={this.props.id}
            isContextual={this.props.isContextual}
            row={nextRow}
            key={nextRow ? `${index + 1}-${nextRow.id}` : null}
            focused={false}
            hide={_.isNull(nextRow)}
            initialIndex={nextMenuIndex}
            menuItems={nextRow ? nextRowItems : false}
            type={nextRow ? nextRow.type : false}
            iTrackingRow={this.props.iTrackingRow + index + 1}
            currentFocusedRow={this.props.focused ? index : null}
            mid={nextRow ? nextRow.homeRailId || nextRow.id : false}
          />
        ) : null}
      </div>
    );
  }
}
