export function codecSupport() {
  const _video = document.createElement('video');
  const _audio = document.createElement('audio');
  const _codecSupport = [];
  let h264 = null,
    hevc = null,
    dolbyVision,
    av1 = null,
    vp9 = null,
    mp4 = null,
    ac_3 = null,
    ec_3 = null;
  h264 =
    _video.canPlayType('video/mp4; codecs="avc1.4D401F"') === 'probably' ? 'avc1.4D401F' : h264;
  h264 =
    _video.canPlayType('video/mp4; codecs="avc1.4D4028"') === 'probably' ? 'avc1.4D4028' : h264;
  h264 =
    _video.canPlayType('video/mp4; codecs="avc1.640028"') === 'probably' ? 'avc1.640028' : h264;
  h264 =
    _video.canPlayType('video/mp4; codecs="avc1.4D4034"') === 'probably' ? 'avc1.4D4034' : h264;
  if (h264) _codecSupport.push(h264);

  hevc =
    _video.canPlayType('video/mp4; codecs="hvc1.2.4.L123.B0"') === 'probably'
      ? 'hvc1.2.4.L123.B0'
      : hevc;
  hevc =
    _video.canPlayType('video/mp4; codecs="hvc1.2.4.L150.B0"') === 'probably'
      ? 'hvc1.2.4.L150.B0'
      : hevc;
  hevc =
    _video.canPlayType('video/mp4; codecs="hvc1.1.6.L153.B0"') === 'probably'
      ? 'hvc1.1.6.L153.B0'
      : hevc;
  if (hevc) _codecSupport.push(hevc);

  dolbyVision =
    _video.canPlayType('video/mp4; codecs="dvh1.05.01"') === 'probably'
      ? 'dvh1.05.01'
      : dolbyVision;
  dolbyVision =
    _video.canPlayType('video/mp4; codecs="dvh1.05.03"') === 'probably'
      ? 'dvh1.05.03'
      : dolbyVision;
  dolbyVision =
    _video.canPlayType('video/mp4; codecs="dvh1.05.06"') === 'probably'
      ? 'dvh1.05.06'
      : dolbyVision;
  dolbyVision =
    _video.canPlayType('video/mp4; codecs="dvh1.05.09"') === 'probably'
      ? 'dvh1.05.09'
      : dolbyVision;
  if (dolbyVision) _codecSupport.push(dolbyVision);

  av1 =
    _video.canPlayType('video/mp4; codecs="av01.0.09M.08"') === 'probably' ? 'av01.0.09M.08' : av1;
  av1 =
    _video.canPlayType('video/mp4; codecs="av01.0.13M.08"') === 'probably' ? 'av01.0.13M.08' : av1;
  if (av1) _codecSupport.push(av1);

  vp9 =
    _video.canPlayType('video/webm; codecs="vp09.00.50.08, vorbis"') === 'probably'
      ? 'vp09.00.50.08'
      : vp9;
  if (vp9) _codecSupport.push(vp9);

  mp4 = _audio.canPlayType('audio/mp4; codecs="mp4a.40.2"') === 'probably' ? 'mp4a.40.2' : mp4;
  mp4 = _audio.canPlayType('video/mp4; codecs="mp4a.40.5"') === 'probably' ? 'mp4a.40.5' : mp4;
  if (mp4) _codecSupport.push(mp4);

  ac_3 = _audio.canPlayType('video/mp4; codecs="ac-3"') === 'probably' ? 'ac-3' : ac_3;
  if (ac_3) _codecSupport.push(ac_3);

  ec_3 = _audio.canPlayType('video/mp4; codecs="ec-3"') === 'probably' ? 'ec-3' : ec_3;
  if (ec_3) _codecSupport.push(ec_3);

  return _codecSupport;
}
