import BasePlatform from './base.js';

const USER_AGENT = navigator.userAgent;
// const USER_AGENT = 'HbbTV/1.2.1 (+DRM;Samsung;SmartTV2017;T-KTSDEUC-1290.3;;)+TVPLUS+SmartHubLink Chrome';
// const USER_AGENT = 'HbbTV/1.2.1 (+DRM;Samsung;SmartTV2020:QTQ60;T-NKLDEUC-1460.9;NikeL;) Tizen/5.5 (+TVPLUS+SmartHubLink) Chrome/69 VID/9'

export default class HbbTV extends BasePlatform {
  constructor() {
    super();
    this.userAgentData = USER_AGENT.slice(USER_AGENT.indexOf('HbbTV'))?.split('(')[1]?.split(';');
    this.hbbtvVersion = this.getHbbTVVersion();
    console.log('Instantiated HbbTV...', this.hbbtvVersion, USER_AGENT, this.userAgentData);
  }

  exit() {
    try {
      console.warn('HbbTV exit');
      // Give our broadcast element the initial cannel
      if (window.rbmhHbbtvChannel) {
        document.getElementById('video').setChannel(window.rbmhHbbtvChannel);
        document.getElementById('video').release();
      }

      const app = document.getElementById('hbbtv-app-manager').getOwnerApplication(document);
      app.destroyApplication();

      const urlParams = new URLSearchParams(window.location.search);
      const cbParam = urlParams.get('cb');
      if (window.cbUrl !== null && window.cbUrl !== undefined) {
        window.location.href = window.cbUrl;
      } else if (cbParam !== null && cbParam !== undefined) {
        window.location.href = cbParam;
      }
    } catch (e) {
      // ignore
    }

    if (window && window.close) {
      window.top.close();
    }
  }

  getBrand() {
    try {
      return `${this.userAgentData[1]}`;
    } catch (e) {
      return 'unspecified';
    }
  }

  getOS() {
    try {
      return `${this.userAgentData[2]}`;
    } catch (e) {
      return 'unspecified';
    }
  }

  getHbbTVVersion() {
    // HbbTV Versions vs Useragent Strings:
    // HbbTV 2.0.2 (TS 102 796 V1.5.1)
    // HbbTV 2.0.1 (TS 102 796 V1.4.1)
    // HbbTV 2.0 (TS 102 796 V1.3.1)
    // HbbTV 1.5 (TS 102 796 V1.2.1)
    // HbbTV 1.0 (TS 102 796 v1.1.1)

    const userAgentData = USER_AGENT.split('/').join(' ').split(' ');
    try {
      console.log('UserAgent data', userAgentData);
      return `${userAgentData[1]}`;
    } catch (e) {
      return 'unspecified';
    }
  }

  firmware() {
    return this.hbbtvVersion;
  }
}
