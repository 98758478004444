import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import apiImageUrl from '../../utils/api-image-url';
import BaseCard from '../card/base-card';
import CardLabel from '../card-label/card-label';
import CardThumbnail from '../card-thumbnail/card-thumbnail';
import CardProgressBar from '../card-progress-bar/card-progress-bar';
import { Icon } from '../page-buttons/page-buttons';

export default class PageCard extends Reflux.Component.extend(BaseCard) {
  constructor(p) {
    super(p);

    this.ref = React.createRef();
  }

  getCardImage() {
    // For the page cards, if the type is video the image will be stretched so we spoof it to universal
    // in order to get the correct image size.
    let isUniversal = this.props.type === 'universal' || this.props.type === 'video';

    if (this.props.isTopResults) {
      return apiImageUrl.getImageByType('page', this.props.resources, this.props.id);
    }

    return apiImageUrl.getImageByType(
      isUniversal ? 'universal' : this.props.cardTypeUIOverride || this.props.content_type,
      this.props.resources,
      this.props.id
    );
  }

  getThumbnail() {
    let src = this.getCardImage();
    return <CardThumbnail src={src} />;
  }

  getDetails() {
    if (this.isServus) {
      return (
        <React.Fragment>
          {this.getLabel()}
          <h3>{this.props.title || this.props.headline}</h3>
        </React.Fragment>
      );
    }

    const heading = <h3 className="ellipsis">{this.props.title || this.props.headline}</h3>;
    const sub =
      this.props.subheading || this.props.subline ? (
        <h4 className="ellipsis">{this.props.subheading || this.props.subline}</h4>
      ) : null;

    return (
      <React.Fragment>
        {heading}
        {sub}
      </React.Fragment>
    );
  }

  getLabel() {
    return (
      <CardLabel
        {...this.props}
        status={this.props.status || (this.props.badgeType && { code: this.props.badgeType })}
        readAria={`${this.props.title} : list item`}
      />
    );
  }

  getCardProgressBar() {
    if (!this.props.progress) {
      return;
    }

    return <CardProgressBar {...this.props} />;
  }

  canShowTitles() {
    return (
      this.isServus ||
      (!this.props.cardTypeUIOverride &&
        this.props.content_type !== 'year' &&
        !['film', 'show', 'event'].includes(
          this.props.cardTypeUIOverride || this.props.content_type
        ))
    );
  }

  render() {
    const hasTitles = this.canShowTitles();
    const classNames = cx(
      `card card--page content-type-${this.props.cardTypeUIOverride || this.props.content_type}`,
      {
        'is-placeholder': this.props.isPlaceholder,
        'is-focused': this.props.focused,
        'has-title': hasTitles,
        'has-no-title': !hasTitles,
        offleft: this.props.offleft
      }
    );

    if (this.props.isPlaceholder) {
      return (
        <div ref={this.ref} className={classNames}>
          <div className="card__inner">
            <div className="card__inner__thumb">
              <div className="image-wrapper image-wrapper--loading" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={this.ref} className={classNames} role="button">
        <div className="card__inner">
          <div className="card__inner__thumb">{this.getThumbnail()}</div>
          <div className="card__inner__details">
            {hasTitles && this.getDetails()}
            {this.getCardProgressBar()}
          </div>
        </div>
        {!this.props.disableFavorite &&
          (this.props.cardTypeUIOverride || this.props.content_type) !== 'subchannel' && (
            <div className="fav-icon">
              <Icon
                iconName={this.props.isFavorite ? 'star-dark' : 'star-dark-empty'}
                focused={this.props.focused}
                size={48}
              />
            </div>
          )}
        {hasTitles && !this.isServus && this.getLabel()}
      </div>
    );
  }
}
