export default function (url) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let type = xhr.getResponseHeader('Content-Type');
        let blob = new Blob([xhr.response], { type });
        if ('arrayBuffer' in blob) {
          let buf = await blob.arrayBuffer();
          blob = new Blob([buf], { type });
        }
        resolve(blob);
      }
    };
    xhr.onerror = reject;
    xhr.send(null);
  });
}
