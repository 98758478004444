import BasePlatform from './base.js';

export default class Vestel extends BasePlatform {
  exit() {
    if (window && window.close) {
      window.top.close();
    }
  }

  getVolume() {
    return document.getElementById('html5Player').volume;
  }

  setVolume(value, restoreVolumeOnExit) {
    document.getElementById('html5Player').volume = value;
  }
}
