import React, { Suspense } from 'react';
import Reflux from 'reflux';
import { startStore } from '../../reflux/startStore';
import { dialogStore } from '../../reflux/dialogStore';
const Consumption = React.lazy(() => import('./consumption'));

export default class ConsumptionWrapper extends Reflux.PureComponent {
  constructor(props) {
    super(props);
    this.stores = [startStore, dialogStore];
  }

  render() {
    if (this.state.data) {
      return (
        <Suspense fallback={<div> </div>}>
          <Consumption
            focused={this.state.isFirstPlayEventFinished && !dialogStore.overlayShowing()}
            match={this.props.match}
            params={this.props.match.params}
            route={this.props.route}
          />
        </Suspense>
      );
    } else {
      return null;
    }
  }
}
