const MODE_SERIAL = 'serial';
const MODE_PARALLEL = 'parallel';

let MODE = MODE_PARALLEL;

if (MODE !== MODE_SERIAL && MODE !== MODE_PARALLEL) {
  console.warn('unkown request queue mode: ', MODE, ' defaulting to parallel.');
  MODE = MODE_PARALLEL;
}

const q = [];
const REQUEST_SPACING = 50;

let currentRequest = null;

function next() {
  if (!q.length) {
    // console.log('no outstanding requests.')
    currentRequest = null;
  } else {
    // console.log('starting next request. remaining: ', q.length - 1)
    let fn = q.shift();
    fn();
  }
}

function wrapRequest(fn, resolve, reject) {
  return () => {
    try {
      currentRequest = fn();
      currentRequest
        .then((result) => {
          resolve(result);
          setTimeout(next, REQUEST_SPACING);
        })
        .catch((err) => {
          try {
            reject(err);
          } finally {
            setTimeout(next, REQUEST_SPACING);
          }
        });
    } catch (ex) {
      // console.error('error making request: ', ex)
      setTimeout(next, REQUEST_SPACING);
    }
  };
}

/* requestFn must be of type ()=>Promise */
export default function queue(requestFn) {
  if (MODE === MODE_PARALLEL) return requestFn();

  // console.log('enqueueing request.')

  let onResolve, onReject;
  let result = new Promise((resolve, reject) => {
    onResolve = resolve;
    onReject = reject;
  });
  let fn = wrapRequest(requestFn, onResolve, onReject);

  if (!currentRequest) {
    // console.log('immediately executing request')
    fn();
  } else {
    // console.log('deferring request. remaining: ', q.length + 1)
    q.push(fn);
  }

  return result;
}
