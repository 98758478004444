import Reflux from 'reflux';
import _ from 'lodash';
import SearchServiceDelegate from '../services/searchServiceDelegate.js';
import PlatformUtils from '../utils/platform.js';
import ErrorReporter from '../utils/error-reporter';

export const searchActions = Reflux.createActions({
  search: { asyncResult: true },
  searchAutosuggest: { asyncResult: true },
  clearHistory: {},
  returnToSearch: {},
  clearSearch: {},
  updateCurrentValue: {},
  focusInputSection: {}
});

searchActions.search.listen(function (q) {
  SearchServiceDelegate.search(q).then(this.completed).catch(this.failed);
});

searchActions.searchAutosuggest.listen(function (q) {
  SearchServiceDelegate.searchAutosuggest(q).then(this.completed).catch(this.failed);
});

export const searchStore = Reflux.createStore({
  listenables: searchActions,
  state: { hasResults: false, view: null, currentValue: '', autoSuggest: [] },
  getInitialState() {
    this.state.history = this.getHistory();
    return this.state;
  },

  updateCurrentValue: function (value) {
    this.state.currentValue = value;
    this.trigger(this.state);
  },

  getHistory: function () {
    let history = PlatformUtils.sharedPlatform.localStorage().getItem('rbtv:searchHistory') || '[]';
    return JSON.parse(history);
  },

  clearSearch() {
    this.state.hasResults = false;
    this.state.view = null;
    this.state.currentValue = '';
    this.state.autoSuggest = [];
    this.state.focused = 'searchKeyboard';
    this.state.showInputSection = true;
    this.trigger(this.state);
  },

  addToHistory: function (text) {
    if (text && text.length && this.getResultsLength() > 0) {
      var history = this.getHistory();
      history.unshift(text);
      history = _.uniq(history);
      while (history.length > 4) history.pop();
      PlatformUtils.sharedPlatform
        .localStorage()
        .setItem('rbtv:searchHistory', JSON.stringify(history));
    }
  },

  clearHistory: function () {
    PlatformUtils.sharedPlatform.localStorage().setItem('rbtv:searchHistory', JSON.stringify([]));
  },

  returnToSearch: function () {
    this.state.hasResults = false;
    this.state.focused = 'searchKeyboard';
    this.state.view = null;
    this.state.offset = 0;
    this.trigger(this.state);
  },

  onSearchCompleted: function (items) {
    this.state.hasResults = true;
    this.state.doingSearch = false;

    let id = 'search';
    const total = items && items.total;
    this.state.view = { items, total, id };

    if (total > 0) {
      this.state.history = this.getHistory();
      this.state.focused = 'results';
      this.state.showInputSection = false;
    }
    this.trigger(this.state);
  },

  onSearchFailed: function (err) {
    ErrorReporter.captureException(err, 'onSearchFailed');
    this.state.hasResults = false;
    this.state.doingSearch = false;
    this.state.view = null; // No results
    this.trigger(this.state);
  },

  onSearchAutosuggestCompleted: function (result) {
    this.state.autoSuggest = result;
    this.trigger(this.state);
  },

  onSearchAutosuggestFailed: function (error) {
    ErrorReporter.captureException(error, 'AUTOSUGGEST ERROR');
  },

  getResultsLength: function (specificView) {
    var view = specificView || this.state.view;
    if (!view) return 0;
    return view.total;
  },

  focusResultsSection: function () {
    if (this.state.view && this.state.view.total > 0) {
      this.state.focused = 'results';
      this.state.showInputSection = false;
      this.trigger(this.state);
    }
  },

  focusInputSection: function () {
    this.state.focused = 'searchKeyboard';
    this.state.showInputSection = true;
    this.trigger(this.state);
  }
});
