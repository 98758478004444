import PlatformUtils from './platform';
import fecha, { setGlobalDateI18n } from 'fecha';
import conf from '../conf';
/* global moment: true */

let timeZone;
try {
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch (error) {
  timeZone = moment.tz.guess();
}

const TZ = PlatformUtils.isPS3 ? '' : moment.tz(moment(), timeZone).format('z');
// give up on guessing the TZ on PS3, where it doesn't work.

const currentYear = new Date().getYear();

if (conf.appNamespace === 'servustv') {
  setGlobalDateI18n({
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez'
    ],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ]
  });
}

export const fechaFormat = function (inputDate, dateFormat) {
  return inputDate instanceof Date && !isNaN(inputDate)
    ? fecha.format(inputDate, dateFormat)
    : null;
};

export class Duration {
  constructor(start, end) {
    this.duration = Math.abs(start - end);
    this.start = start;
    this.end = end;

    this.isBefore = start < end;
    this.isAfter = end > start;
    this.isNow = end === start;

    this.totalMS = this.totalSeconds = Math.floor(this.duration / 1000);
    this.totalMinutes = Math.floor(this.totalSeconds / 60);
    this.totalHours = Math.floor(this.totalSeconds / (60 * 60));

    this.seconds = parseInt(this.totalSeconds % 60, 10);
    this.minutes = parseInt(this.totalMinutes % 60, 10);
    this.hours = parseInt(this.totalHours, 10);
  }
}

const DateFormatter = {
  timezoneAbbr: TZ,

  locale: 'en-us',
  millsInADay: 86400000,
  millsInAYear: 86400000 * 365,

  getDateString: function (dateTime, monthStr = 'MMM D', yearStr = 'MMM D, YYYY') {
    if (!dateTime) {
      return 'Date unavailable';
    }
    let date = new Date(dateTime);
    let format = date.getYear() !== currentYear ? yearStr : monthStr;
    return fechaFormat(date, format);
  },

  getDateStringToRead: function (dateTime) {
    if (!dateTime) {
      return 'Date unavailable';
    }
    let date = new Date(dateTime);
    return fechaFormat(date, 'MMMM. D, YYYY');
  },

  getTimeString: function (dateTime) {
    if (!dateTime) {
      return 'Date unavailable';
    }
    let date = new Date(dateTime);
    return fechaFormat(date, 'h:mmA');
  },

  getDateRangeString: function (startTime, endTime, showYear = false) {
    if (!startTime || !endTime) {
      return 'Date unavailable';
    }
    let startDate = new Date(startTime);
    let endDate = new Date(endTime);
    let isNotThisYear =
      showYear && (startDate.getYear() !== currentYear || endDate.getYear() !== currentYear);
    let format = isNotThisYear ? 'MMM D, YY' : 'MMM D';
    let startString = fechaFormat(startDate, format);
    let endString = fechaFormat(endDate, format);
    let today = new Date();
    if (endDate - startDate < this.millsInADay && endDate.getDay() === startDate.getDay()) {
      return startString;
    } else if (startDate - today > this.millsInAYear) {
      return fechaFormat(startDate, 'MMM YYYY');
    } else {
      return `${startString} - ${endString}`;
    }
  },

  getDateRangeStringToRead: function (startTime, endTime) {
    if (!startTime || !endTime) {
      return 'Date unavailable';
    }
    let startDate = new Date(startTime);
    let endDate = new Date(endTime);
    let startString = fechaFormat(startDate, 'MMMM. D');
    let endString = fechaFormat(endDate, 'MMMM. D');
    let today = new Date();
    if (endDate - startDate < this.millsInADay) {
      return startString;
    } else if (startDate - today > this.millsInAYear) {
      return fechaFormat(startDate, 'MMMM. YYYY');
    } else {
      return `${startString} - ${endString}`;
    }
  },

  getTimeString1_6(dateTime) {
    if (!dateTime) {
      return 'Date unavailable';
    }
    let date = new Date(dateTime);
    let str = fechaFormat(date, 'h:mm A ') + TZ;
    return str.replace(':00', '');
  },

  getTimestring24Hrs(dateTime) {
    if (!dateTime) {
      return 'Date unavailable';
    }
    let date = new Date(dateTime);
    return fechaFormat(date, 'HH:mm');
  },

  getDateStringsForUniversalCard(dateTime) {
    let date = new Date(dateTime);
    let header = fechaFormat(date, 'MMM D');
    let subline = fechaFormat(date, 'ddd @ h:mm A ') + TZ;
    return { header, subline };
  },

  getMonthDayFull: function (date) {
    return fechaFormat(date, 'MMMM D');
  },

  getLocalTime: function (startTime, format = 'h:mm A') {
    let startDate = new Date(startTime);
    let hour = fechaFormat(startDate, format);
    return `${hour} ${TZ}`;
  },

  isToday: function (date) {
    let today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    return date - today === 0;
  },

  isTomorrow: function (date) {
    let today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    return date - today === this.millsInADay;
  },

  isInLessThan48Hours: function (date) {
    let msec = date.getTime() - new Date().getTime();
    let sec = msec / 1000;
    let hrs = sec / 60 / 60;
    return hrs < 48;
  },

  getDateTimeZone: function () {
    const date = new Date();
    return `${date} ${TZ} | ${timeZone}`;
  }
};

export default DateFormatter;
