export default function isElementInView(el, pos) {
  if (!el || !el.getBoundingClientRect) return;

  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window && window.main && window.main.clientHeight) &&
    rect.right <= (window && window.main && window.main.clientWidth)
  );
}
