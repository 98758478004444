/* globals Hisense_GetFirmWareVersion, Hisense_GetModelName, Hisense_GetDeviceID, Hisense_GetSupportForHDR, Hisense_GetBrand, Hisense_GetCCModeStatus */
import BasePlatform from './base.js';
import ErrorReporter from '../../error-reporter';

export default class VIDAA extends BasePlatform {
  constructor() {
    super();
    console.log('Instantiated VIDAA...');
  }

  exit() {
    if (window && window.close) {
      window.top.close();
    }
  }

  firmware() {
    try {
      return Hisense_GetFirmWareVersion();
    } catch (e) {
      ErrorReporter.captureException(e);
    }
  }

  model() {
    try {
      return Hisense_GetModelName();
    } catch (e) {
      ErrorReporter.captureException(e);
    }
  }

  deviceId() {
    try {
      return Hisense_GetDeviceID();
    } catch (e) {
      ErrorReporter.captureException(e);
      return super.deviceId();
    }
  }

  supportsHDR() {
    try {
      return Hisense_GetSupportForHDR();
    } catch (e) {
      ErrorReporter.captureException(e);
    }
  }

  getBrand() {
    try {
      return Hisense_GetBrand();
    } catch (e) {
      ErrorReporter.captureException(e);
    }
  }

  areCaptionsEnabled() {
    // 0 on
    // 1 off
    // 2 on when mute
    try {
      if (Hisense_GetCCModeStatus) {
        // Function doesn't exist on some models
        const ccEnabled = Hisense_GetCCModeStatus();
        return ccEnabled === 0 || ccEnabled === 2;
      } else {
        return false;
      }
    } catch (e) {
      ErrorReporter.captureException(e);
      return false;
    }
  }
}
