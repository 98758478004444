import Reflux from 'reflux';
import conf from '../conf';
import { consumptionStore, consumptionActions } from './consumptionStore';
import { configDataActions, configDataStore } from './configDataStore';
import { appStore, appActions } from './appStore';
import { epgStore } from './epgStore';
import { adsActions } from './adsStore';
import API from '../services/api.js';

const gfkAnalyticsActions = Reflux.createActions({
  initGfkExtension: {}
});

const defaultState = {};

class GfkAnalyticsStore extends Reflux.Store {
  constructor() {
    super();

    this.state = defaultState;

    this.currentAssetCustomParameters = {};

    this.listenTo(configDataActions.setConfigData, this.initGfkScript);
    this.listenTo(appActions.setAuthToken, this.initGfkScript);
    this.listenTo(adsActions.onAdStarted, this.listenToOnAdStarted);
    this.listenTo(adsActions.onAdComplete, this.listenToOnAdComplete);
    this.listenTo(
      consumptionActions.setCurrentAssetCompleted,
      this.listenToSetCurrentAssetCompleted
    );
  }

  initGfkScript() {
    if (!conf.enableGfk) return;
    const country_code = appStore.getCountryCode();
    const gfk_settings = configDataStore.getConstant('gfk_settings');

    if (gfk_settings && country_code && !this.gfkScriptIsAdded) {
      const el = document.createElement('script');
      el.async = true;
      el.src = `https://${gfk_settings.region_id}-config${
        conf.testBuild ? '-preproduction' : ''
      }.sensic.net/ctv/playerextension.js`;

      document.head.appendChild(el);
      this.gfkScriptIsAdded = true;
    }
  }

  initGfkExtension({ bitmovinPlayer } = {}) {
    if (!window.gfkExtension && window.gfkS2sExtension) {
      if (this.isUnpermittedContent()) return;

      const HTMLPlayer = document.getElementById('html5Player');
      const gfk_settings = configDataStore.getConstant('gfk_settings');

      const gfkS2sConf = {
        media: gfk_settings?.media_id || 'servustv',
        url: conf.testBuild
          ? `http://at-config-preproduction.sensic.net/ctv/s2s-web.js`
          : `http://at-config.sensic.net/s2s-web.js`,
        type: 'TV',
        device: 'SMART-TV',
        logLevel: conf.testBuild ? 'debug' : 'log'
      };

      if (!HTMLPlayer && !bitmovinPlayer) return;

      this.gfkPlayerType = 'live';

      const playerConf = {
        instance: HTMLPlayer || bitmovinPlayer,
        vendor: HTMLPlayer ? 'html5' : 'bitmovin',
        type: this.gfkPlayerType
      };

      const contentMetadata = {
        ContentID: 'default'
      };

      try {
        window.gfkExtension = new window.gfkS2sExtension.PlayerExtension(
          playerConf,
          gfkS2sConf,
          contentMetadata
        );
        window.gfkExtension.setParameter({ customParams: this.currentAssetCustomParameters });
      } catch (e) {
        console.error('[gfk] setup error', e);
      }
    }
  }

  isUnpermittedContent(listenerIsLinear) {
    const isLinear = listenerIsLinear || consumptionStore.state.isLinear;
    const country_code = appStore.getCountryCode();
    const currentLinearChannelId = epgStore.state.currentLinearChannel.id;

    const unpermittedContent =
      isLinear &&
      (currentLinearChannelId !== 'stv-linear' ||
        (currentLinearChannelId === 'stv-linear' && country_code !== 'at'));

    return unpermittedContent;
  }

  destroyGfkExtension() {
    if (!window.gfkExtension) return;
    window.gfkExtension.setPlayer({
      instance: undefined
    });

    window.gfkExtension = null;
  }

  listenToSetCurrentAssetCompleted({ currentAssetObject, isLinear } = {}) {
    const isLive =
      isLinear ||
      (currentAssetObject.content_type === 'live_program' &&
        currentAssetObject?.status?.code === 'live');
    if (!conf.enableGfk || !currentAssetObject) return;

    if (this.isUnpermittedContent(isLinear)) {
      this.destroyGfkExtension();
      return;
    }

    // Only HTML player because we want to initiate GFK for Bitmovin after .load is completed
    if (!window.gfkExtension && document.getElementById('html5Player')) {
      this.initGfkExtension();
    }

    if (
      this.currentAssetCustomParameters &&
      this.currentAssetCustomParameters.videoid === currentAssetObject.id &&
      (isLive === this.currentAssetCustomParameters.cliptype) === 'live'
    )
      return;

    const gfk_settings = configDataStore.getConstant('gfk_settings');

    this.currentAssetCustomParameters = {
      channel: gfk_settings?.media_id || 'servustv',
      deviceid: 'tv_app',
      videoid: currentAssetObject.id,
      episodeid: currentAssetObject.id,
      playerduration: Math.trunc(currentAssetObject.duration / 1000),
      episodeduration: Math.trunc(currentAssetObject.duration / 1000),
      videoduration: Math.trunc(currentAssetObject.duration / 1000),
      videotitle: currentAssetObject.title || 'stv',
      programname: currentAssetObject.label || 'stv',
      videopartid: '1_1'
    };

    if (isLive) {
      this.currentAssetCustomParameters = Object.assign({}, this.currentAssetCustomParameters, {
        playerid: 'stv.live.agtt',
        cliptype: 'live'
      });

      if (window.gfkExtension)
        window.gfkExtension.setParameter({ customParams: this.currentAssetCustomParameters });
    } else {
      API.getGfkConfigData({
        productID: currentAssetObject.id,
        content_type: currentAssetObject.content_type,
        statusCode: currentAssetObject.status ? currentAssetObject.status.code : null,
        isLinear
      }).then((gfk_conf) => {
        this.currentAssetCustomParameters = Object.assign({}, this.currentAssetCustomParameters, {
          playerid: gfk_conf.player_id,
          cliptype: gfk_conf.clip_type
        });

        if (window.gfkExtension)
          window.gfkExtension.setParameter({ customParams: this.currentAssetCustomParameters });
      });
    }

    if (!window.gfkExtension) return;

    if (isLive) {
      if (this.gfkPlayerType !== 'live') {
        this.gfkPlayerType = 'live';

        window.gfkExtension.setPlayer({
          type: this.gfkPlayerType
        });
      }
    } else {
      if (this.gfkPlayerType === 'live') {
        this.gfkPlayerType = 'vod';

        window.gfkExtension.setPlayer({
          type: this.gfkPlayerType
        });
      }
    }
  }

  listenToOnAdStarted(adResponse) {
    if (!conf.enableGfk || !window.gfkExtension) return;

    const ad = adResponse.getAd && adResponse.getAd();

    if (!ad || !ad.isLinear()) return;

    const info = ad.getAdPodInfo();
    const adPosition = info.getAdPosition();
    const adCountdownTimer = info.getMaxDuration();
    console.log('gfk listenToOnAdStarted', info);

    if (adPosition === 1) {
      console.log('gfk listenToOnAdStarted setParameter', info);

      const customParameters = {
        ...this.currentAssetCustomParameters,
        cliptype: 'ad',
        playerduration: adCountdownTimer
      };

      window.gfkExtension.setParameter('default', customParameters);
    }
  }

  listenToOnAdComplete(adResponse) {
    if (!conf.enableGfk || !window.gfkExtension) return;
    const ad = adResponse.getAd && adResponse.getAd();

    if (!ad || !ad.isLinear()) return;

    const info = ad.getAdPodInfo();
    const adPosition = info.getAdPosition();
    const totalAds = info.getTotalAds();

    if (adPosition === totalAds) {
      window.gfkExtension.setParameter('default', this.currentAssetCustomParameters);
    }
  }
}

Reflux.initStore(GfkAnalyticsStore);
const gfkAnalyticsStore = GfkAnalyticsStore.singleton;

export { gfkAnalyticsStore, gfkAnalyticsActions };
