import Reflux from 'reflux';
import React from 'react';
import { captionStore } from '../../reflux/captionStore';
import { settingsStore } from '../../reflux/settingsStore';
import _ from 'lodash';
import cn from 'classnames';
import PlatformUtils from '../../utils/platform';

class CaptionBlock extends React.PureComponent {
  viewHTML() {
    if (this.props.captionData && this.props.captionData.length) {
      let str = _(this.props.captionData).reduce((mem, v) => {
        if (v.cue) {
          mem += captionStore.webvttCueToHtml(v.cue); // converting cue to html tag ondemand
        }
        return mem;
      }, '');

      return {
        __html: str
      };
    }
  }

  render() {
    let is1080 = window.screenSizeValue === '1080p';
    let cx = cn({ is1080p: is1080 && !PlatformUtils.isTizen });
    return <div id="captions" className={cx} dangerouslySetInnerHTML={this.viewHTML()}></div>;
  }
}

export default class ClosedCaptions extends Reflux.Component {
  constructor(p) {
    super(p);
    this.stores = [captionStore];
    this.state = {};
  }

  render() {
    const shouldShowCaptions =
      this.state.currentCaptionViews &&
      this.state.currentCaptionViews.length &&
      (settingsStore.state.settings.shouldShowCC || this.state.currentAssetHasForcedCaptions) &&
      !this.props.disabled;
    if (shouldShowCaptions) {
      return <CaptionBlock captionData={this.state.currentCaptionViews} />;
    } else {
      return null;
    }
  }
}
