import { Menu } from './reactv-navigation';
import { ariaTTSServiceStore } from '../reflux/ariaTTSServiceStore';

/**
 * ListMenu ListMenu is for a menu that is a list of items with focus shifting within the list.
 * generally an abstract class to be sub-classed.
 *
 * List Menus expect to be based onUp/onDown/onLeft/onRight events to be called if the menu does not
 * capture the event.
 *
 * For example on a horizontal list or slot menu if we are at index 1 and hit onLeft then the menu will
 * decrement the index and the onLeft handler will NOT be called. if we are at index 0 and hit left
 * then the index can not be decremented so we try to call the onLeft prop function
 */
export class ListMenu extends Menu {
  /**
   * ListMenu constructor
   * @param {Object} props - React Component Properties, (make sure to super react lifecycle calls)
   * @param {Object|Array} [props.menuItems]      - array or object of menu menuItems, required to count number of items
   * @param {number} [props.disableMouseEvents]   - skips including onMouseOver and onClick props when calling this.getFocusProps() (for children elements)
   * @param {Function} [props.onUp]       - onUp function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onDown]     - onDown function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onLeft]     - onLeft function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onRight]    - onRight function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onEnter]    - onEnter function to be called on direction event if the menu does not handle the event.
   *
   * @todo add test coverage;
   *
   * @return {null}
   */
  constructor(props) {
    super(props);
    this.state = this.configureState(props);
  }

  configureState(props, noFocusChange) {
    /**
     * @type {Object}
     * @property {number} state.currentFocus    - the current focus index of the list
     */
    var state = Object.assign({
      currentFocus: props.setInitialFocusIndex || 0
    });

    if (noFocusChange) {
      delete state.currentFocus;
    }

    this.focusIndexes = 0;
    return state;
  }

  componentDidUpdate(prevProps, prevState) {
    if (super.componentDidUpdate) super.componentDidUpdate(prevProps, prevState);
    if (prevProps.menuItems && this.props.menuItems) {
      if (prevProps.menuItems.length !== this.props.menuItems.length) {
        this.setState(this.configureState(this.props, true));
      }
    }
  }
  /**
   * getFocusProps()
   * gets the properties to the focusable list components. To get this to work you need
   * to have {...this.getFocusProps} passed to the child components to handle focus as well as
   * attach different mouse events.
   *
   * @todo add test coverage for this.
   * @params      {Object} [props] - props to pass to children
   * @returns     {Object} props for child components
   */
  getFocusProps(props) {
    props = props || {};
    var focusIndex = this.focusIndexes++;
    var menu = this;
    props = Object.assign({}, this.props, {
      focusIndex: focusIndex,
      focused: menu.state.focused && menu.state.currentFocus === focusIndex,
      menuid: menu.props.menuid
    });

    if (!this.props.disableMouseEvents) {
      props = Object.assign(props, {
        onMouseOver: function (otherstate) {
          // Arg allows children to add their own state.
          var newstate = Object.assign(
            {
              currentFocus: focusIndex
            },
            otherstate || {}
          );
          menu.setState(newstate);
          if (!menu.props.focused) menu.focus();
        },
        onClick: function () {
          menu.onEnter();
        }
      });
    }
    return props;
  }

  onEnter() {
    var keys = Object.keys(this.props.menuItems);
    var key = keys[this.state.currentFocus];
    const response = this.props.menuItems[key];
    super.onEnter(response, this.state.currentFocus);
  }

  onLongPress() {
    var keys = Object.keys(this.props.menuItems);
    var key = keys[this.state.currentFocus];
    const response = this.props.menuItems[key];
    super.onLongPress(response, this.state.currentFocus);
  }

  /**
   * increment()
   * Increase the list index onRight for Horizontal, onDown for Vertical menus
   *
   * @return {boolean} whether we have handled the increment (if not the event should pass to
   * the menu super which should eventually call the prop function corresonding to the directional event.
   *
   * @todo add test coverage for this.
   */
  increment(evt) {
    if (this.state.currentFocus < this.props.menuItems.length - 1) {
      this.setState({ currentFocus: this.state.currentFocus + 1 });
      return true;
    }
  }

  /**
   * Decrease the list index onLeft for Horizontal, onUp for Vertical menus
   *
   * @return {boolean} whether we have handled the increment (if not the event should pass to
   * the menu super which should eventually call the prop function corresonding to the directional event.
   *
   * @todo add test coverage for this.
   */
  decrement(evt) {
    if (this.state.currentFocus > 0) {
      this.setState({ currentFocus: this.state.currentFocus - 1 });
      return true;
    }
  }

  /**
   * utility function that checks if the menu is currently focused and the index is the current focus
   * @params {number} index - the index to check if its focused
   * @return {boolean} whether the index is focused
   */
  isFocused(index) {
    return this.props && this.state && this.props.focused && this.state.currentFocus === index;
  }

  scrollNextPage() {
    if (this.refs && this.props && this.props.menuItems) {
      var indexArray = Object.keys(this.refs);
      var visibleSize = this.getVisibleScrollCount() + 1;
      var lastIndex =
        Number(indexArray.length > 0 ? indexArray[0] : indexArray[indexArray.length - 1]) +
        visibleSize;
      if (this.props.menuItems.length > lastIndex) {
        this.setState({ currentFocus: lastIndex });
      } else if (this.state.currentFocus < this.props.menuItems.length) {
        this.setState({ currentFocus: this.props.menuItems.length - 1 });
      }
    }
  }

  scrollPreviousPage() {
    if (this.refs && this.props && this.props.menuItems) {
      var indexArray = Object.keys(this.refs);
      var visibleSize = this.getVisibleScrollCount() - 1;
      var firstIndex = Number(
        indexArray.length > 0 ? indexArray[0] : indexArray[indexArray.length - 1]
      );
      if (firstIndex - visibleSize > 0) {
        this.setState({ currentFocus: firstIndex - visibleSize });
      } else if (this.state.currentFocus > 0) {
        this.setState({ currentFocus: 0 });
      }
    }
  }

  getVisibleScrollCount() {
    return 4;
  }
}

/**
 * HorizontalMenu left/right implementation of List Menu
 * @example
 * // Setup
 * class HorzMenu extends HorizontalMenu {
 *      render() {
 *          return (
 *               <nav>
 *                   { this.props.menuItems.map(function(idx, id) {
 *                       return <MenuComponent item={idx}  key={`${this.props.menuid}-${id}`} {...this.getFocusProps()} ref={id} />
 *                   }, this)}
 *               </nav>
 *          )
 *      }
 *  }
 *
 * class MenuComponent extends React.Component {
 *      render() {
 *          var class = classnames({focused: this.props.focused}); // will get set by ...this.getFocusProps()
 *          return (
 *              <a className={classes} { ...this.props }>{name}</a>
 *          );
 *      }
 *  }
 *
 * // Usage
 * var menuItems = [ 'pabst', 'coors', 'miller', 'budweiser', 'sierra nevada', 'rolling rock' ]
 * class App extends React.Component {
 *  doSomething(selectedItem) {
 *      console.log('got selected item', selectedItem);
 *  }
 *  render() {
 *      return (
 *           <HorzMenu menuItems={menuItems} onEnter={this.doSomething} />
 *      )
 *   }
 * }
 */
export class HorizontalMenu extends ListMenu {
  /**
   * @return {boolean} tries to return whether we have handled the event.
   */
  onRight() {
    return this.increment() || super.onRight() || ariaTTSServiceStore.readText('end of list');
  }

  /**
   * @return {boolean} tries to return whether we have handled the event.
   */
  onLeft() {
    return this.decrement() || super.onLeft() || ariaTTSServiceStore.readText('start of list');
  }

  onNext() {
    this.scrollNextPage();
  }

  onPrevious() {
    this.scrollPreviousPage();
  }
}

/**
 * VerticalMenu up/down implementation of List Menu
 * @example
 * // Setup
 * class VertMenu extends VerticalMenu {
 *      render() {
 *          return (
 *               <nav>
 *                   { this.props.menuItems.map(function(idx, id) {
 *                       return <MenuComponent item={idx}  key={`${this.props.menuid}-${id}`} {...this.getFocusProps()} ref={id} />
 *                   }, this)}
 *               </nav>
 *          )
 *      }
 *  }
 *
 * class MenuComponent extends React.Component {
 *      render() {
 *          var class = classnames({focused: this.props.focused}); // will get set by ...this.getFocusProps()
 *          return (
 *              <a className={classes} { ...this.props }>{name}</a>
 *          );
 *      }
 *  }
 *
 * // Usage
 * var menuItems = [ 'pabst', 'coors', 'miller', 'budweiser', 'sierra nevada', 'rolling rock' ]
 * class App extends React.Component {
 *  doSomething(selectedItem) {
 *      console.log('got selected item', selectedItem);
 *  }
 *  render() {
 *      return (
 *           <VertMenu menuItems={menuItems} onEnter={this.doSomething} />
 *      )
 *   }
 * }
 */
export class VerticalMenu extends ListMenu {
  /**
   * @return {boolean} tries to return whether we have handled the event.
   */
  onUp() {
    return this.decrement() || super.onUp();
  }

  /**
   * @return {boolean} tries to return whether we have handled the event.
   */
  onDown() {
    return this.increment() || super.onDown();
  }
}

/**
 * SlotMenu
 *
 * A slot menu is a list menu with a set number of items which are visible and navigable. Once navigation
 * hits the end of the window the "window" moves with the index.
 *
 * This class should be abstract extended by subclasses horizontal and vertical menus.
 */

export class SlotMenu extends ListMenu {
  /**
   * SlotMenu constructor expects React Component props.
   * @param {Object} props - React Component Properties, (make sure to super react lifecycle calls)
   * @param {number} [props.slots=1] - number of menu "slots" which are visible
   * @param {number} [props.disableMouseEvents]   - disable Mouse Events from focus props.
   * @param {Object|Array} [props.menuItems] - array or object of menu menuItems, required to count number of grid items
   * @param {number} [props.menuLength] - needed if menuItems are not passed in.
   * @param {Function} [props.onUp] - onUp function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onDown] - onDown function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onLeft] - onLeft function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onRight] - onRight function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onEnter] - onEnter function to be called on direction event if the menu does not handle the event.
   *
   * @todo add test coverage;
   *
   * @return {null}
   */

  /**
   * Update states when we get new menuItems.
   */
  configureState(props) {
    /**
     * @type {Object}
     * @property {number} state.currentSlot - the current offset of the slot in the slot window
     * @property {number} state.slotDelta - the change in slot focus after a slotIndex update
     * @property {number} state.focusDelta - the change focus after a focusIndex update
     * @property {number} state.listOffset - this current offset of the slot window from the bottom of the list
     */
    var state = super.configureState(props);
    state = Object.assign(state, {
      currentSlot: 0,
      slotDelta: 0,
      focusDelta: 0,
      listOffset: 0
    });
    return state;
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps && this.props && prevProps.menuItems !== this.props.menuItems)
      this.setState(this.configureState(this.props));
  }
  /**
   * @return {Object} an object of properties to be to be passed to the children focusable elements.
   * @property {Function} onMouseOver - onMouseOver function that handles the moueover events, updates index and sets focus
   * @property {Function} onClick - onClick function that handles click events.
   * @property {boolean} inWindow - whether the focusable component is currently in the slot window.
   */
  getFocusProps(props) {
    // Need to check if we're within the slot window and only handle mouse
    // events in window.
    props = super.getFocusProps(props);
    var { onMouseOver } = props;
    props.inWindow = this.indexInWindow(props.focusIndex);

    if (!props.disableMouseEvents) {
      props.onMouseOver = function () {
        if (this.indexInWindow(props.focusIndex)) {
          var delta = props.focusIndex - this.state.currentFocus;
          onMouseOver({
            currentSlot: this.state.currentSlot + delta,
            slotDelta: delta,
            focusDelta: delta
          });
        }
      }.bind(this);

      // TODO: Had to comment this out when transferring into the repo
      // props.onClick = function() {
      //     if (this.indexInWindow(props.focusIndex)) super.onEnter();
      // }.bind(this);
    }

    return props;
  }

  /**
   * @param {number} idx index to test whether its in the current slot window.
   * @returns {boolean} whether the index is in the current slot window
   */
  indexInWindow(idx) {
    var lowerBound = this.state.currentFocus - this.state.currentSlot;
    var upperBound = lowerBound + this.props.slots;
    return idx >= lowerBound && idx < upperBound;
  }

  /**
   * Increments the currentFocus index also tests whether
   * we will be moving the slot and the current list offset
   * @returns {boolean}
   */
  increment() {
    if (this.state.currentFocus < this.props.menuItems.length - 1) {
      var currentFocus = this.state.currentFocus + 1;
      var slotDelta = 0;
      if (this.state.currentSlot < this.props.slots - 1) {
        this.state.currentSlot = this.state.currentSlot + 1;
        slotDelta = 1;
      }
      // Flush data and re-render;

      var currentSlot = this.state.currentSlot + slotDelta;
      var listOffset = currentFocus - currentSlot;

      this.setState({
        currentFocus: currentFocus,
        currentSlot: currentSlot,
        slotDelta: slotDelta,
        focusDelta: 1,
        listOffset: listOffset
      });
      return true;
    } else {
      return false;
    }
  }

  /**
   * decrement()
   * Increments the currentFocus index also tests whether
   * we will be moving the slot and the current list offset
   * @returns {boolean}
   */
  decrement() {
    let currentSlot;
    if (this.state.currentFocus > 0) {
      var currentFocus = this.state.currentFocus - 1;
      var slotDelta = 0;
      if (this.state.currentSlot > 0) {
        currentSlot = this.state.currentSlot - 1;
        slotDelta = -1;
      }
      // Flush data and re-render;
      currentSlot = this.state.currentSlot + slotDelta;
      var listOffset = currentFocus - currentSlot;

      this.setState({
        currentFocus: currentFocus,
        currentSlot: currentSlot,
        slotDelta: slotDelta,
        focusDelta: -1,
        listOffset: listOffset
      });
      return true;
    } else {
      return false;
    }
  }
}

SlotMenu.defaultProps = {
  slots: 1
};

/**
 * HorizontalSlotMenu left/right implementation of Slot Menu
 * @example
 * // Setup
 * class HorzMenu extends HorizontalSlotMenu {
 *      render() {
 *          return (
 *               <nav>
 *                   { this.props.menuItems.map(function(idx, id) {
 *                       return <MenuComponent item={idx}  key={`${this.props.menuid}-${id}`} {...this.getFocusProps()} ref={id} />
 *                   }, this)}
 *               </nav>
 *          )
 *      }
 *  }
 *
 * class MenuComponent extends React.Component {
 *      render() {
 *          var class = classnames({focused: this.props.focused}); // will get set by ...this.getFocusProps()
 *          return (
 *              <a className={classes} { ...this.props }>{name}</a>
 *          );
 *      }
 *  }
 *
 * // Usage
 * var menuItems = [ 'pabst', 'coors', 'miller', 'budweiser', 'sierra nevada', 'rolling rock' ]
 * class App extends React.Component {
 *  doSomething(selectedItem) {
 *      console.log('got selected item', selectedItem);
 *  }
 *  render() {
 *      return (
 *           <HorzMenu menuItems={menuItems} slots={2} onEnter={this.doSomething} />
 *      )
 *   }
 * }
 *
 */
export class HorizontalSlotMenu extends SlotMenu {
  /**
   * @return {boolean} tries to return whether we have handled the event.
   */
  onRight() {
    return this.increment() || super.onRight();
  }

  /**
   * @return {boolean} tries to return whether we have handled the event.
   */
  onLeft() {
    return this.decrement() || super.onLeft();
  }
}

/**
 * VerticalSlotMenu up/down implementation of Slot Menu
 * @example
 * // Setup
 * class VertMenu extends VerticalSlotMenu {
 *      render() {
 *          return (
 *               <nav>
 *                   { this.props.menuItems.map(function(idx, id) {
 *                       return <MenuComponent item={idx}  key={`${this.props.menuid}-${id}`} {...this.getFocusProps()} ref={id} />
 *                   }, this)}
 *               </nav>
 *          )
 *      }
 *  }
 *
 * class MenuComponent extends React.Component {
 *      render() {
 *          var class = classnames({focused: this.props.focused}); // will get set by ...this.getFocusProps()
 *          return (
 *              <a className={classes} { ...this.props }>{name}</a>
 *          );
 *      }
 *  }
 *
 * // Usage
 * var menuItems = [ 'pabst', 'coors', 'miller', 'budweiser', 'sierra nevada', 'rolling rock' ]
 * class App extends React.Component {
 *  doSomething(selectedItem) {
 *      console.log('got selected item', selectedItem);
 *  }
 *  render() {
 *      return (
 *           <VertMenu menuItems={menuItems} slots={2} onEnter={this.doSomething} />
 *      )
 *   }
 * }
 *
 */
export class VerticalSlotMenu extends SlotMenu {
  /**
   * @return {boolean} tries to return whether we have handled the event.
   */
  onDown() {
    return this.increment() || super.onDown();
  }

  /**
   * @return {boolean} tries to return whether we have handled the event.
   */
  onUp() {
    return this.decrement() || super.onUp();
  }
}

export class GridMenu extends Menu {
  /**
   * GridMenu constructor
   * @param {Object}  props                       - React Component Properties, (make sure to super)
   * @param {number}  props.numberOfColumns                  - Number of columns in the grid (rows determined by length of menuitems
   * @param {boolean} [props.focused]             - Whether menu is focused or not
   * @param {Object|Array} [props.menuItems]      - array or object of menu menuItems, required to count number of grid items
   * @param {number}   [props.initialFocusIndex]  - Initial focus index.
   * @param {number}   [props.menuLength]         - needed if menuItems are not passed in.
   * @param {Function} [props.onUp]               - onUp function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onDown]             - onDown function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onLeft]             - onLeft function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onRight]            - onRight function to be called on direction event if the menu does not handle the event.
   * @param {Function} [props.onEnter]            - onEnter function to be called on direction event if the menu does not handle the event.
   *
   * @return null;
   */
  constructor(props) {
    super(props);
    var state = Object.assign(
      {
        index: this.props.initialFocusIndex || 0
      },
      this.state || {}
    );

    state.row = Math.floor(state.index / props.numberOfColumns);
    /**
     * @type {Object}
     * @property {number} state.index - the current index of items in the grid
     */
    this.state = state;
    this.focusIndexes = 0;
  }

  /**
   * mainly sets the row if focus is changed.
   */
  componentDidUpdate(prevProps, prevState) {
    if (super.componentDidUpdate) super.componentDidUpdate(prevProps, prevState);
    if (prevState.index !== this.state.index) {
      this.setState({
        row: Math.floor(this.state.index / this.props.numberOfColumns)
      });
    }
  }

  setNewIndex(index) {
    this.setState({
      index: index,
      row: Math.floor(index / this.props.numberOfColumns),
      col: Math.ceil(index % this.props.numberOfColumns)
    });
  }

  /**
   * Right arrow handler.
   * @return {boolean} - try to return whether we have handled the event
   */
  onRight() {
    var onRightEdge =
      this.state.index % this.props.numberOfColumns === this.props.numberOfColumns - 1 ||
      this.state.index === Object.keys(this.props.menuItems).length - 1;
    if (onRightEdge) {
      return super.onRight() || ariaTTSServiceStore.readText('end of grid');
    } else {
      this.setNewIndex(this.state.index + 1);
      return true;
    }
  }

  /**
   * Left arrow handler.
   * @return {boolean} - try to return whether we have handled the event
   */
  onLeft() {
    var onLeftEdge = this.state.index % this.props.numberOfColumns === 0;
    if (onLeftEdge) {
      return super.onLeft() || ariaTTSServiceStore.readText('end of grid');
    } else {
      this.setNewIndex(this.state.index - 1);
      return true;
    }
  }

  /**
   * Up arrow handler.
   * @return {boolean} - try to return whether we have handled the event
   */
  onUp() {
    var newindex = this.state.index - this.props.numberOfColumns;
    if (newindex < 0) return super.onUp();
    else {
      this.setNewIndex(newindex);
      return true;
    }
  }

  /**
   * Down arrow handler.
   * @return {boolean} - try to return whether we have handled the event
   */
  onDown() {
    const length = Object.keys(this.props.menuItems).length;
    const numberOfItemsOnBottomRow =
      length % this.props.numberOfColumns || this.props.numberOfColumns;
    const onBottomRow = this.state.index >= length - numberOfItemsOnBottomRow;
    if (onBottomRow) {
      return super.onDown() || ariaTTSServiceStore.readText('end of grid');
    } else {
      var newindex = this.state.index + this.props.numberOfColumns;
      if (newindex >= length) {
        newindex = length - 1;
      }
      this.setNewIndex(newindex);
      return true;
    }
  }

  onEnter() {
    const response = this.props.menuItems[this.state.index];
    return super.onEnter(response, this.state.index);
  }

  onLongPress() {
    const response = this.props.menuItems[this.state.index];
    super.onLongPress(response, this.state.index);
  }
}
