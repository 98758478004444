import conf from '../../conf';

function getScreenDetails() {
  if (typeof window === 'undefined') return null;
  try {
    const { screen, devicePixelRatio: density } = window;
    const { width, height } = screen;
    const { clientWidth, clientHeight } = document.documentElement;
    return {
      height,
      width,
      density,
      resolution: [width, height].join('x'),
      colorDepth: `${screen.colorDepth}-bits`,
      viewportSize: [clientWidth, clientHeight].join('x')
    };
  } catch (error) {
    console.error('issue obtaining screen details', error);
    return null;
  }
}

function getDefaultContext() {
  const isServus = conf.appNamespace === 'servustv';
  const screen = getScreenDetails();
  return {
    app: {
      appId: isServus ? 'com.redbull.ServusTV' : 'com.redbull.RBTV',
      name: isServus ? 'ServusTV' : 'Red Bull TV',
      version: conf.version
    },
    screen: screen || {}
  };
}

export { getDefaultContext };
