import Reflux from 'reflux';
import { Navigation as Nav } from '../utils/reactv-navigation';
import { configDataStore } from './configDataStore';
import { settingsStore } from './settingsStore';
import { homeStore, homeActions } from './homeStore';
import PlatformUtils from '../utils/platform.js';

export const dialogActions = Reflux.createActions({
  showBufferingScreen: {},
  hideBufferingScreen: {},
  showLoadFailedScreen: {},
  hideLoadFailedScreen: {},
  showNoConnectionScreen: {},
  hideNoConnectionScreen: {},
  showInterruptPlaybackScreen: {},
  hideInterruptPlaybackScreen: {},
  showResumeDialog: {},
  hideResumeDialog: {},
  showBackExitDialog: {},
  hideBackExitDialog: {},
  showFirmwareOutOfDateScreen: {},
  hideFirmwareOutOfDateScreen: {},
  showDeviceNotSupportedScreen: {},
  hideDeviceNotSupportedScreen: {},
  showappOutOfDateScreen: {},
  hideappOutOfDateScreen: {},
  showError500Screen: {},
  hideError500Screen: {},
  showErrorHomeScreen: {},
  hideErrorHomeScreen: {},
  showActionConfirmation: {},
  hideActionConfirmation: {},
  showEnvSwitcher: {},
  hideEnvSwitcher: {},
  showSuitestIdSwitcher: {},
  hideSuitestIdSwitcher: {},
  showSpoofSwitcher: {},
  hideSpoofSwitcher: {},
  showInvalidContentScreen: {},
  hideInvalidContentScreen: {},
  showLogoutDialog: {},
  hideLogoutDialog: {},
  showErrorMessageScreen: {},
  showSignInPrompt: {},
  hideSignInPrompt: {},
  showPrivacyConsentPrompt: {},
  hidePrivacyConsentPrompt: {},
  showSunsetMessage: {},
  hideSunsetMessage: {}
});

export const dialogStore = Reflux.createStore({
  listenables: dialogActions,

  state: {
    invalidContentScreen: false,
    actionConfirmation: false,
    backExitScreen: false,
    bufferingScreen: false,
    loadFailedScreen: false,
    noConnectionScreen: false,
    interruptPlaybackScreen: false,
    deviceNotSupportedScreen: false,
    firmwareOutOfDateScreen: false,
    appOutOfDateScreen: false,
    error500Screen: false,
    errorHomeScreen: false,
    logoutScreen: false,
    signInPrompt: false,
    sunsetMessage: false,
    privacyConsentPrompt: false,
    errorMessageScreen: { isVisible: false, message: '' },
    resume: {
      active: false
    },
    backBinding: null
  },

  getInitialState: function () {
    return this.state;
  },

  dialogsShowing: function () {
    let areDialogsShowing =
      this.state.actionConfirmation ||
      this.state.appOutOfDateScreen ||
      this.state.backExitScreen ||
      this.state.bufferingScreen ||
      this.state.firmwareOutOfDateScreen ||
      this.state.deviceNotSupportedScreen ||
      this.state.invalidContentScreen ||
      this.state.error500Screen ||
      this.state.errorHomeScreen ||
      this.state.interruptPlaybackScreen ||
      this.state.loadFailedScreen ||
      this.state.noConnectionScreen ||
      this.state.logoutScreen ||
      this.state.signInPrompt ||
      this.state.sunsetMessage ||
      this.state.privacyConsentPrompt ||
      this.state.resume.active ||
      this.state.errorMessageScreen.isVisible;
    return areDialogsShowing;
  },

  overlayShowing: function () {
    return (
      this.state.actionConfirmation ||
      this.state.appOutOfDateScreen ||
      this.state.backExitScreen ||
      this.state.firmwareOutOfDateScreen ||
      this.state.deviceNotSupportedScreen ||
      this.state.invalidContentScreen ||
      this.state.error500Screen ||
      this.state.errorHomeScreen ||
      this.state.interruptPlaybackScreen ||
      this.state.loadFailedScreen ||
      this.state.noConnectionScreen ||
      this.state.logoutScreen ||
      this.state.signInPrompt ||
      this.state.sunsetMessage ||
      this.state.privacyConsentPrompt ||
      this.state.resume.active ||
      this.state.errorMessageScreen.isVisible
    );
  },

  resetInactivityTimeout: function () {
    var timeoutConstant = configDataStore.getConstant('playback_interruption_delay');
    clearTimeout(this.state.inactivityTimeout);
    this.state.inactivityTimeout = setTimeout(() => {
      dialogActions.showInterruptPlaybackScreen();
    }, timeoutConstant);
  },

  showErrorMessageScreen: function (visible = false, message = '') {
    this.state.errorMessageScreen.isVisible = visible;
    this.state.errorMessageScreen.message = message;
    this.trigger(this.state);
  },

  showBufferingScreen: function () {
    this.state.bufferingScreen = true;
    this.trigger(this.state);
  },

  hideBufferingScreen: function () {
    this.state.bufferingScreen = false;
    this.trigger(this.state);
  },

  showLoadFailedScreen: function () {
    this.state.loadFailedScreen = true;
    this.trigger(this.state);
    console.warn('show load failed screen');
  },

  hideLoadFailedScreen: function () {
    this.state.loadFailedScreen = false;
    this.trigger(this.state);
  },

  showNoConnectionScreen: function () {
    this.state.noConnectionScreen = true;
    this.trigger(this.state);
    console.warn('show no connection screen');
  },

  _exitApplication: function () {
    console.log('exiting application.');
    PlatformUtils.exitAndClearLocalStorage();
  },

  hideNoConnectionScreen: function () {
    if (this.state.noConnectionScreen) {
      console.info('hide no connection screen');
      this.state.noConnectionScreen = false;
      this.trigger(this.state);
    }
  },

  showInterruptPlaybackScreen: function () {
    console.log('showInterruptPlaybackScreen');
    if (this.state.backBinding) {
      this.state.backBinding.unsubscribe();
      this.state.backBinding = false;
    }
    this.state.backBinding = Nav.subscribeToKey('Back', () => {
      dialogActions.hideInterruptPlaybackScreen();
    });
    if (settingsStore.state.settings.shouldInterruptPlayback) {
      this.state.interruptPlaybackScreen = true;
      this.trigger(this.state);
    }
  },

  hideInterruptPlaybackScreen: function () {
    if (this.state.backBinding) {
      this.state.backBinding.unsubscribe();
      this.state.backBinding = false;
    }
    this.state.interruptPlaybackScreen = false;
    this.trigger(this.state);
  },

  showFirmwareOutOfDateScreen: function (message) {
    this.state.firmwareOutOfDateScreen = true;
    this.state.firmwareOutOfDateMessage = message;
    this.trigger(this.state);
  },

  hideFirmwareOutOfDateScreen: function () {
    this.state.firmwareOutOfDateScreen = false;
    this.trigger(this.state);
  },

  showInvalidContentScreen: function () {
    this.state.invalidContentScreen = true;
    this.trigger(this.state);
  },
  hideInvalidContentScreen: function () {
    this.state.invalidContentScreen = false;
    this.trigger(this.state);
  },

  showDeviceNotSupportedScreen: function () {
    this.state.deviceNotSupportedScreen = true;
    this.trigger(this.state);
  },

  hideDeviceNotSupportedScreen: function () {
    this.state.deviceNotSupportedScreen = false;
    this.trigger(this.state);
  },

  showappOutOfDateScreen: function () {
    this.state.appOutOfDateScreen = true;
    this.trigger(this.state);
  },

  hideappOutOfDateScreen: function () {
    this.state.appOutOfDateScreen = false;
    this.trigger(this.state);
  },
  showError500Screen: function () {
    this.state.error500Screen = true;
    this.trigger(this.state);
  },

  hideError500Screen: function () {
    this.state.error500Screen = false;
    this.trigger(this.state);
  },

  showErrorHomeScreen: function () {
    this.state.errorHomeScreen = true;
    this.trigger(this.state);
  },

  hideErrorHomeScreen: function () {
    this.state.errorHomeScreen = false;
    this.trigger(this.state);
  },

  showBackExitDialog: function (onClose) {
    this.state.backExitScreen = true;
    this.state.onClose = onClose;
    this.trigger(this.state);
  },

  hideBackExitDialog: function () {
    this.state.backExitScreen = false;
    if (this.state.onClose) {
      this.state.onClose();
      this.state.onClose = null;
    }
    this.trigger(this.state);
  },

  showResumeDialog: function (card, resumePoint, callback, callbackArg) {
    if (homeStore.state.page) {
      homeActions.setLastPage(homeStore.state.page);
    }

    if (this.state.backBinding) {
      this.state.backBinding.unsubscribe();
      this.state.backBinding = false;
    }
    this.state.backBinding = Nav.subscribeToKey('Back', () => {
      dialogActions.hideResumeDialog();
    });

    this.state.resume = {
      active: true,
      heading: card.title,
      subheading: card.subheading,
      shouldResume: function (resume) {
        // Caller can pass in a callbackArg to use instead of the card. Return resumePoint based on user selection.
        callback(callbackArg || card, resume ? resumePoint : 1);
      },
      focused: true
    };
    this.trigger(this.state);
  },

  hideResumeDialog: function () {
    if (this.state.backBinding) {
      this.state.backBinding.unsubscribe();
      this.state.backBinding = false;
    }
    this.state.resume = {
      active: false,
      focused: true
    };
    let lastViewedPage = homeStore.state.lastViewedPage;
    if (lastViewedPage && lastViewedPage.currentPageSection !== 'home') {
      homeActions.loadPage(homeStore.state.lastViewedPage, false);
      homeActions.forceHomeFocusOn('contentSection');
      homeActions.returnToLastSection();
    } else if (homeStore.state.currentMenuSection === 'home') {
      homeActions.returnToLastSection();
    } else {
      homeActions.setPage(homeStore.state.lastPage);
      homeActions.setLastPage(null);
    }
    this.trigger(this.state);
  },
  showActionConfirmation: function (onClose) {
    this.state.actionConfirmation = true;
    this.state.onClose = onClose;
    this.trigger(this.state);
  },

  hideActionConfirmation: function () {
    this.state.actionConfirmation = false;
    if (this.state.onClose) {
      this.state.onClose();
      this.state.onClose = null;
    }
    this.trigger(this.state);
  },

  showEnvSwitcher: function (callback) {
    this.state.envSwitcher = true;
    this.state.envSwitcherCallback = callback;
    this.trigger(this.state);
  },

  hideEnvSwitcher: function () {
    this.state.envSwitcher = false;
    if (this.state.envSwitcherCallback) {
      this.state.envSwitcherCallback();
    }
    this.state.envSwitcherCallback = null;
    this.trigger(this.state);
  },

  showSuitestIdSwitcher: function (callback) {
    this.state.suitestIdSwitcher = true;
    this.state.suitestIdSwitcherCallback = callback;
    this.trigger(this.state);
  },

  hideSuitestIdSwitcher: function () {
    this.state.suitestIdSwitcher = false;
    if (this.state.suitestIdSwitcherCallback) {
      this.state.suitestIdSwitcherCallback();
    }
    this.state.suitestIdSwitcherCallback = null;
    this.trigger(this.state);
  },

  showSpoofSwitcher: function (callback) {
    this.state.spoofSwitcher = true;
    this.state.spoofSwitcherCallback = callback;
    this.trigger(this.state);
  },

  hideSpoofSwitcher: function () {
    this.state.spoofSwitcher = false;
    if (this.state.spoofSwitcherCallback) {
      this.state.spoofSwitcherCallback();
    }
    this.state.spoofSwitcherCallback = null;
    this.trigger(this.state);
  },

  setInitialLocation: function (initialPath) {
    this.state.initialPath = initialPath;
  },

  showLogoutDialog: function (onClose) {
    this.state.logoutScreen = true;
    this.state.onClose = onClose;
    this.trigger(this.state);
  },

  hideLogoutDialog: function () {
    this.state.logoutScreen = false;
    if (this.state.onClose) {
      this.state.onClose();
      this.state.onClose = null;
    }
    this.trigger(this.state);
  },

  showSignInPrompt: function (callback) {
    this.state.signInPrompt = true;
    this.state.signInPromptCallback = callback;
    this.trigger(this.state);
  },

  hideSignInPrompt: function () {
    this.state.signInPrompt = false;
    if (this.state.signInPromptCallback) {
      this.state.signInPromptCallback();
    }
    this.state.signInPromptCallback = null;
    this.trigger(this.state);
  },

  showPrivacyConsentPrompt: function (callback) {
    this.state.privacyConsentPrompt = true;
    this.state.privacyConsentPromptCallback = callback;
    this.trigger(this.state);
  },

  hidePrivacyConsentPrompt: function () {
    this.state.privacyConsentPrompt = false;
    if (this.state.privacyConsentPromptCallback) {
      this.state.privacyConsentPromptCallback();
    }
    this.state.privacyConsentPromptCallback = null;
    this.trigger(this.state);
  },

  showSunsetMessage: function (callback) {
    this.state.sunsetMessage = true;
    this.state.sunsetMessageCallback = callback;
    this.trigger(this.state);
  },

  hideSunsetMessage: function () {
    this.state.sunsetMessage = false;
    if (this.state.sunsetMessageCallback) {
      this.state.sunsetMessageCallback();
    }
    this.state.sunsetMessageCallback = null;
    this.trigger(this.state);
  }
});
