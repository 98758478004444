/* global tizen:true */

let callback = null;

const defaultValueKeys = {
  size: 'CAPTION_SIZE_STANDARD',
  font: 'CAPTION_FONT_STYLE2',
  color: 'CAPTION_COLOR_WHITE',
  opacity: 'CAPTION_OPACITY_SOLID',
  edge: 'CAPTION_EDGE_NONE'
};

const valueTranslations = {
  size: {
    CAPTION_SIZE_DEFAULT: 'default',
    CAPTION_SIZE_SMALL: 0.7,
    CAPTION_SIZE_STANDARD: 1.0,
    CAPTION_SIZE_LARGE: 1.3,
    CAPTION_SIZE_EXTRA_LARGE: 1.5 // ??
  },
  font: {
    CAPTION_FONT_DEFAULT: 'default',
    CAPTION_FONT_STYLE0: 'default', // ??
    CAPTION_FONT_STYLE1: 'monspace serif',
    CAPTION_FONT_STYLE2: 'serif',
    CAPTION_FONT_STYLE3: 'monospace sans-serif',
    CAPTION_FONT_STYLE4: 'sans-serif',
    CAPTION_FONT_STYLE5: 'casual',
    CAPTION_FONT_STYLE6: 'cursive',
    CAPTION_FONT_STYLE7: 'smallcaps'
  },
  color: {
    CAPTION_COLOR_DEFAULT: 'default',
    CAPTION_COLOR_WHITE: 'white',
    CAPTION_COLOR_BLACK: 'black',
    CAPTION_COLOR_RED: 'red',
    CAPTION_COLOR_GREEN: 'green',
    CAPTION_COLOR_BLUE: 'blue',
    CAPTION_COLOR_YELLOW: 'yellow',
    CAPTION_COLOR_MAGENTA: 'magenta',
    CAPTION_COLOR_CYAN: 'cyan'
  },
  opacity: {
    CAPTION_OPACITY_SOLID: 1,
    CAPTION_OPACITY_FLASHING: 'flashing',
    CAPTION_OPACITY_TRANSLUCENT: 0.3,
    CAPTION_OPACITY_TRANSPARENT: 0,
    CAPTION_OPACITY_DEFAULT: 'default'
  },
  edge: {
    CAPTION_EDGE_NONE: 'none',
    CAPTION_EDGE_RAISED: 'raised',
    CAPTION_EDGE_DEPRESSED: 'depressed',
    CAPTION_EDGE_UNIFORM: 'uniform',
    CAPTION_EDGE_DROP_SHADOWED: 'shadow'
  }
};

const keyTranslations = {
  CAPTION_FONT_SIZE_KEY: 'size',
  CAPTION_FONT_STYLE_KEY: 'font',
  CAPTION_FONT_COLOR_KEY: 'color',
  CAPTION_FONT_OPACITY_KEY: 'opacity',
  CAPTION_BG_COLOR_KEY: 'color',
  CAPTION_BG_OPACITY_KEY: 'opacity',
  CAPTION_EDGE_TYPE_KEY: 'edge',
  CAPTION_EDGE_COLOR_KEY: 'color',
  CAPTION_WINDOW_COLOR_KEY: 'color',
  CAPTION_WINDOW_OPACITY_KEY: 'opacity'
};

const keyNames = Object.keys(keyTranslations);

const resultTranslations = {
  CAPTION_FONT_SIZE_KEY: 'textSize',
  CAPTION_FONT_STYLE_KEY: 'textFont',
  CAPTION_FONT_COLOR_KEY: 'textColor',
  CAPTION_FONT_OPACITY_KEY: 'textOpacity',
  CAPTION_BG_COLOR_KEY: 'backgroundColor',
  CAPTION_BG_OPACITY_KEY: 'backgroundOpacity',
  CAPTION_EDGE_TYPE_KEY: 'textEdgeStyle',
  CAPTION_EDGE_COLOR_KEY: 'textEdgeColor', // ???
  CAPTION_WINDOW_COLOR_KEY: 'windowColor',
  CAPTION_WINDOW_OPACITY_KEY: 'windowOpacity'
};

const getCaptionValue = (k) => {
  const value = tizen.tvinfo.getCaptionValue(k);
  const valueKind = keyTranslations[k];
  const valueTable = valueTranslations[valueKind];
  const translatedValue = valueTable[value];

  // need to test 'override' on device...
  if (translatedValue === 'default') {
    if (k === 'CAPTION_BG_COLOR_KEY' || k === 'CAPTION_WINDOW_COLOR_KEY') {
      return { value: 'black', override: true };
    } else if (k === 'CAPTION_FONT_COLOR_KEY') {
      return { value: 'white', override: true };
    } else if (k === 'CAPTION_BG_OPACITY_KEY') {
      return { value: 255, override: true };
    }
    const defaultKey = defaultValueKeys[valueKind];
    const defaultValue = valueTable[defaultKey];
    return { value: defaultValue, override: true };
  } else {
    return { value: translatedValue, override: false };
  }
};

const getCaptions = () => {
  console.log('tizen get captions:');
  const result = {};
  keyNames.forEach((k) => {
    result[resultTranslations[k]] = getCaptionValue(k);
  });
  console.log('----------------------------------------');
  console.log(result);
  return result;
};

let initialized = false;
const initCaptions = () => {
  if (initialized) return;
  console.log('initializing tizen captions.');
  initialized = true;
  const cb = () => {
    console.log('tizen captions: got caption value change.');
    if (callback) callback();
  };
  keyNames.forEach((k) => {
    try {
      tizen.tvinfo.addCaptionValueChangeListener(k, cb);
    } catch (ex) {
      console.error('error adding caption listener: ' + k, ex);
    }
  });
};

export function loadTizenCaptionsConfig() {
  initCaptions();
  return getCaptions();
}

export function setTizenCaptionsChangedCallback(fn) {
  callback = fn;
}
