import conf from '../../../conf';

const generateID = function () {
  // Note no guarantee this is actually unique.
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Base Platform (also the browser platform)
 **/
export default class BasePlatform {
  /**
   * base platform constructor
   */
  constructor() {
    /**
     * @property {boolean} needsProxy - whether the platform needs a proxy
     */
    this.needsProxy = true;
    /**
     * @property {boolean} whether the platform supports the magic wand.
     */
    this.supportsMagicWandNatively = false;
    /**
     * @property {boolean} whether the platform supports the magic wand.
     */
    this.startHistoryLength = window.history.length;
  }

  /**
   * Platform name
   * @returns {string}
   */
  name() {
    return {
      name: 'Browser',
      variation: 'Base'
    };
  }

  /**
   * initialize the platform, should be called after dom is render
   * usually should be called on componentDidMount of main react
   * component
   *
   * @param {function} [callback] - callback to be called once initialization is complete
   */
  init(callback) {
    console.info('default platform init');
    if (callback) callback();
  }

  /**
   * whether this platform needs a proxy
   * @returns {boolean} whether the platform needs to be proxied for CORS requests
   */
  needsProxy() {
    return this.needsProxy;
  }

  /**
   * whether this platform supports a magic wand
   * @returns {boolean} whether the platform supports the magic wand
   */
  supportsMagicWandNatively() {
    return this.supportsMagicWandNatively;
  }

  /**
   * disable the platform screen saver
   */
  disableScreenSaver() {
    console.warn('platform does not know how to disable screensaver');
  }

  /**
   * enable the platform screen saver
   */
  enableScreenSaver() {
    console.warn('platform does not know how to enable screensaver');
  }

  /**
   * get the platform local storage, generally browser localStorage. Custom implementations
   * should return an object that confroms to the localStorage API
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
   *
   * @returns {Object} platform specific localStorage should conform to the local
   */
  localStorage() {
    if (conf.useWeinre || conf.delayWeinre) {
      return {
        setItem: () => {},
        getItem: () => {
          return null;
        },
        removeItem: () => {
          return null;
        },
        clear: () => {
          return null;
        }
      };
    } else if (typeof window !== 'undefined') {
      return window.localStorage;
    }
  }

  /**
   * exit the application
   * @param {boolean} [exitToMenu=false]
   */
  exit() {
    console.log('exiting platform (default)');
    const backlength = window.history.length - this.startHistoryLength - 1;
    console.info('Base Platform, exiting (going back %s history items) ....', backlength);
    window.history.back(backlength);
  }

  /**
   * Get the device id
   */
  deviceId() {
    console.log('No device ID method provided, creating one.');
    const localStorage = this.localStorage();
    let savedId = localStorage.getItem('rbtv.deviceid');
    if (!savedId) {
      savedId = generateID();
      localStorage.setItem('rbtv.deviceid', savedId);
    }
    return savedId;
  }

  /**
   * Get the device type
   */
  deviceType() {
    return navigator.appCodeName + ' - ' + navigator.appName;
  }

  /**
   * get a unique device id
   */
  uid() {
    return this.deviceId().replace(' ', '');
  }

  /**
   * get deice browser version
   */
  getDeviceBrowserVersion() {
    const version = navigator.appVersion.match(/^[^\s]*/)[0] || null;
    return version;
  }

  getDeviceLanguage() {
    // We only check for the device language on app install
    const localStorage = this.localStorage();
    let savedLanguage = localStorage.getItem('rbtv.deviceLanguage');
    if (!savedLanguage) {
      savedLanguage = (
        navigator.language || (conf.appNamespace === 'servustv' ? 'de-DE' : 'en-us')
      ).toLowerCase();
      localStorage.setItem('rbtv.deviceLanguage', savedLanguage);
    }
    return savedLanguage;
  }

  supportsHDR() {
    return false;
  }

  getVolume() {
    console.info(`getVideo returns hard-coded volume of 1`);
    return 1;
  }

  setVolume(value, restoreVolumeOnExit) {
    console.info(`setVolume to ${value} ignored`);
  }

  areCaptionsEnabled() {
    // Default is to have captions disabled, unless the user has explicitly enabled them in their system menu
    return false;
  }

  getBrand() {
    console.warn('getBrand base epmty value used');
    return '';
  }

  getOS() {
    console.warn('getOS base epmty value used');
    return '';
  }

  getHdmiVersion() {
    console.warn('getHdmiVersion base epmty value used');
    return '';
  }

  getHbbTVVersion() {
    console.warn('getHbbTVVersion base epmty value used');
    return '';
  }

  model() {
    console.warn('model base epmty value used');
    return '';
  }

  firmware() {
    console.warn('firmware base epmty value used');
    return '';
  }

  getDeviceAudioVideoAttributes() {
    console.warn('no auido/video attributes got from device');
    return null;
  }
}
