import conf from '../conf';
import PlatformUtils from './platform';
import _ from 'lodash';
import { startStore } from '../reflux/startStore';
import { appStore } from '../reflux/appStore';
import { settingsStore } from '../reflux/settingsStore';
import { userAccountStore } from '../reflux/userAccountStore';
import { videoPlayerStore } from '../reflux/videoPlayerStore';

if (conf.testBuild) {
  window.testingInfoObj = {
    data: {
      apiUrl: localStorage.getItem('rbtv:testApiUrl') || conf.apiUrl,
      version: conf.version,
      buildParams: conf.buildParams,
      platform: conf.platform,
      appStore: appStore.state,
      settingStore: settingsStore.state,
      startStore: startStore.state,
      userStore: userAccountStore.state,
      videoStore: videoPlayerStore.state,
      getCountryCode: appStore.getCountryCode,
      deviceInfo: _.pickBy(
        {
          'OS version': PlatformUtils.sharedPlatform.getOS(),
          'Player version': PlatformUtils.sharedPlatform.firmware(),
          'User agent': navigator?.userAgent?.toString(),
          'HDR support': PlatformUtils.sharedPlatform.supportsHDR() ? 'Yes' : 'No',
          'HDMI version': PlatformUtils.sharedPlatform.getHdmiVersion(),
          ID: PlatformUtils.sharedPlatform.deviceId()
        },
        _.identity
      )
    },
    get: function getData() {
      this.dataplatform = conf.platform;
      this.dataapiUrl = conf.apiUrl;
      this.dataversion = conf.version;
      this.databuildParams = conf.buildParams;
      this.appStore = appStore.state;
      this.startStore = startStore.state;
      this.userAccountStore = userAccountStore.state;
      this.videoPlayerStore = videoPlayerStore.state;
      this.getCountryCode = appStore.getCountryCode;
      this.datadeviceInfo = _.pickBy(
        {
          'OS version': PlatformUtils.sharedPlatform.getOS(),
          'Player version': PlatformUtils.sharedPlatform.firmware(),
          'User agent': navigator?.userAgent?.toString(),
          'HDR support': PlatformUtils.sharedPlatform.supportsHDR() ? 'Yes' : 'No',
          'HDMI version': PlatformUtils.sharedPlatform.getHdmiVersion()
        },
        _.identity
      );
      return this.data;
    }
  };
}
