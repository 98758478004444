import Reflux from 'reflux';
import { imagePreload as ImagePreload } from '../services/image-preload.js';
import apiImageUrl from '../utils/api-image-url.js';
import api from '../services/api.js';
import conf from '../conf';
import EPGServiceDelegate from '../services/epgServiceDelegate.js';
import { configDataStore } from '../reflux/configDataStore';
import { consumptionStore, consumptionActions } from '../reflux/consumptionStore';
import { homeActions, homeStore } from '../reflux/homeStore';
import { liveChannelsActions } from '../reflux/liveChannelsStore';
import { paginationStore } from '../reflux/paginationStore';
import { playlistStore } from './playlistStore.js';
import PlatformUtils from '../utils/platform';
import _ from 'lodash';
import RefluxPromise from 'reflux-promise';

Reflux.use(RefluxPromise(window.Promise));
const isServus = conf.appNamespace === 'servustv';

export const epgActions = Reflux.createActions({
  getInitialState: {},
  loadEPG: { asyncResult: true },
  loadLinearChannels: { asyncResult: true },
  handleEPGChange: {},
  handleEPGRefresh: {},
  updateEpgIndex: {},
  closeEpg: {},
  fixIndex: {},
  onEnter: {},
  openFromEpg: {},
  showInfoScreen: {},
  hideInfoScreen: {},
  updateEpgContent: {},
  getEPGData: {},
  setChannelPage: {},
  switchLinearChannel: {},
  setUpdateInterval: {},
  backToInfoScreen: {}
});

epgActions.loadEPG.listen(function (id) {
  return EPGServiceDelegate.loadEPG(id).then(this.completed).catch(this.failed);
});

export const epgStore = Reflux.createStore({
  listenables: epgActions,
  state: {
    channel: {},
    EPG: { meta: { limit: 8 } },
    cardList: [],
    allCards: [],
    currentlyPlaying: null,
    timeshifted: false,
    shiftNumber: 0,
    allImagesPreloaded: false,
    pagesRequested: {},
    initialLoadComplete: false,
    linearChannels: { items: [] },
    currentLinearChannel: {},
    currentLinearChannelIndex: 0,
    showLinearChannelTitle: false,
    onclick_display_info: true,
    showInfoScreen: false,
    currentAsset: {},
    epgData: [],
    epgChannelIndex: 0,
    isEmpty: false,
    openFromEpg: false,
    channelPage: false,
    lastChannelIndex: 0,
    lastCardIndex: 0,
    lastFocusedValue: 0,
    closeEpg: false,
    servusUserIsGeoBlocked: false,
    backToInfoScreen: false
  },

  init: function () {
    this.switchLinearChannelTimeout = null;
    this.scheduleEPGRefresh = _.throttle(this.scheduleEPGRefresh, 30000, {
      leading: true,
      trailing: false
    });
  },

  loadEPGFailed: function (error) {
    console.error('EPG failed', error);
  },

  updateEpgIndex: function (data) {
    if (consumptionStore.state.consumptionFocus === 'menu') {
      return;
    }
    const currentFocus = this.state.epgData[this.state.epgChannelIndex].channel.currentFocus || 0;
    if (data === 'right') {
      const length = this.state.epgData[this.state.epgChannelIndex].items.length;
      if (currentFocus === 9 || currentFocus >= length - 1) {
        return;
      }
      this.state.epgData[this.state.epgChannelIndex].channel.currentFocus = currentFocus + 1;
      this.state.currentAsset =
        this.state.epgData[this.state.epgChannelIndex].items[currentFocus + 1];
    }
    if (data === 'left') {
      if (currentFocus === 0) {
        return;
      }
      this.state.epgData[this.state.epgChannelIndex].channel.currentFocus = currentFocus - 1;
      this.state.currentAsset =
        this.state.epgData[this.state.epgChannelIndex].items[currentFocus - 1];
    }
    if (data === 'up') {
      if (this.state.epgChannelIndex === 0) {
        if (consumptionStore.state.consumptionFocus === 'content') {
          epgActions.closeEpg();
          if (
            consumptionStore.state.backToProgramm ||
            homeStore.state.currentMenuSection === 'programm'
          ) {
            consumptionActions.setFocusOnMainMenu();
          }
        }
        return;
      }
      this.state.epgChannelIndex = this.state.epgChannelIndex - 1;
      const lastIndex = this.state.epgData[this.state.epgChannelIndex + 1].channel.currentFocus;
      if (this.state.epgData[this.state.epgChannelIndex].channel.currentFocus < 3) {
        if (lastIndex < 3) {
          this.state.epgData[this.state.epgChannelIndex].channel.currentFocus = lastIndex;
        } else {
          this.state.epgData[this.state.epgChannelIndex].channel.currentFocus = 2;
        }
      }
      this.state.currentAsset = this.state.epgData[this.state.epgChannelIndex].items[currentFocus];
    }
    if (data === 'down') {
      const length = this.state.epgData.length;
      if (this.state.epgChannelIndex >= length - 1) {
        return;
      }
      this.state.epgChannelIndex = this.state.epgChannelIndex + 1;
      const lastIndex = this.state.epgData[this.state.epgChannelIndex - 1].channel.currentFocus;
      if (this.state.epgData[this.state.epgChannelIndex].channel.currentFocus < 3) {
        if (lastIndex < 3) {
          this.state.epgData[this.state.epgChannelIndex].channel.currentFocus = lastIndex;
        } else {
          this.state.epgData[this.state.epgChannelIndex].channel.currentFocus = 2;
        }
      }
      this.state.currentAsset = this.state.epgData[this.state.epgChannelIndex].items[currentFocus];
    }
    this.trigger(this.state);
  },

  loadEPGCompleted: function (data) {
    if (!data || (data.meta && data.meta.total === 0) || (data.items && data.items.length === 0)) {
      console.error('EPG returned no data');
      epgStore.loadEPGFailed();
    }
    if (!this.state.initialLoadComplete) {
      this.setEPGData(data);

      this.state.shiftNumber = 0;
      this.state.allImagesPreloaded = false;
      this.state.initialLoadComplete = true;
    } else {
      const pageSize = data.meta.limit;
      const pageNumber = data.meta.offset / data.meta.limit;
      const start = pageNumber * pageSize;
      console.log(
        'pageNumber=' + pageNumber + ' pageNumber - 1=' + pageNumber + ' pageSize=' + pageSize
      );
      const newItems = this.state.EPG.items.slice();
      data.items.forEach((item, idx) => {
        newItems[start + idx] = item;
      });
      this.state.allCards = this.state.EPG.items = newItems;
      this.trigger(this.state);
    }
    this.scheduleEPGRefresh();
  },

  loadLinearChannelsCompleted: function (linearChannelsData) {
    if (!linearChannelsData) {
      console.warn('No linear channels data');
      return;
    }

    if (
      PlatformUtils.isMultiLinearDisabled() &&
      linearChannelsData.items &&
      linearChannelsData.items.length
    ) {
      linearChannelsData.items = linearChannelsData.items.filter(
        (channel) => channel.id === PlatformUtils.getDefaultLinearID()
      );
    }

    this.state.linearChannels = linearChannelsData;
    this.trigger(this.state);

    // TODO: Find a better place to put this data, other than homeStore.
    // Only putting it directly in homeStore for now
    // because the homeStore is not able to import epgStore, startStore or consumptionStore without a circular import error
    homeActions.setLinearChannels(linearChannelsData);
    this._preloadLinearChannelImages();
  },

  setEPGData: function (data) {
    this.state.EPG.id = data.id;
    this.state.EPG.meta = data.meta || {};
    this.state.EPG.items = data.items || [];

    this.scheduleEPGRefresh();
    if (PlatformUtils.isMultiLinearDisabled()) {
      this.state.EPG.title = conf.appNamespace === 'servustv' ? 'ServusTV' : 'Red Bull TV';
      this.updateCurrentLinearChannel();
    }

    if (this.state.EPG && this.state.EPG.items) {
      const allTVCards = this.state.EPG.items || [];

      this.state.allCards = allTVCards;

      this.state.cardList = this.getCards();
      console.log('setEPGData - this.getTimeUntilNextVideo() = ' + this.getTimeUntilNextVideo());
      clearTimeout(this.state.epgTimeout);
      this.state.epgTimeout = window.setTimeout(
        this.handleEPGChange,
        this.getTimeUntilNextVideo() + 250 > 2147483647
          ? 2147483647
          : this.getTimeUntilNextVideo() + 250
      );
      this.preloadSomeEPGImages();
    } else {
      clearTimeout(this.state.epgTimeout);
    }
  },

  preloadSomeEPGImages: function () {
    this._preloadEPGImages(0, 30);
  },

  preloadRemainingEPGImages: function () {
    if (this.state.allImagesPreloaded) {
      return;
    }
    this._preloadEPGImages(30 - this.state.shiftNumber);
    this.state.allImagesPreloaded = true;
  },

  _preloadEPGImages: function (begin, end) {
    if (!conf.fullPreload) {
      return;
    }
    const preloadImage = new ImagePreload();

    this.getCards()
      .slice(begin, end)
      .forEach((card, idx) => {
        // TV playlist cards
        preloadImage.preloadSource(
          apiImageUrl.getImageByType('schedule-item', card.resources, card.id)
        );
      });
  },

  _preloadLinearChannelImages: function () {
    const preloadImage = new ImagePreload();
    if (!this.state.linearChannels.items) {
      return;
    }

    this.state.linearChannels.items.slice(0, 6).forEach((card, idx) => {
      // Preload images for the first 6 linear channel cards that show on Home
      preloadImage.preloadSource(
        apiImageUrl.getImageByType('linear-channel', card.resources, card.id)
      );
    });
  },

  scheduleEPGRefresh: function () {
    const ttl = 30000;
    console.log(` epg ttl in seconds = ${ttl / 1000}`);
    //this.state.epgRefreshTimeout = window.setTimeout(epgActions.handleEPGRefresh, ttl);
  },

  handleEPGRefresh: function () {
    if (this.state.epgRefreshTimeout) {
      clearTimeout(this.state.epgRefreshTimeout);
    }
    epgActions.loadEPG(this.state.EPG.id);
  },

  handleEPGChange: function () {
    // Remove first element, set another timeout
    this.state.cardList = this.getCards();
    this.state.shiftNumber = this.state.shiftNumber + 1;
    console.log('HANDLING CHANGE - ' + this.getTimeUntilNextVideo());
    this.state.epgTimeout = window.setTimeout(
      this.handleEPGChange,
      this.getTimeUntilNextVideo() + 250 > 2147483647
        ? 2147483647
        : this.getTimeUntilNextVideo() + 250
    );
    this.trigger(this.state);
    if (this.state.cardList.length && consumptionStore.state.isLinear) {
      consumptionActions.updateContentObjectForEPG(this.getCurrentVideo());
    }
  },

  clearEPGTimeouts: function () {
    // We run two timeouts for the EPG:
    // 1) To refresh the data based on a TTL
    clearTimeout(this.state.epgRefreshTimeout);
    // 2) To handle removal of the first item of the list when that section of the EPG is finished
    clearTimeout(this.state.epgTimeout);
    // We need to clear both of these when the user switches away from linear to VOD
  },

  getTimeUntilNextVideo: function () {
    const currentVideo = this.getCurrentVideo();
    if (currentVideo) {
      const currentlyPlayingEnd = this.calculateDate(currentVideo.end_time);
      const currentTime = new Date();
      console.log('currentlyPlayingEnd - currentTime = ' + (currentlyPlayingEnd - currentTime));

      const nextVideoStartInterval = currentlyPlayingEnd - currentTime;

      if (nextVideoStartInterval < 0) {
        console.warn('EPG data incorrect - current video in past.');
        return 1800000;
      }

      return nextVideoStartInterval;
    } else {
      console.warn('No EPG data');
      return 1800000;
    }
  },

  getCards: function () {
    if (!(this.state.allCards && this.state.allCards.length)) return [];
    const idx = this._firstRelevantCardIndex(this.state.allCards);
    return this.state.allCards.slice(idx);
  },

  getCurrentVideo: function () {
    if (!this.state.allCards) return null;
    if (!this.state.allCards.length) return null;

    const cards = this.state.allCards;
    const idx = this._firstRelevantCardIndex(cards);

    const currentProgram = cards[idx];

    if (!currentProgram) return null;
    return currentProgram;
  },

  _endTime: function (card) {
    if (!card) {
      return null;
    }
    if (!card._end_time_for_EPG) {
      card._end_time_for_EPG = +this.calculateDate(card.end_time);
    }
    return card._end_time_for_EPG;
  },

  _firstRelevantCardIndex(cards) {
    const now = +new Date();
    let i = 0;
    while (i < cards.length - 2 && this._endTime(cards[i]) < now) {
      i++;
    }
    return i;
  },

  calculateDate: function (cardDate) {
    let epgDate = new Date(cardDate);
    if (this.state.timeshifted) {
      // TODO: Not sure if this offset is reliable - update as needed when we see real timeshifted EPG.
      epgDate = new Date(epgDate - new Date().getTimezoneOffset());
    }

    return epgDate;
  },

  getLinearStream: function () {
    return {
      id: 'linear',
      stream_url: api.getVideoUrl(configDataStore.getConstant('linear_stream_id'))
    };
  },

  switchLinearChannel: function (isRight) {
    console.log('epgStore.js - switchLinearChannel');
    if (this.state.epgData.length === 0 || !_.has(this.state, 'linearChannels.items')) {
      return;
    }
    const linearChannels = this.state.linearChannels.items;
    const defaultLinear = PlatformUtils.getDefaultLinearID();
    if (linearChannels.findIndex((e) => e.id === defaultLinear) === -1) {
      const defaultChannel = { id: defaultLinear, selectedId: 0 };
      linearChannels.unshift(defaultChannel);
    }
    const linearChannelsLength = linearChannels.length;
    const currentChannelIndex = this.state.currentLinearChannelIndex;
    let nextChannelIndex;
    if (isRight) {
      if (currentChannelIndex < linearChannelsLength - 1) {
        nextChannelIndex = currentChannelIndex + 1;
      } else {
        nextChannelIndex = 0;
      }
    } else if (!isRight) {
      if (currentChannelIndex > 0) {
        nextChannelIndex = currentChannelIndex - 1;
      } else {
        nextChannelIndex = linearChannelsLength - 1;
      }
    }
    this.state.currentLinearChannel = linearChannels[nextChannelIndex];
    this.state.currentLinearChannelIndex = nextChannelIndex;
    this.state.allCards = [];

    console.log('this.switchLinearChannelTimeout=', this.switchLinearChannelTimeout);

    if (this.switchLinearChannelTimeout) {
      clearTimeout(this.switchLinearChannelTimeout);
      this.switchLinearChannelTimeout = setTimeout(() => {
        consumptionActions.setAndPlayLinearChannel(this.state.currentLinearChannel.id);
        liveChannelsActions.updatePositionIndex(this.state.currentLinearChannelIndex);
        this.switchLinearChannelTimeout = null;
      }, 1000);
    } else {
      consumptionActions.setAndPlayLinearChannel(this.state.currentLinearChannel.id);
      liveChannelsActions.updatePositionIndex(this.state.currentLinearChannelIndex);
      this.switchLinearChannelTimeout = setTimeout(() => {
        this.switchLinearChannelTimeout = null;
      }, 1000);
    }
  },

  updateCurrentLinearChannel: function (id) {
    if (PlatformUtils.isMultiLinearDisabled()) {
      this.state.currentLinearChannelIndex = 0;
      this.state.currentLinearChannel = this.state.EPG;
      this.state.currentLinearChannel.id = PlatformUtils.getDefaultLinearID();
      return;
    }
    if (this.state.epgData && this.state.epgData.length) {
      const currentEpgIndex = _.findIndex(this.state.epgData, { id });
      this.state.epgChannelIndex = currentEpgIndex;
      this.trigger(this.state);
    }
    let currentLinearChannelIndex = _.findIndex(this.state.linearChannels.items, { id });
    if (currentLinearChannelIndex === -1) {
      const linearChannels = this.state.linearChannels;
      if (
        linearChannels &&
        linearChannels.items &&
        linearChannels.meta &&
        linearChannels.items.length < linearChannels.meta.total
      ) {
        paginationStore.loadRemainingCollectionPages(linearChannels);
        setTimeout(() => this.updateCurrentLinearChannel(id), 1000);
        return;
      } else {
        if (isServus) {
          // set default currentLinear because for Servus we don't have a default one in this.state.linearChannels (feature)
          this.state.currentLinearChannel = {
            id: PlatformUtils.getDefaultLinearID()
          };
          this.trigger(this.state);
          return;
        }

        console.error("Couldn't find linear channel " + id);
        currentLinearChannelIndex = 0;
      }
    }
    this.state.currentLinearChannelIndex = currentLinearChannelIndex;
    this.state.currentLinearChannel = this.state.linearChannels.items[currentLinearChannelIndex];
  },

  fixIndex: function () {
    this.state.epgChannelIndex = this.state.lastChannelIndex;
    this.state.epgData[this.state.epgChannelIndex].channel.currentFocus = this.state.lastCardIndex;
    this.state.currentAsset =
      this.state.epgData[this.state.lastChannelIndex].items[this.state.lastCardIndex];
    this.trigger(this.state);
  },

  onEnter: function () {
    if (consumptionStore.state.consumptionFocus === 'menu') {
      return;
    }
    const now = new Date().getTime();
    const start = new Date(this.state.currentAsset.start_time).getTime();
    if (this.state.onclick_display_info && this.state.currentAsset?.id !== 'empty') {
      this.state.lastChannelIndex = this.state.epgChannelIndex;
      this.state.lastCardIndex =
        this.state.epgData[this.state.epgChannelIndex].channel.currentFocus;

      if (_.isEmpty(this.state.currentAsset)) {
        const currentFocus = this.state.epgData[this.state.epgChannelIndex].channel.currentFocus;
        this.state.currentAsset =
          this.state.epgData[this.state.epgChannelIndex].items[currentFocus];
      }
      this.showInfoScreen();
      this.trigger(this.state);
    } else {
      if (now > start || this.state.epgData[this.state.epgChannelIndex].items[0].id === 'empty') {
        const id = this.state.epgData[this.state.epgChannelIndex].channel.id;
        this.trigger(this.state);
        consumptionActions.setAndPlayLinearChannel(id, true);
        setTimeout(() => {
          consumptionActions.hideLiveGuideDrawer();
          consumptionActions.resetHiddenControls();
        }, 500);
      } else {
        if (this.state.showInfoScreen) return;
        this.openFromEpg();
        playlistStore.playDeeplinkPlaylistFromProduct(epgStore.state.currentAsset.id);
      }
    }
  },

  openFromEpg: function (state = true) {
    this.state.openFromEpg = state;
    this.trigger(this.state);
  },

  backToInfoScreen: function () {
    this.state.backToInfoScreen = true;
    this.trigger(this.state);
  },

  showInfoScreen: function () {
    this.state.showInfoScreen = true;
    this.trigger(this.state);
  },

  hideInfoScreen: function () {
    this.state.showInfoScreen = false;
    this.state.backToInfoScreen = false;
    this.trigger(this.state);
  },

  updateEpgContent: function () {
    let emptyChannels = 0;
    const data = this.state.epgData;
    if (!data) {
      this.state.isEmpty = true;
      this.trigger(this.state);
      return;
    }
    data.forEach((element, index) => {
      if (
        element &&
        element.items &&
        element.items.length > 0 &&
        element.items[0].id !== element.id
      ) {
        this.state.isEmpty = false;
        element.items.forEach((item) => {
          const now = new Date().getTime();
          const end = new Date(item.end_time).getTime();
          if (now > end) {
            const newArray = element.items.slice(1, element.items.length);
            this.state.epgData[index].items = newArray;
          }
        });
      } else {
        emptyChannels++;
        if (emptyChannels === data.length - 1) {
          this.state.isEmpty = true;
        }
        const newArray = [];
        newArray.push({ id: 'empty', channel: element.id, title: element.channel.title });
        this.state.epgData[index].items = newArray;
      }
    });
    this.trigger(this.state);
  },

  getEPGData: async function () {
    const channelsData = [];
    const promises = [];
    const channels = [];
    if (conf.appNamespace === 'servustv') {
      channels.push({ id: PlatformUtils.getDefaultLinearID() });
    }
    epgStore.state.linearChannels.items.forEach((element) => {
      channels.push(element);
    });
    channels.forEach((element) => {
      promises.push(
        new Promise((resolve, reject) => {
          resolve(api.getEPG(element.id));
        })
      );
    });

    try {
      const resolvedData = await Promise.all(promises);
      let index = 0;

      epgStore.state.linearChannels.items.forEach(async function (element) {
        const object = resolvedData.find((object, index) => {
          if (!object.id) {
            object.id = PlatformUtils.getDefaultLinearID();
            return element.id === PlatformUtils.getDefaultLinearID();
          }
          if (object.id === epgStore.state.currentLinearChannel.id) {
            epgStore.state.epgChannelIndex = index;
          }
          return object.id === element.id;
        });
        if (object) {
          channelsData[index] = {};
          channelsData[index] = Object.assign({}, object);
          channelsData[index].channel = element;
          channelsData[index].channel.resources = ['rbtv_display_art_landscape'];
          channelsData[index].channel.currentFocus = 0;
          index = index + 1;
        }
      });
      if (conf.appNamespace === 'servustv') {
        const found = resolvedData.find(
          (element) => element.id === PlatformUtils.getDefaultLinearID()
        );
        let defaultChannel = {};
        defaultChannel = found || {};
        if (PlatformUtils.isMultiLinearDisabled() && resolvedData[0]?.items) {
          defaultChannel.items = resolvedData[0].items;
        }
        defaultChannel.channel = {
          isChannel: true,
          isLinear: true,
          isLive: true,
          content_type: 'subchannel',
          subchannel_type: 'linear',
          title: '',
          id: PlatformUtils.getDefaultLinearID(),
          resources: ['rbtv_display_art_landscape'],
          currentFocus: 0
        };
        channelsData.unshift(defaultChannel);
      }
    } catch (error) {
      if (error === 403) this.state.servusUserIsGeoBlocked = true;
      console.log('Error: failed to load epg data');
    }
    this.state.epgData = channelsData;

    if (conf.appNamespace === 'servustv' && epgStore.state.servusUserIsGeoBlocked) {
      this.trigger(this.state);
      return;
    }

    if (
      !epgStore.state.epgData[epgStore.state.epgChannelIndex]?.items ||
      epgStore.state.epgData[epgStore.state.epgChannelIndex].items.length === 0
    ) {
      const index = epgStore.state.epgData.findIndex(
        (element) => element.items && element.items.length > 0
      );
      epgStore.state.epgChannelIndex = index;
    }
    epgActions.updateEpgContent();
    this.trigger(this.state);
  },

  setUpdateInterval: function () {
    this.updateInterval = setInterval(() => {
      epgActions.updateEpgContent();
    }, 5000);
  },

  closeEpg: function () {
    clearInterval(this.updateInterval);
    this.state.closeEpg = true;
    this.trigger(this.state);
    this.state.closeEpg = false;
    consumptionActions.hideLiveGuideDrawer();
    this.trigger(this.state);
  },

  setChannelPage: function (val) {
    this.state.channelPage = val || false;
    this.trigger(this.state);
  }
});
