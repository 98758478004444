import Reflux from 'reflux';

export const videoTimeProxyActions = Reflux.createActions(['setCurrentTime']);

export const videoTimeProxyStore = Reflux.createStore({
  listenables: videoTimeProxyActions,
  state: {},
  lazyCurrentTime: 0,
  oneSecondTime: 0,
  setCurrentTime: function (playerState) {
    var lazyTimeUpdate =
      (playerState.currentTime < this.lazyCurrentTime ||
        playerState.currentTime >= Math.floor(this.lazyCurrentTime + 3)) &&
      !this.state.ended;
    var oneSecondTimeUpdate =
      (playerState.currentTime < this.oneSecondTime ||
        playerState.currentTime >= Math.floor(this.oneSecondTime + 1)) &&
      !this.state.ended;
    this.state = playerState;

    if (oneSecondTimeUpdate) {
      this.trigger({
        data: this.state,
        originator: lazyTimeUpdate ? 'lazyCurrentTime' : 'oneSecondCurrentTime'
      });
      this.oneSecondTime = playerState.currentTime;
      if (lazyTimeUpdate) this.lazyCurrentTime = playerState.currentTime;
    } else {
      this.trigger({
        data: this.state,
        originator: 'currentTime'
      });
    }
  }
});
