import React from 'react';
import cx from 'classnames';

export default class CardThumbnail extends React.Component {
  constructor(p) {
    super(p);

    this.state = {
      src: null,
      loaded: false
    };

    this.setSource = this.setSource.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount() {
    this.setSource();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.setSource();
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  clear() {
    if (this.loader) {
      this.loader.onload = null;
    }
  }

  setSource() {
    this.clear();

    if (this.props.src) {
      this.setState({ src: this.props.src, loaded: false });

      this.loader = new Image();
      this.loader.src = this.props.src;
      this.loader.onload = () => this.setState({ loaded: true });
    } else {
      this.setState({ src: null, loaded: false });
    }
  }

  render() {
    const { loaded, src } = this.state;
    const classNames = cx('image-wrapper', {
      'image-wrapper--loading': !loaded,
      'image-wrapper--has-src': !!src
    });

    if (!loaded || !src) {
      return <div className={classNames} />;
    }

    return (
      <div className={classNames}>
        <img src={src} />
      </div>
    );
  }
}
