import React from 'react';
import CardMenu from '../card-menu/card-menu';
import ScheduleList from '../schedule-list/schedule-list';
import { configDataStore } from '../../reflux/configDataStore';
import { scheduleActions, scheduleStore } from '../../reflux/scheduleStore';

export default class CardRow extends React.PureComponent {
  render() {
    if (this.props.hide) return null;
    if (this.props.type === 'schedule' && !this.props.isContextual) {
      let onEnter = function (card) {
        if (card.status && card.status.code !== 'live' && card.status.code !== 'post') {
          return;
        }

        this.props.onEnter(card);
      };
      return (
        <ScheduleList
          {...this.props}
          onEnter={onEnter.bind(this)}
          onChange={scheduleActions.updatePositionIndex}
          setInitialFocusIndex={scheduleStore.state.focusPosition}
        />
      );
    } else {
      let menuItems = this.props.menuItems;
      if (this.props.row.isTopResults) {
        let topResultsLength = configDataStore.getConstant('search_top_results_limit');
        menuItems = menuItems.slice(0, topResultsLength);
      }
      let isScheduleChangedToVideoList;
      if (this.props.type === 'schedule' && this.props.isContextual) {
        isScheduleChangedToVideoList = true;
      }

      return (
        <CardMenu
          {...this.props}
          menuItems={menuItems}
          isScheduleChangedToVideoList={isScheduleChangedToVideoList}
        />
      );
    }
  }
}
