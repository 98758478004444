import { localizationStore } from '../reflux/localizationStore';

var TimeFormatter = function () {
  var tf = {};

  tf.toHmsString = function (time) {
    var hours = Math.floor(time / 3600);
    var minutes = Math.floor((time - hours * 3600) / 60);
    var seconds = Math.floor(time - (hours * 3600 + minutes * 60));

    var timeString = '';
    timeString += hours > 0 ? this.pad(hours, 2) + ':' : '';
    timeString += this.pad(minutes, 2) + ':' + this.pad(seconds, 2);

    return timeString;
  };

  tf.toHmsReadString = function (time) {
    var hours = Math.floor(time / 3600);
    var minutes = Math.floor((time - hours * 3600) / 60);
    var seconds = Math.floor(time - (hours * 3600 + minutes * 60));

    var timeString = '';
    timeString += hours > 0 ? hours + ' hours, ' : '';
    timeString += minutes > 0 ? minutes + ' minutes, ' : '';
    timeString += seconds + ' seconds';

    return timeString;
  };

  tf.getHumanReadableDuration = function (duration) {
    if (!duration) {
      return '';
    }

    const out = [];
    const msInSecond = 1000;
    const msInMinute = msInSecond * 60;
    const msInHour = msInMinute * 60;
    if (duration >= msInMinute) {
      const hours = Math.trunc(duration / msInHour);
      if (hours > 0) {
        out.push(`${hours} ${localizationStore._GET('hour').toLocaleUpperCase()}`);
        duration = duration - hours * msInHour;
      }

      const minutes = Math.trunc(duration / msInMinute);
      if (minutes > 0) {
        out.push(`${minutes} ${localizationStore._GET('minute').toLocaleUpperCase()}`);
        duration = duration - minutes * msInMinute;
      }
    } else {
      const seconds = Math.trunc(duration / msInSecond);
      out.push(`${seconds} ${localizationStore._GET('second').toLocaleUpperCase()}`);
    }

    return out.join(' ');
  };

  tf.hmsToSeconds = function (hmsString) {
    var hms = hmsString.split(':');
    return parseInt(hms[0], 10) * 3600 + parseInt(hms[1], 10) * 60 + parseInt(hms[2], 10);
  };

  tf.pad = function (n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  };

  return tf;
};

module.exports = TimeFormatter;
