import Reflux from 'reflux';
import { iTrackingActions } from '../../reflux/iTrackingStore';
import isElementInView from '../../utils/isElementInView';
import conf from '../../conf';

export default class BaseCard extends Reflux.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focused: props.focused
    };

    this.wasFocused = false;
    this.readTimeout = 0;
  }

  componentDidMount() {
    this.checkReadAria();
    this.isServus = conf.appNamespace === 'servustv';
    this.track();
  }

  componentDidUpdate() {
    this.checkReadAria();

    if (!this.props.iTrackingLargest || this.props.iTrackingLargest < this.props.iTrackingIndex) {
      this.track();
    }
  }

  track() {
    if (!this.ref?.current) return;

    const inView = isElementInView(this.ref.current);
    if (inView) return;

    iTrackingActions.addToBatch({
      row: this.props.iTrackingRow,
      index: this.props.iTrackingIndex,
      title: `${document.location.pathname}:||:${this.props.rowType}:||:${this.props.title}`,
      id: this.props.id,
      type: this.props.type
    });
  }

  checkReadAria() {
    if (this.props.focused && this.props.focused !== this.wasFocused) {
      if (this.readAria) {
        this.readTimeout = setTimeout(() => {
          if (this.props.focused) this.readAria();
        }, 300);
      }
    } else if (!this.props.focused && this.readTimeout) {
      clearTimeout(this.readTimeout);
      this.readTimeout = 0;
    }

    this.wasFocused = this.props.focused;
  }

  render() {
    return <div></div>;
  }
}
