import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import getParentId from '../../utils/get-parent-id';
import PlatformUtils from '../../utils/platform.js';
import apiImageUrl, { getTitleTreatment, getThumbnail } from '../../utils/api-image-url';
import BaseCard from '../card/base-card';
import CardLabel from '../card-label/card-label';
import CardProgressBar from '../card-progress-bar/card-progress-bar';
import CardThumbnail from '../card-thumbnail/card-thumbnail';
import { settingsStore } from '../../reflux/settingsStore';
import { Icon } from '../page-buttons/page-buttons';

const ALLOWED_LABELS = [
  'live',
  'unavailable',
  'watching',
  'pre',
  'trim',
  'delayed',
  'canceled',
  'post'
];
const SUPPORT_VIDEO_PREVIEWS = PlatformUtils.supportCardVideoPreviews();
export default class FeaturedCard extends Reflux.Component.extend(BaseCard) {
  constructor(p) {
    super(p);
    this.state = {
      titleTreatmentLoaded: false,
      titleTreatment: null,
      artwork: null,
      height: 0
    };
    this.ref = React.createRef();
    this.setArtworks = this.setArtworks.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.setArtworks();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      this.props.id !== prevProps.id ||
      !isEqual(this.props.resources, prevProps.resources) ||
      (!this.props.isPlaceholder && prevProps.isPlaceholder)
    ) {
      this.setArtworks();
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  clear() {
    if (this.titleTreatmentLoader) {
      this.titleTreatmentLoader.onload = null;
    }
  }

  setArtworks() {
    this.clear();

    const { id, resources } = this.props;
    const parentId = getParentId(this.props);
    const artwork = getThumbnail(resources, id, 'featured');
    const titleTreatment = getTitleTreatment(
      resources,
      parentId || id,
      'featuredTitle',
      'featuredTitle'
    );

    this.setState({
      artwork,
      titleTreatment,
      titleTreatmentLoaded: false,
      height: 0
    });

    if (titleTreatment) {
      const update = (height = 0) => {
        this.setState({ titleTreatmentLoaded: true, height });
      };
      this.titleTreatmentLoader = new Image();
      this.titleTreatmentLoader.src = titleTreatment;
      this.titleTreatmentLoader.onload = function () {
        update(this.height);
      };
    }
  }

  render() {
    const classNames = cx('card card--featured', {
      'has-title-treatment': !!this.state.titleTreatment,
      'title-treatment-loaded': this.state.titleTreatmentLoaded,
      'is-placeholder': this.props.isPlaceholder,
      'is-focused': this.props.focused,
      offleft: this.props.offleft
    });

    if (this.props.isPlaceholder) {
      return (
        <div ref={this.ref} className={classNames}>
          <div className="card__inner">
            <div className="card__inner__thumb">
              <div className="image-wrapper image-wrapper--loading" />
            </div>
          </div>
        </div>
      );
    }

    let previewSrc;
    let canShowVideoPreview =
      this.props.focused &&
      this.props.playable &&
      SUPPORT_VIDEO_PREVIEWS &&
      settingsStore.state.settings.shouldShowVideoPreview;

    if (canShowVideoPreview) {
      previewSrc = apiImageUrl.getVideoPreviewURL(
        this.props.id,
        this.props.resources,
        'short_preview_mp4_medium'
      );
    }

    let style = {};
    if (this.state.height) {
      style.height = this.state.height;
    }

    return (
      <div ref={this.ref} className={classNames} role="button">
        <div className="card__inner">
          <div className="card__inner__thumb">
            <CardThumbnail src={this.state.artwork} />
            {canShowVideoPreview && previewSrc ? (
              <video src={previewSrc} autoPlay loop playsInline />
            ) : null}
          </div>
          <div className="card__inner__details">
            <CardLabel
              {...this.props}
              allowed={!this.isServus && ALLOWED_LABELS}
              featured
              readAria={`${this.props.title} : ${this.props.subheading} : list item`}
            />
            <div className="card__inner__details__title" style={style}>
              {this.state.titleTreatment && <img src={this.state.titleTreatment} />}
              <h3>{this.props.title}</h3>
            </div>
            <h4>{this.props.subheading}</h4>
            {this.props.progress && this.props.progress > 0 ? (
              <CardProgressBar
                focused={this.props.focused}
                progress={this.props.progress}
                isCurrentVOD={this.props.isCurrentVOD}
              />
            ) : null}
          </div>
        </div>
        {!(canShowVideoPreview && previewSrc) ? (
          <div className="fav-icon">
            <Icon
              iconName={this.props.isFavorite ? 'star-dark' : 'star-dark-empty'}
              focused={this.props.focused}
              size={48}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
