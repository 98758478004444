import Reflux from 'reflux';

export const exitActions = Reflux.createActions({
  exitApp: {}
});

export const exitStore = Reflux.createStore({
  listenables: exitActions,

  exitApp: function () {
    console.log('exiting');
    this.trigger();
  }
});
