import React from 'react';
import BaseCard from '../card/base-card';
import apiImageUrl from '../../utils/api-image-url';
import cx from 'classnames';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';
import { localizationStore } from '../../reflux/localizationStore';

export default class ViewMoreButton extends BaseCard {
  componentDidMount() {
    super.componentDidMount();

    this.iconSrc = apiImageUrl.getIcon('view-more', true, false);
    this.readText = localizationStore._GET('view_more');
  }

  readAria(cannotBeCancelled) {
    ariaTTSServiceStore.readText(`${this.readText} : button`, cannotBeCancelled);
  }

  render() {
    return (
      <div
        className={cx('view-more-button', this.props.rowType, {
          'is-focused': this.props.focused
        })}
      >
        <div className="view-more-button__inner">
          <div className="view-more-button__inner__icon">
            <img src={this.iconSrc} />
          </div>
          <span className="view-more-button__inner__text">{this.readText}</span>
        </div>
      </div>
    );
  }
}
