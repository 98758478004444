/* global WebVTT: true */
import _ from 'lodash';
import Reflux from 'reflux';
import api from '../services/api.js';
import { imagePreload as ImagePreload } from '../services/image-preload.js';
import conf from '../conf.js';

// Store for handling data contained in playlist.json

let lastRetrievedMetadataID = null;

export const assetMetadataStore = Reflux.createStore({
  state: {
    metadata: {},
    retrievedSpriteVTTs: {}
  },

  init: function () {
    this.preloadImage = new ImagePreload();
  },

  getMetadata: function () {
    return this.state.metadata;
  },

  retrieveTimedMetadata: function (id, callback) {
    lastRetrievedMetadataID = id;
    this.state.metadataForAnalytics = this.state.metadata;
    this.state.metadata = {};
    if (!id || id === 'servus-fallback') {
      callback();
      return;
    }
    api
      .getAssetMetadata(id)
      .then((result) => {
        if (lastRetrievedMetadataID === id) {
          this.state.metadata = result.body;
          if (callback) {
            callback(result && result.body && result.body.dash_available);
          }

          const segment =
            this.state.metadata &&
            this.state.metadata.segments &&
            this.state.metadata.segments.length &&
            this.state.metadata.segments[0];
          if (segment && segment.spriteUrl) {
            this.state.currentSpriteDomain = segment.spriteUrl.replace('sprites.vtt', '');
            api.getRaw(segment.spriteUrl).then((spriteData) => {
              const parsedSpriteData = this.parseSpriteInfoFromVTT(spriteData);
              this.state.retrievedSpriteVTTs[segment.spriteUrl] = parsedSpriteData;

              if (!conf.noPreloadThumbnailPreviews) {
                // TODO: Add logic  to determine how many images to retrieve based
                // on video duration
                console.log('got sprites', parsedSpriteData);
                const imagePreloadCount = Math.floor(
                  parsedSpriteData.length && parsedSpriteData.length / 12
                );
                const thumbDomain = this.state.currentSpriteDomain;
                const imageAssets = Array.from(
                  { length: imagePreloadCount },
                  (v, k) => thumbDomain + k + '.jpg'
                );
                this.preloadImage.preloadUsingQueue(imageAssets, () => {
                  console.log('Preloaded thumbnails');
                });
              }
            });
          }

          this.trigger(this.state);
        }
      })
      .catch((e) => {
        console.warn('Error retrieving playlist.json, try to proceed without', e);
        callback();
      });
  },

  parseSpriteCuttingValues: function (spriteSegment) {
    if (!spriteSegment || !spriteSegment.imgUrl) {
      console.warn('Got empty spriteSegment', spriteSegment);
      return null;
    }
    const spriteXYWH = spriteSegment.imgUrl.split('=')[1];
    const [thumbX, thumbY, thumbWidth, thumbHeight] = spriteXYWH.split(',');
    return {
      url: this.state.currentSpriteDomain + spriteSegment.imgUrl,
      thumbX: parseInt(thumbX),
      thumbY: parseInt(thumbY),
      thumbWidth: parseInt(thumbWidth),
      thumbHeight: parseInt(thumbHeight)
    };
  },

  findInterval: function (segments, currentTime) {
    return _.find(segments, (segment) => {
      return segment.startTime <= currentTime && currentTime <= segment.endTime;
    });
  },

  getCurrentThumbnail: function (currentTime) {
    const currentSegment = this.getCurrentSegment(currentTime);

    if (currentSegment && currentSegment.spriteUrl) {
      if (this.state.retrievedSpriteVTTs[currentSegment.spriteUrl]) {
        let currentSprite = this.findInterval(
          this.state.retrievedSpriteVTTs[currentSegment.spriteUrl],
          Math.floor(currentTime)
        );
        return this.parseSpriteCuttingValues(currentSprite);
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  getCurrentSegment: function (currentTime) {
    return this.state.metadata && this.state.metadata.segments && this.state.metadata.segments[0];

    // let remainingSegments = _.filter(assetMetadataStore.getMetadata().segments, segment => {
    //   return currentTime < (segment.start + segment.duration);
    // });
    // return remainingSegments[0];
  },

  parseSpriteInfoFromVTT(data, settings) {
    var parser = new WebVTT.Parser(window, WebVTT.StringDecoder()),
      cues = [];
    parser.oncue = function (cue) {
      cues.push(cue);
    };
    parser.parse(data);
    parser.flush();
    if (cues) {
      return cues.map((cue) => ({
        startTime: cue.startTime,
        endTime: cue.endTime,
        imgUrl: cue.text
      }));
    }
    return null;
  }
});
