import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import apiImageUrl from '../../utils/api-image-url';
import dateFormatter from '../../utils/date-formatter';
import BaseCard from '../card/base-card';
import CardThumbnail from '../card-thumbnail/card-thumbnail';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';
import { WhitePlayIcon, RedPlayIcon, Icon } from '../../components/page-buttons/page-buttons';
import { localizationStore } from '../../reflux/localizationStore';
import conf from '../../conf';

export default class PlaylistCard extends Reflux.PureComponent.extend(BaseCard) {
  constructor(p) {
    super(p);
    this.updateImage = true;
    this.isServus = conf.appNamespace === 'servustv';
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    this.updateImage =
      this.props.id !== prevProps.id ||
      (!this.props.isPlaceholder && prevProps.isPlaceholder) ||
      !isEqual(this.props.resources, prevProps.resources);
  }

  setCardImage() {
    if (this.updateImage && this.props.resources) {
      this.image = apiImageUrl.getImageByType(
        'schedule-item',
        this.props.resources,
        this.props.id,
        !this.props.playable
      );
      this.updateImage = false;
    }
  }

  getThumbnail() {
    this.setCardImage();
    return <CardThumbnail src={this.image} />;
  }

  getOverlayIcon() {
    if (this.isServus) {
      if (this.props.isWatching) {
        return <RedPlayIcon className="overlay-icon" />;
      }
    } else {
      if (this.props.isWatching) {
        return <WhitePlayIcon className="overlay-icon" />;
      } else if (this.props.recentlyWatched) {
        return <Icon iconName="check" className="overlay-icon" />;
      }
    }
  }

  getTime() {
    if (!this.isServus || !this.props.start_time) {
      return null;
    }

    const time = dateFormatter.getTimestring24Hrs(this.props.start_time);
    return <div className="time">{time}</div>;
  }

  readAria() {
    const readText = `${this.props.title || this.props.headline} : playlist item`;

    ariaTTSServiceStore.readText(readText);
  }

  render() {
    const classNames = cx('playlist-card', {
      'is-placeholder': this.props.isPlaceholder,
      'is-focused': this.props.focused,
      offleft: this.props.offleft
    });

    if (this.props.isPlaceholder) {
      return (
        <div ref={this.props.propRef} className={classNames}>
          <div className="playlist-card__inner">
            <div className="playlist-card__inner__details" />
          </div>
        </div>
      );
    }

    return (
      <div ref={this.props.propRef} className={classNames} role="button">
        <div className="playlist-card__time">{this.getTime()}</div>
        <div className="playlist-card__inner">
          <div className="playlist-card__inner__thumb">
            {this.getThumbnail()}
            {this.getOverlayIcon()}
          </div>
          <div className="playlist-card__inner__details">
            <h3 className="ellipsis">
              {this.props.isFavorite && (
                <Icon className="favicon" iconName="star-black" focused={false} size={32} />
              )}
              {this.props.title || this.props.headline}
            </h3>
            <h4 className="ellipsis">
              {this.isServus && this.props.playable && !this.props.isLive && (
                <Icon
                  iconName={'currently-watching-solid'}
                  className="playlist-card__playable-icon"
                  focused={this.props.focused}
                  active
                  size={15}
                />
              )}
              {this.props.isLive ? (
                <span className="blinker">{localizationStore._GET('live')}</span>
              ) : (
                this.props.subheading
              )}
            </h4>
          </div>
        </div>
      </div>
    );
  }
}
