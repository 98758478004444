var Switcher = {
  setFocus: function (refName) {
    var setfocus = function setfocus() {
      this.setState({
        focused: refName
      });
    }.bind(this);
    return setfocus;
  },

  isFocused: function (refName) {
    return this.props.focused && this.state.focused === refName;
  },

  isActive: function (refName) {
    return this.state.focused === refName;
  }
};

export default Switcher;
