import BasePlatform from './base.js';

export default class Opera extends BasePlatform {
  constructor() {
    super();
    console.log('Instantiated Opera...');
  }

  exit() {
    if (window && window.close) {
      window.top.close();
    }
  }

  getVolume() {
    return document.getElementById('html5Player').volume;
  }

  setVolume(value, restoreVolumeOnExit) {
    document.getElementById('html5Player').volume = value;
  }
}
