import BasePlatform from './base.js';

export default class PS5 extends BasePlatform {
  constructor() {
    super();
    console.info('Instantiated PS5...');
    try {
      window.msdk.device.disableSystemMedia();
      console.log('SystemMedia has been disabled');
      window.msdk.device.disableScreenshots();
      console.log('Screenshots have been disabled.');
    } catch (e) {
      console.log(`Failed to disable screenshots or disableSystemMedia: ${e}`);
    }
  }

  exit() {
    if (window && window.close) {
      console.info('window close');
      window.close();
    }
  }

  setVolume(value) {
    // this.uvpPlayer.volume(value);
    console.info(`setVolume to ${value} ignored`);
  }
}
