import React from 'react';
import classNames from 'classnames';
import BaseButton from '../../components/card/base-button';
import Reflux from 'reflux';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';

export default class MenuItem extends Reflux.Component.extend(BaseButton) {
  componentHasFocus() {
    return this.props.focused && this.props.isActive;
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
  }

  readAria() {
    ariaTTSServiceStore.readText(this.props.name + ': menu item');
  }

  render() {
    var itemClass = classNames(this.props.itemClass, this.props.focused);
    var titleClass = classNames('menu-item__title', {
      'menu-item__title--brighter': this.props.needsBrighterMenu
    });

    return (
      <div className={itemClass}>
        <div className={titleClass}>{this.props.name}</div>
      </div>
    );
  }
}
