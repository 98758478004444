import React, { Suspense } from 'react';
import Reflux from 'reflux';
import { startStore } from '../../reflux/startStore';
import { historyStore } from '../../reflux/historyStore';
import qs from 'query-string';
import _ from 'lodash';
const Intro = React.lazy(() => import('./intro'));

export default class IntroWrapper extends Reflux.Component {
  constructor(props) {
    super(props);
    this.stores = [startStore];
    historyStore.setHistory(props.history);
    this.urlParams = qs.parse(this.props.location.search);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.state, nextState);
  }

  render() {
    if (this.state.data) {
      return (
        <Suspense fallback={<div> </div>}>
          <Intro {...this.props} urlParams={this.urlParams} />
        </Suspense>
      );
    } else {
      return null;
    }
  }
}
