import Reflux from 'reflux';
import ProductServiceDelegate from '../services/productServiceDelegate.js';
import { imagePreload as ImagePreload } from '../services/image-preload.js';
import apiImageUrl from '../utils/api-image-url.js';
import { dialogActions } from './dialogStore';
import { analyticsScreenView } from './appkitAnalyticsStore';
import _ from 'lodash';
import ErrorReporter from '../utils/error-reporter';
import { paginationStore } from './paginationStore';
import { historyStore, historyActions } from './historyStore.js';
import { scheduleActions } from './scheduleStore.js';
import { appStore } from './appStore';
import conf from '../conf';
import PlatformUtils from '../utils/platform';

export const homeActions = Reflux.createActions({
  loadPage: { asyncResult: true },
  loadPageLight: { asyncResult: true },
  setPageLoading: {},
  setDisableFadeOut: {},
  toggleUpHint: {},
  setPage: {},
  setLastPage: {},
  forceLoadConsumption: {},
  clearState: {},
  onShouldMenuCollapse: {},
  showOverlay: {},
  forceHomeFocusOn: {},
  returnToLastSection: {},
  setLinearChannels: {},
  setCurrentPage: {}
});

homeActions.loadPage.listen((id, isReload) => {
  homeStore.state.reloadingPage = isReload;
  return ProductServiceDelegate.getHydratedProductData(id)
    .then((data) => {
      homeStore.onLoadPageCompleted(data);
      return data;
    })
    .catch(homeStore.onLoadPageFailed);
});

homeActions.loadPageLight.listen((id) => {
  return ProductServiceDelegate.getProductData(id);
});

export const homeStore = Reflux.createStore({
  listenables: homeActions,

  state: {
    reloadingPage: false,
    loadPageFailed: false,
    page: null,
    lastPage: null,
    loadingPage: false,
    showUpHint: false,
    currentMenuSection: 'home',
    currentPageSection: 'home',
    collapsed: false,
    isOverlayVisible: false,
    goingToFadeOutHome: true,
    forceHomeFocusOn: false,
    disableFadeOut: false
  },

  getInitialState: function () {
    return this.state;
  },

  init: function () {
    this.preloadImage = new ImagePreload();
  },

  setCurrentPage: function (page) {
    this.state.currentPageSection = page;
    this.trigger(this.state);
  },

  clearState: function () {
    this.state.page = null;
    this.trigger(this.state);
  },

  parseQueryParameters() {
    const query = window.location.href.split('?')[1];
    return (
      query &&
      query.split('&').reduce(function (queryParameters, rawPair) {
        const pair = rawPair.split('=').map(decodeURIComponent);
        queryParameters[pair[0]] = pair[1];
        return queryParameters;
      }, {})
    );
  },

  showOverlay: function (isVisible) {
    this.state.isOverlayVisible = isVisible;
    this.trigger(this.state);
  },

  isDeeplink: function () {
    const queryParameters = this.parseQueryParameters();
    if (queryParameters.IsDeeplink) {
      return true;
    }
    return false;
  },

  forceHomeFocusOn: function (value) {
    this.state.forceHomeFocusOn = value;
    this.trigger(this.state);
  },

  onLoadPageCompleted: function (data) {
    const queryParameters = this.parseQueryParameters();
    if (
      queryParameters &&
      !_.isUndefined(queryParameters.IsDeeplinkLocale) &&
      queryParameters.IsDeeplinkLocale !== 'undefined'
    ) {
      console.warn('Localized deeplink page loaded, deleting deeplink session token');
      appStore.setDeeplinkAuthToken({ token: null });
    }
    homeStore.state.lastViewedPage = data.id;
    dialogActions.hideInvalidContentScreen();
    if (data === 404) {
      if (this.isDeeplink()) {
        return this.onInvalidPageFailed(new Error('Invalid item error'));
      } else {
        return this.onLoadPageFailed(new Error('404 on page data'));
      }
    } else if (data === 500) {
      return this.onLoadPageFailed(new Error('500 on page data'));
    }

    this.state.loadPageFailed = false;
    this.state.serverStatus = 200;

    if (data.collections && data.collections.length) {
      data.collections.forEach((collection) => {
        paginationStore.addFetchedPagesToCollectionItems(collection);
      });
    }

    this.state.page = data;

    this.trigger(this.state);

    const contentType = this.state.page.content_type;
    const isChannel = contentType === 'channel' || contentType === 'subchannel';

    if (isChannel) {
      this.state.page.isLinearChannelPage = _.find(
        this.state.linearChannels && this.state.linearChannels.items,
        { id: data.id }
      );
      const title = this.state.page.title;
      this.trackPage(true, title);
    } else if (!this.state.reloadingPage) {
      this.trackPage();
    }

    let imageAssets = [];
    const cardImages = [];

    const bg = PlatformUtils.isPS3 ? 'pageBgPs3' : 'pageBg';

    if (contentType === 'event') {
      if (_.has(this.state.page, 'id')) {
        const currentPage = this.state.page;
        const backgroundImage = apiImageUrl.getImageByType(
          bg,
          currentPage.resources,
          currentPage.id
        );
        imageAssets.push(backgroundImage);
        if (_.includes(currentPage.resources, 'rbtv_title_treatment_landscape')) {
          const titleImage = apiImageUrl.getImageByType(
            'pageTitle',
            currentPage.resources,
            currentPage.id
          );
          imageAssets.push(titleImage);
        }
      } else {
        console.warn('No current event page found.');
      }
    } else {
      if (_.has(this.state.page, 'id')) {
        const currentPage = this.state.page;
        const backgroundImage = apiImageUrl.getImageByType(
          bg,
          currentPage.resources,
          currentPage.id
        );
        imageAssets.push(backgroundImage);
        if (_.includes(currentPage.resources, 'rbtv_title_treatment_landscape')) {
          const titleImage = apiImageUrl.getImageByType(
            'pageTitle',
            currentPage.resources,
            currentPage.id
          );
          imageAssets.push(titleImage);
        }
      } else {
        console.warn('No current page data found');
      }

      if (_.has(this.state.page, 'collections[0].items')) {
        // Preload first row of images
        _.each(this.state.page.collections[0].items, (item, id) => {
          const cardImage = apiImageUrl.getImageByType(item.type, item.resources, item.id);
          cardImages.push(cardImage);
        });
      }
    }
    imageAssets = imageAssets.concat(cardImages);
    if (!conf.fullPreload && !conf.delayPreload) {
      this.loadPageAssetsCompleted();
    } else {
      this.preloadImage.preloadUsingQueue(imageAssets, this.loadPageAssetsCompleted);
    }
  },

  trackPage: function (isChannel = false, channelTitle = null) {
    let title = 'Page';
    if (this.state.page) {
      title = this.state.page.label || this.state.page.title;
    }
    analyticsScreenView(this.state.page.id, {
      title,
      isChannel,
      channelTitle,
      collectionId: this.state.lastSelectedCollectionId
    });
  },

  loadPageAssetsCompleted: function () {
    this.state.loadingPage = false;
    this.trigger(this.state);
  },

  setPageLoading: function () {
    this.state.loadingPage = true;
    this.trigger(this.state);
  },

  setDisableFadeOut: function (value) {
    this.state.disableFadeOut = value;
    this.trigger(this.state);
  },

  setPage: function (value) {
    this.state.page = value;
    this.trigger(this.state);
  },

  setLastPage: function (value) {
    this.state.lastPage = value;
    this.trigger(this.state);
  },

  onInvalidPageFailed: function () {
    this.state.loadPageFailed = true;
    this.state.loadingPage = false;
    dialogActions.showInvalidContentScreen();
    this.trigger(this.state);
  },

  onLoadPageFailed: function (err) {
    console.log('err=', err);
    ErrorReporter.captureException(err, 'onLoadPageFailed');
    this.state.loadPageFailed = true;
    this.state.serverStatus = err;
    this.state.loadingPage = false;
    dialogActions.showInvalidContentScreen();
    this.trigger(this.state);
  },

  onLoadPageLightFailed: function (err) {
    ErrorReporter.captureException(err, 'onLoadPageLightFailed');
  },

  toggleUpHint: function (shouldShow) {
    this.state.showUpHint = shouldShow;
    this.trigger(this.state);
  },

  forceLoadConsumption: function () {
    this.state.FORCE_LOAD_CONSUMPTION = true;
    this.trigger(this.state);
    this.state.FORCE_LOAD_CONSUMPTION = false;
  },

  onShouldMenuCollapse: function (value) {
    let isCollapsed;

    if (value === -1 && this.state.page !== null) {
      isCollapsed = true;
    } else if (value === 0) {
      isCollapsed = false;
    } else if (value === 1) {
      isCollapsed = true;
    } else {
      isCollapsed = false;
    }
    if (this.state.collapsed !== isCollapsed) {
      this.state.collapsed = isCollapsed;
      this.trigger(this.state);
    }
  },
  returnToLastSection() {
    const lastComponent = historyStore.getLastComponent();
    if (!lastComponent.backState) historyStore.pushHistory('/Home');

    if (
      lastComponent.backState &&
      lastComponent.backState.name !== 'page' &&
      lastComponent.backState.name !== 'viewMore'
    ) {
      historyActions.clearHistory();
    }
    historyStore.pushHistory(
      lastComponent.backPathname,
      lastComponent.backState,
      lastComponent.backParams
    );
    scheduleActions.reset();
  },

  setLinearChannels: function (data) {
    this.state.linearChannels = data === 'undefined' ? { items: [] } : data;
    this.trigger(this.state);
  }
});
