/* eslint import/first: 0, no-redeclare: 0 */
import './utils/polyfill.js'; // import polyfill overrides for missing ES6 JS.

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Reflux from 'reflux';
import { inspect } from 'util';
import { BrowserRouter, Route, matchPath } from 'react-router-dom';
import HomeWrapper from './components/home/home-wrapper';
import IntroWrapper from './components/intro/intro-wrapper';
import ConsumptionWrapper from './components/consumption/consumption-wrapper';
import { s } from './utils/screen-size.js';
import { appStore } from './reflux/appStore';
import { playlistStore } from './reflux/playlistStore';
import { dialogActions } from './reflux/dialogStore';
import { gfkAnalyticsStore } from './reflux/gfkAnalyticsStore';
import { cardUpdaterStore } from './reflux/cardUpdaterStore';
import { startStore, startActions } from './reflux/startStore';
import { exitActions } from './reflux/exitStore.js';
import { configDataStore } from './reflux/configDataStore';
// Needed so that the init fires, we don't import this anywhere else
// eslint-disable-next-line
import { voiceCommandStore } from './reflux/voiceCommandStore';
import Captions from './components/cc/cc';
import PlatformUtils from './utils/platform.js';
import deeplinkService from './services/deeplinkService.js';
import ErrorReporter from './utils/error-reporter';
import { Navigation as Nav } from './utils/reactv-navigation';
import conf from './conf';
import Loader from './components/low-fps-intro/low-fps-intro';
import Switch from './components/switch/switch';
import NVP2Obj from './utils/nvp2Obj.js';

const Dialogs = React.lazy(() => import('./components/dialogs/dialogs'));
const UniversalPlayer = React.lazy(() => import('./components/video-player/universal-player'));
const DebugTerminal = React.lazy(() => import('./components/debug-terminal/debug-terminal'));
import { videoPlayerStore } from './reflux/videoPlayerStore';
import { addWebMAFListener, removeWebMAFListener } from './utils/webmaf.js';
import reload from './utils/reload.js';
import { datadogLogs } from '@datadog/browser-logs';
import { logsStore } from './reflux/logsStore.js';
import keymapper from '../js/utils/keymapper';
import './utils/testingInfoObj.js';
import _ from 'lodash';
import cx from 'classnames';

const isServus = conf.appNamespace === 'servustv';
const EXCLUDE_BACKGROUND_FETCH_COLLUMNS = ['interests', 'recommender'];
const ALLOWED_BACKGROUND_FETCH_SCREENS = [
  'calendar', // home/calendar
  'home' // home
];

/***************************************************************************
 Note this is just imported so it can bind itself to the stores it needs to
 listen to. We don't actually use this anywhere on this page.
 ***************************************************************************/
import { videoAnalyticsStore } from './reflux/videoViewTrackingStore';

PlatformUtils.sharedPlatform.init(function (err) {
  if (err) {
    return console.error(err);
  } else {
    PlatformUtils.sharedPlatform.disableScreenSaver();
    setInterval(() => {
      console.log('ensuring screensaver disabled.');
      PlatformUtils.sharedPlatform.disableScreenSaver();
    }, 5 * 60 * 1000);
    startActions.loadInitialData();
  }
});

videoAnalyticsStore.init();
if (!PlatformUtils.isHTML5) {
  const { errorReporterKey, version, ...remainingConf } = conf;
  ErrorReporter.init(errorReporterKey, version, {
    tags: {
      platform: PlatformUtils.getPlatformID(),
      model: PlatformUtils.getModel(),
      firmware: '[loading]',
      ...remainingConf
    }
  });
}

if (conf.enableDatadog) {
  const argsFixer = function (args) {
    // Check if the object is circular to prevent large size objects
    function isCircular(d) {
      try {
        JSON.stringify(d);
      } catch (e) {
        return true;
      }
      return false;
    }

    if (!isCircular(args)) {
      return args;
    } else {
      return inspect(args);
    }
  };

  datadogLogs.init({
    clientToken: conf.DATADOG_CLIENT_TOKEN,
    site: conf.DATADOG_SITE,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: conf.version,
    service: conf.appNamespace,
    env: conf.testBuild ? 'testbuild' : 'production',
    useCrossSiteSessionCookie: true
  });

  datadogLogs.logger.setLevel('debug');
  datadogLogs.addLoggerGlobalContext('platform', conf.platform);

  const defaultLog = console.log.bind(console);
  const defaultInfo = console.info.bind(console);

  console.log = (msg, ...args) => {
    if (args && args.length) {
      defaultLog(msg, args);
    } else {
      defaultLog(msg);
    }
    datadogLogs.logger.log(msg, argsFixer(args));
  };

  console.info = (msg, ...args) => {
    if (args && args.length) {
      defaultInfo(msg, args);
    } else {
      defaultInfo(msg);
    }
    datadogLogs.logger.info(msg, argsFixer(args));
  };
}

const INACTIVE_REFRESH_TIMEOUT = 3 * 60 * 1000;
const COLLECTION_REQUEST_SPACING = 100;

// NOTE: this is duplicated in the Xbox Index.html
// the 'activated' event comes before this listener gets added,
// so it does not catch an actual app launch with deep link
// BUT it does handle any deep link calls when the app is active or simply suspended.
if (typeof window.Windows !== 'undefined') {
  /* global Windows: true */
  Windows.UI.WebUI.WebUIApplication.addEventListener('activated', function (args) {
    console.log('Deep Link ARGS = ', args);
    if (args && args.detail && args.detail.length > 0 && args.detail[0].uri) {
      // NOTE: this code is duplicated in PS4 Index.html and in src/utils/nvp2Obj.js.
      //       Would be nice to have just 1 copy and share it.
      const query = args.uri.query.split('?')[1];

      const deepLinkData = NVP2Obj(query);

      window.deepLinkData = deepLinkData;
    }
  });
}

class App extends Reflux.Component {
  constructor(props) {
    super(props);
    this.debugSequence = ['7', '8'];
    this.sequence = [];
    this._keymapper = keymapper();
    this.sequenceTimeout = null;

    this.state = {
      ready: false,
      debugTerminal: false
    };
    this.stores = [startStore, appStore, playlistStore, logsStore, gfkAnalyticsStore];
    window.addEventListener('appcontrol', deeplinkService.checkTizenData);
    this.onVisibility = this.onVisibility.bind(this);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.getCurrentView = this.getCurrentView.bind(this);
    this.resetCardRefreshTimeout = this.resetCardRefreshTimeout.bind(this);
    this.startBackgroundFetch = this.startBackgroundFetch.bind(this);
    this.stopBackgroundFetch = this.stopBackgroundFetch.bind(this);
    this.refreshCards = this.refreshCards.bind(this);
    this.fireVoiceCommand = this.fireVoiceCommand.bind(this);
    this.disconnectionHandler = this.disconnectionHandler.bind(this);
    this.connectionHandler = this.connectionHandler.bind(this);
    this.setupNetworkConnectivityListeners = this.setupNetworkConnectivityListeners.bind(this);
    this.removeNetworkConnectivityListeners = this.removeNetworkConnectivityListeners.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.ready !== nextState.ready ||
      this.state.sessionReady !== nextState.sessionReady ||
      this.state.assetsReady !== nextState.assetsReady ||
      this.state.isFirstPlayEventFinished !== nextState.isFirstPlayEventFinished ||
      this.state.debugTerminal !== nextState.debugTerminal
    );
  }

  onVisibility() {
    if (document.hidden) {
      this.stopBackgroundFetch();
    } else {
      this.startBackgroundFetch();
    }
  }

  onBeforeUnload() {
    exitActions.exitApp();
  }

  /**
   * This only test enough to detect 'home' and 'calendar' screens.
   * If you want to detect nested pages, home/:view/:id, clips, shows, stc. or /consumption or /
   * you need to extend this function
   *
   * @returns string | null
   */
  getCurrentView() {
    let match = matchPath(window.location.pathname, '/home/:view');
    if (match) {
      return match.params.view;
    }

    match = matchPath(window.location.pathname, '/home');
    if (match) {
      return 'home';
    }

    return null;
  }

  // Only update when screen is on, e.g on homepage
  async refreshCards() {
    const match = this.getCurrentView();
    if (!ALLOWED_BACKGROUND_FETCH_SCREENS.includes(match)) {
      console.info(`[debug] Skip background refresh for ${window.location.pathname}`);
      return;
    }

    try {
      await cardUpdaterStore.scheduleCollectionUpdates(
        COLLECTION_REQUEST_SPACING,
        null,
        EXCLUDE_BACKGROUND_FETCH_COLLUMNS,
        match
      );
    } catch (error) {
      console.error(error);
    }

    setTimeout(this.resetCardRefreshTimeout, 50);
  }

  resetCardRefreshTimeout() {
    if (PlatformUtils.isMagenta) {
      return;
    }

    clearTimeout(this.userInactivityCardRefreshTimeout);
    const delay =
      parseInt(configDataStore.getConstant('discovery_reset_timeout'), 10) ||
      INACTIVE_REFRESH_TIMEOUT;
    this.userInactivityCardRefreshTimeout = setTimeout(this.refreshCards, delay);
  }

  startBackgroundFetch() {
    console.info('[debug] Started background fetch');
    setTimeout(this.resetCardRefreshTimeout, 50);
  }

  stopBackgroundFetch() {
    console.info('[debug] Stopped background fetch');
    clearTimeout(this.userInactivityCardRefreshTimeout);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.displayDebugTerminal);
    clearTimeout(this.userInactivityCardRefreshTimeout);
    if (!PlatformUtils.isMagenta) {
      document.removeEventListener('visibilitychange', this.onVisibility);
      document.removeEventListener('keydown', this.resetCardRefreshTimeout);
    }

    this.removeNetworkConnectivityListeners();
    if (conf.testBuild) {
      this.voiceCommands.map((subscriber) => subscriber?.unsubscribe());
      this.restartBinding.unsubscribe();
    }
  }

  componentDidMount() {
    if (isServus) {
      const userCookieConsentPreferences =
        JSON.parse(localStorage.getItem('rbtv:cookieConsent')) || [];
      const marketing_cookies = userCookieConsentPreferences.find(
        (item) => item.key === 'marketing_cookies'
      );
      const isCookiesAccepted = marketing_cookies && marketing_cookies.selected;
      const country = appStore.getCountryCode();

      const el = document.createElement('script');
      const trackingSrv = isCookiesAccepted
        ? 'session-cookie.digi-ping.com'
        : 'session.digi-ping.com'; // Red Tech provided values: 'session-cookie.digi-ping.com' : 'session.digi-ping.com'. From docs: 'session.tvping.com' : 'session-cl.tvping.com'
      const channelId = country === 'at' ? '2000' : '2001'; // Red Tech provided value 2000 for AT and 2001 for GE and other countries (Switzerland)
      const resolution = '2'; // Refers to 'HD'
      const delivery = 7; // Red Tech provided value
      const current_time_secs = Math.floor(Date.now() / 1000);
      const contextId = 'Smart TV'; // For management to track users on web vs smart tv
      el.setAttribute(
        'src',
        `https://${trackingSrv}/${channelId}/tracking.js?suspended=true&r=${resolution}&d=${delivery}&t=${current_time_secs}&i=${contextId}`
      );
      document.head.appendChild(el);
    }

    if (!PlatformUtils.isMagenta) {
      document.addEventListener('visibilitychange', this.onVisibility);
      document.addEventListener('keydown', this.resetCardRefreshTimeout);
    }

    this.statusTimeout = setTimeout(() => {
      if (!this.state.isFirstPlayEventFinished) {
        startActions.markFirstPlayEventAsFinished();
        console.warn('No playback after 25 seconds, trying to play WORB');
        playlistStore.playLinearChannel();
      }
    }, 25000);

    this.setupNetworkConnectivityListeners();

    window.addEventListener('beforeunload', this.onBeforeUnload);

    if (conf.testBuild) {
      window.addEventListener('keyup', this.displayDebugTerminal.bind(this));
    }

    if (conf.testBuild) {
      this.voiceCommands = this.fireVoiceCommand([
        // { key: '1', actionId: 'tvMediaControl.Restart' },
        // { key: '2', actionId: 'tvMediaControl.TrackPrevious' },
        // { key: '3', actionId: 'tvMediaControl.TrackNext' }
        // { key: '4', actionId: 'tvMediaControl.SkipBackward', time: 30 },
        // { key: '5', actionId: 'tvMediaControl.SkipForward', time: 30 },
        // { key: 'P', actionId: 'tvMediaControl.SetPlayPosition', time: 300 },
        // { key: 'Q', actionId: 'tvMediaControl.SetSubtitle', toggle: 'on' },
        // { key: 'W', actionId: 'tvMediaControl.SetSubtitle', toggle: 'off' },
        // {
        //   key: 'V',
        //   actionId: 'TVSearchAll.CPSearchDeeplink',
        //   appid: 'InAppSDK_DummyAppID',
        //   caller: 'searchCapsule',
        //   changedAppId: '4OAwqypfRT.RedBullTV',
        //   nlgNeeded: 'false',
        //   originalAppId: 'com.samsung.tv.searchall',
        //   param: '%7B%22search_version%22%3A%221.0.0%22%2C%22search_terms%22%3A%5B%7B%22field%22%3A%22utterance%22%2C%22keyword%22%3A%22Search%20for%20bikes%22%7D%2C%7B%22field%22%3A%22is_play_utterance%22%2C%22keyword%22%3Afalse%7D%2C%7B%22field%22%3A%22title%22%2C%22keyword%22%3A%22bikes%22%7D%5D%2C%22search_samsung_internal_terms%22%3A%5B%7B%22field%22%3A%22conversation_id%22%2C%22keyword%22%3A%22tr-20210610T162249.695Z-e323eb41%22%7D%5D%7D',
        //   requestId: '',
        //   type: 'webapp'
        // },
      ]);
      this.restartBinding = Nav.subscribeToKey('9', reload);
    }
  }

  displayDebugTerminal(e) {
    clearTimeout(this.sequenceTimeout);
    if (this.state.debugTerminal === true && e.keyCode === 53) {
      logsStore.consoleToRender();
    }
    const add = _.debounce((code) => {
      this.sequence.push(this._keymapper[code]);
      const debugSecuence = this.debugSequence;
      if (_.isEqual(this.sequence, debugSecuence)) {
        this.setState({ debugTerminal: !this.state.debugTerminal });
      }

      this.sequenceTimeout = setTimeout(() => {
        this.sequence = [];
      }, 1000);
    }, 50);

    add(e.keyCode);
  }

  fireVoiceCommand(commands) {
    return commands.map(({ key, ...action }) =>
      Nav.subscribeToKey(key, () => voiceCommandStore.onVoiceActionReceive(7, action))
    );
  }

  static getDerivedStateFromError() {
    dialogActions.showError500Screen();
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    ErrorReporter.captureException(error);
  }

  disconnectionHandler() {
    console.info('[debug] Go offline');
    if (!PlatformUtils.isPS5) {
      videoPlayerStore.haltVideo();
    }
    dialogActions.showNoConnectionScreen();
    this.stopBackgroundFetch();
  }

  connectionHandler() {
    console.info('[debug] Go online');
    dialogActions.hideNoConnectionScreen();
    this.startBackgroundFetch();
  }

  setupNetworkConnectivityListeners() {
    if (PlatformUtils.isTizen) {
      let hasRun = false;

      const disconnect = () => {
        if (hasRun) return;
        hasRun = true;

        this.disconnectionHandler();
      };

      this.tizenNetworkInterval = setInterval(() => {
        try {
          const connection = parseInt(window.webapis.network.getActiveConnectionType(), 10);

          // active connection type of zero means disconnected
          if (connection === 0) {
            disconnect();
          }
        } catch (ex) {
          // getActiveConnectionType will throw an exception when the network disconnects
          disconnect();
        }
      }, 1 * 10000);
    } else if (PlatformUtils.isPS4 || PlatformUtils.isPS3) {
      addWebMAFListener((json) => {
        if (json && json.command === 'networkStatusChange') {
          if (json.newState === 'disconnected' || json.newState === 'unknown') {
            this.disconnectionHandler();
          } else {
            this.connectionHandler();
          }
        } else if (json && json.command === 'getExternalParameter' && json.status === 'ok') {
          // handle 'live' deepLinks
          const payload = json;
          if (
            payload.value &&
            typeof payload.value === 'string' &&
            payload.value.indexOf('=') > -1
          ) {
            const deepLinkData = NVP2Obj(payload.value);
            // keep from re-deeplinking upon foregrounding, as the external parameter
            // is not cleared.
            if (_.isEqual(deepLinkData, window.deepLinkData)) return;

            window.deepLinkData = deepLinkData;

            deeplinkService.setDeeplinkContent(deepLinkData);
          }
        } else if (json && json.command === 'applicationStatusChange') {
          if (json.applicationStatus === 'foreground') {
            // check for deepLink params when we become foregrounded.
            window.external.user(JSON.stringify({ command: 'getExternalParameter' }));
          }
        }
      });
    } else if (PlatformUtils.isXbox) {
      const networkInformation = Windows.Networking.Connectivity.NetworkInformation;
      this.onNetworkStatusChanged = (eventArgs) => {
        const internetConnectionProfile = networkInformation.getInternetConnectionProfile();
        const networkConnectivityLevel = internetConnectionProfile.getNetworkConnectivityLevel();
        switch (networkConnectivityLevel) {
          case Windows.Networking.Connectivity.NetworkConnectivityLevel.none:
            this.disconnectionHandler();
            break;
          default:
            this.connectionHandler();
        }
      };
      networkInformation.addEventListener('networkstatuschanged', this.onNetworkStatusChanged);
    } else {
      window.addEventListener('online', this.connectionHandler);
      window.addEventListener('offline', this.disconnectionHandler);
    }
  }

  removeNetworkConnectivityListeners() {
    if (PlatformUtils.isTizen) {
      clearInterval(this.tizenNetworkInterval);
    } else if (PlatformUtils.isPS4 || PlatformUtils.isPS3) {
      removeWebMAFListener();
    } else if (PlatformUtils.isXbox) {
      const networkInformation = Windows.Networking.Connectivity.NetworkInformation;
      networkInformation.removeEventListener('networkstatuschanged');
    } else {
      window.removeEventListener('online', this.connectionHandler);
      window.removeEventListener('offline', this.disconnectionHandler);
    }
  }

  render() {
    const isSunsetFinal =
      PlatformUtils.isPS3 && conf.sunsetTime && new Date(conf.sunsetTime) < new Date();
    const isAppReady =
      this.state.ready &&
      this.state.sessionReady &&
      this.state.data &&
      this.state.assetsReady &&
      this.state.status === 200 &&
      this.state.isFirstPlayEventFinished;
    const isAppErrorOnStartup = this.state.ready && this.state.status !== 200;
    const platformPlayer = PlatformUtils.getPlatformID();
    const platformFPS = PlatformUtils.platformFPS(PlatformUtils.getPlatformID());
    const classNames = cx('main', {
      'is-high-resolution': platformFPS === 'high',
      'is-low-resolution': platformFPS === 'low',
      'is-servus': isServus
    });

    return (
      <React.StrictMode>
        <div id="main" className={classNames}>
          <div>
            {this.state.debugTerminal && (
              <Suspense fallback={<div> </div>}>
                <DebugTerminal />
              </Suspense>
            )}
          </div>
          {!isAppReady && !isAppErrorOnStartup && (
            <Loader visible={true} fpsVersion={PlatformUtils.platformFPS(platformPlayer)} />
          )}
          {!isSunsetFinal && (
            <div id="mainContent">
              <div className="videoItem" style={PlatformUtils.getVideoStyle()}>
                <Suspense fallback={<div> </div>}>
                  <UniversalPlayer
                    focused={true}
                    platform={platformPlayer}
                    width={s(1920)}
                    height={s(1080)}
                    top={0}
                    left={0}
                  ></UniversalPlayer>
                </Suspense>
                <Captions />
              </div>
              <div className="page">
                <BrowserRouter basename={`${location.pathname}`}>
                  <Switch>
                    <Route key="intro" exact path="/" component={IntroWrapper} />
                    <Route key="consumption" path="/Consumption" component={ConsumptionWrapper} />
                    <Route key="home" exact path="/Home/:view" component={HomeWrapper} />
                    <Route key="home" exact path="/Home/:view/:id" component={HomeWrapper} />
                    <Route
                      key="home"
                      exact
                      path="/Home/:view/:id/:setting"
                      component={HomeWrapper}
                    />
                    <Route key="home" component={HomeWrapper} />
                  </Switch>
                </BrowserRouter>
              </div>
            </div>
          )}
          <Suspense fallback={<div></div>}>
            <Dialogs />
          </Suspense>
        </div>
      </React.StrictMode>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('mainApp'));
