import BasePlatform from './base.js';
import ErrorReporter from '../../error-reporter';
import { codecSupport } from '../../../utils/codec-support.js';
import { getDefaultContext } from '../../analytics/builders.js';

const USER_AGENT = navigator.userAgent;

export default class Magenta extends BasePlatform {
  constructor() {
    super();
    this.userAgentData = USER_AGENT.split(' ')[3]?.split('/');
    console.log('Instantiated Magenta...');
    const codecs = codecSupport();
    const frameRate = '30'; // while we can not query from the device

    try {
      this.AVAttributes = {
        category: 'smart_tv',
        os_family: 'magenta',
        model: this.model() || null,
        os_version: this.firmware() || null,
        format: this.resolution() + frameRate,
        quality: this.supportsHDR() || null,
        locale: this.deviceLanguage || 'de',
        codec: codecs.toString() || null
      };
      console.log('Magenta AV Attributes: ', this.AVAttributes);
    } catch (error) {
      console.log('Error getting platform params');
      ErrorReporter.captureException(error);
    }
  }

  resolution() {
    try {
      const screenWidth = getDefaultContext().screen.width;
      let resolution = '720';
      if (screenWidth <= 640) {
        resolution = '480p';
      } else if (screenWidth <= 1280) {
        resolution = '720p';
      } else if (screenWidth <= 1920) {
        resolution = '1080p';
      } else if (screenWidth >= 3840) {
        resolution = '2160p';
      }
      return resolution;
    } catch (e) {
      ErrorReporter.captureException(e);
    }
  }

  model() {
    try {
      return `${this.userAgentData[0]}`;
    } catch (e) {
      return 'unspecified';
    }
  }

  firmware() {
    try {
      return `${this.userAgentData[1]}`;
    } catch (e) {
      return 'unspecified';
    }
  }

  exit() {
    if (window && window.close) {
      window.top.close();
    }
  }

  getDeviceAudioVideoAttributes() {
    return this.AVAttributes;
  }
}
