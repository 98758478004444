import API from '../services/api.js';

export default class EPGServiceDelegate {
  static loadEPG(id) {
    return API.getEPG(id).then(EPGServiceDelegate.onSuccess).catch(EPGServiceDelegate.onError);
  }

  static onSuccess(result) {
    return result;
  }

  static onError(err) {
    return new Promise(function (resolve, reject) {
      let error = { errorCode: err };
      reject(error);
    });
  }
}
