import React from 'react';
import isEqual from 'lodash/isEqual';
import { localizationStore } from '../../reflux/localizationStore';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';
import dateFormatter from '../../utils/date-formatter';
import normalizeLabel from '../../utils/normalize-label';
import BaseAria from '../aria-tts/base-aria';

export default class ScheduleLabel extends BaseAria {
  constructor(p) {
    super(p);

    this.read = false;
    this.clear = this.clear.bind(this);
    this.handleProps = this.handleProps.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.handleProps();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    this.read = this.read && prevProps.focused && !this.props.focused ? false : this.read;

    if (prevProps.id !== this.props.id) {
      this.handleProps();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.clear();
  }

  clear() {
    clearTimeout(this.timeout);
  }

  handleProps() {
    this.clear();

    if (this.props.isLinear) {
      return;
    }

    if (!this.props.status) {
      return;
    }

    const { type, eventStatus, label, retry } = normalizeLabel(this.props);

    if (retry !== undefined) {
      this.timeout = setTimeout(() => {
        this.handleProps();
      }, retry);
    }

    const state = {
      label,
      type,
      eventStatus
    };

    switch (eventStatus) {
      case 'upcoming':
      case 'unconfirmed':
        state.label = `${localizationStore._GET('upcoming')} - ${localizationStore._GET(
          'time_tba'
        )}`;
        break;
      case 'pre':
        const { start_time, end_time } = this.props.status;
        if (start_time && end_time) {
          const now = new Date();
          const startTime = new Date(start_time);
          const endTime = new Date(end_time);
          const atTime = dateFormatter.getLocalTime(start_time);
          const message = localizationStore._GET(
            'live_on_date_at_time',
            dateFormatter.getDateString(start_time),
            atTime
          );
          const upcomingMessage = `${localizationStore._GET('upcoming')} - ${message}`;

          // has started
          if (startTime <= now) {
            // has ended
            if (endTime <= now) {
              // Live at %start_ime%
              state.label = message;
            } else {
              // Upcoming - Live on %1$s at %2$s
              state.label = upcomingMessage;
            }
            // 48 before start time
          } else if (dateFormatter.isInLessThan48Hours(startTime)) {
            if (dateFormatter.isToday(startTime)) {
              // Live today at %1$s
              state.label = localizationStore._GET('live_today_at_time', atTime);
            } else if (dateFormatter.isTomorrow(startTime)) {
              // Live tomorrow at %1$s
              state.label = localizationStore._GET('live_tomorrow_at_time', atTime);
            } else {
              // Supposedly never gets to this point
              // Upcoming - Live on %1$s at %2$s
              state.label = upcomingMessage;
            }
            // more than 48 before start time
          } else {
            // Upcoming - Live on %1$s at %2$s
            state.label = upcomingMessage;
          }
        }
        break;
      case 'post':
      case 'unavailable':
        const msg = this.props.status.label || this.props.status.message;
        state.label = localizationStore._GET_LABEL(msg);
        break;
      default:
        break;
    }

    if (!isEqual(state, this.state)) {
      this.setState(state);
    }
  }

  readAria() {
    const { label } = this.state;
    if (!label || this.read) return;
    this.read = true;

    ariaTTSServiceStore.readText(this.props.readAria ? `${label} : ${this.props.readAria}` : label);
  }

  render() {
    const { label, type, eventStatus } = this.state;
    if (!label) {
      return null;
    }

    if (this.props.allowed && !this.props.allowed.includes(eventStatus || type)) {
      return null;
    }

    return (
      <div className="card-panel">
        <span className={`card-label card-label--${eventStatus || type} ellipsis`}>{label}</span>
      </div>
    );
  }
}
