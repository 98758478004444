/* global Windows: true */
import BasePlatform from './base.js';

export default class Xbox extends BasePlatform {
  constructor() {
    super();
    console.info('Instantiated Xbox...');
  }

  exit() {
    if (window && window.close) {
      console.info('window close');
      setTimeout(() => {
        window.close();
      }, 100);
    }
  }

  getVolume() {
    return document.getElementById('html5Player').volume;
  }

  setVolume(value, restoreVolumeOnExit) {
    document.getElementById('html5Player').volume = value;
  }

  areCaptionsEnabled() {
    const captionsEnabled = Windows.Xbox.System.ClosedCaptions.ClosedCaptionProperties.isEnabled;
    return captionsEnabled === true;
  }
}
