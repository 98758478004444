import React from 'react';
import Reflux from 'reflux';
import _ from 'lodash';
import classnames from 'classnames';
import GridPanel from '../../components/grid-page/grid-panel';
import { gridPageStore, gridPageActions } from '../../reflux/gridPageStore';
import { homeStore } from '../../reflux/homeStore';
import { paginationStore } from '../../reflux/paginationStore';
import cardLookup from '../../config/cardLookup';
import { userAccountActions } from '../../reflux/userAccountStore';

/**
 * findLastRowIndex finds the index of the last item in a particular column
 * of a grid
 * @param columnToGoTo - column we are finding the index of.
 * @param length - length of the list
 * @param columns - number of columns
 * @returns {number} - index of item
 */
const findLastRowIndex = function (columnToGoTo, length, columns) {
  var desiredIndex = Math.floor(length / columns) * columns + columnToGoTo;
  if (desiredIndex < length) {
    return desiredIndex;
  } else if (length % columns === 0 && desiredIndex - columns >= 0) {
    return desiredIndex - columns;
  } else {
    return length - 1;
  }
};

/**
 * GridPage component, is a collection of GridPanels
 */

export default class GridPage extends Reflux.PureComponent {
  constructor(p) {
    super(p);

    this.stores = [paginationStore];
    this.state = Object.assign(
      gridPageStore.state,
      this.state || {
        currentGrid: 0
      }
    );

    this.panelRefs = [];

    this.gridPageOnUp = this.gridPageOnUp.bind(this);
    this.gridPageOnDown = this.gridPageOnDown.bind(this);
    this.saveCurrentGridCoordinates = this.saveCurrentGridCoordinates.bind(this);
    this.gridPageOnEnter = this.gridPageOnEnter.bind(this);
    this.unsub = gridPageActions.reset.listen(() => {
      this.setState(gridPageStore.state);
    });
  }

  componentDidMount() {
    homeStore.showOverlay(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show && !prevProps.show) {
      this.setState({ loading: true });
      var self = this;
      setTimeout(() => {
        self.setState({ loading: false });
      }, 1);
    }
    if (prevProps.collections !== this.props.collections || this.state.forceList) {
      this.panelRefs = [];
      this.props.collections.forEach((coll, index) => {
        this.panelRefs[index] = React.createRef();
      });
      this.setState({ forceList: false });
    }
    if (!prevProps.resetList && this.props.resetList) {
      this.setState({ currentGrid: 0 });
    } else if (prevProps.backParams.savedIndex !== this.props.backParams.savedIndex) {
      const currentGrid = parseInt(this.props.backParams.savedIndex, 10);
      if (!isNaN(currentGrid)) {
        this.setState({ currentGrid });
      }
    } else if (prevProps.show && this.lastRenderedViewId !== prevProps.id) {
      this.setState({ currentGrid: 0 });
    }
    this.setState({ viewId: this.props.id });
    gridPageActions.updateGridState(this.state);
  }

  changeFocus(amt) {
    if (amt === 0 || typeof amt !== 'number' || isNaN(amt)) return;
    let cg = this.state.currentGrid + amt;
    if (!isNaN(cg) && cg >= 0 && cg <= this.props.collections.length - 1) {
      if (amt === 1) {
        this.panelRefs[cg].current.setMenuInitialFocusIndex(this.state.coordinates.x);
      } else {
        let index = findLastRowIndex(
          this.state.coordinates.x,
          this.props.collections[cg].items.length,
          this.props.numberOfColumns
        );
        this.panelRefs[cg].current.setMenuInitialFocusIndex(index);
      }
      this.setState({
        currentGrid: cg
      });
    }
  }

  gridPageOnDown() {
    this.changeFocus(1);
    this.props.onShouldMenuCollapse(this.state.currentGrid);
  }

  gridPageOnUp() {
    this.changeFocus(-1);
    this.props.onShouldMenuCollapse(this.state.currentGrid);
  }

  saveCurrentGridCoordinates(x, y) {
    if (_.isEqual(this.state.coordinates, { x, y })) return;
    this.setState({ coordinates: { x, y } });
  }

  componentWillUnmount() {
    homeStore.showOverlay(false);
    this.unsub();
  }

  getDataForCards(collection) {
    // Edge case.
    if (!collection || !collection.items || !collection.items.length) return [];
    return collection.items.map((item, index) => {
      let component;

      if (collection.homeRailId === 'continue_watching') {
        component = cardLookup('universal');
      } else if (collection.homeRailId === 'hero_channels') {
        component = cardLookup('linear-channel');
      } else if (collection.homeRailId === 'hero_cards') {
        component = cardLookup('page');
      } else if (collection.homeRailId === 'favorites') {
        component = cardLookup('universal', item.type);
      } else if (collection.type === 'generic' && collection.item_type === 'mixed') {
        if (item?.type === 'page') {
          component = cardLookup('page');
        } else {
          component = cardLookup('mixed');
        }
      } else if (collection.type && collection.type !== 'generic') {
        component = cardLookup(collection.type);
      } else {
        component = cardLookup(
          collection?.item_type || item?.type,
          item?.content_type || item?.type
        );
      }

      if (!component) {
        return null;
      }

      return _.extend(item, { component });
    });
  }

  gridPageOnEnter(item, itemIndex, collection, collectionIndex) {
    collection.selectedId = item.id;
    item.positionIndex = itemIndex;
    collection.positionIndex = collectionIndex;
    if (this.props.onEnter) {
      this.props.onEnter(item, collection);
    }
  }

  getGridPanels() {
    var last = this.props.collections.length - 1;
    let { x, y } = this.state.coordinates;
    let currFocusIdx = y * this.props.numberOfColumns + x;
    return this.props.collections.map((collection, index) => {
      let isFocusedMenu = index === this.state.currentGrid;
      let initialFocusIndex = isFocusedMenu ? currFocusIdx : undefined;
      var menuProps = {
        onLeft: this.props.onLeft,
        onRight: this.props.onRight,
        onDown: this.props.onDown,
        onBottomRow:
          this.state.currentGrid === this.props.collections.length - 1
            ? this.props.onBottomRow
            : null,
        setCoordinates: this.saveCurrentGridCoordinates,
        backParams: this.props.backParams,
        focused: isFocusedMenu && this.props.focused,
        initialFocusIndex: initialFocusIndex,
        panelIndex: index,
        iTrackingRow: this.props.iTrackingRow + index
      };

      if (index > 0) {
        menuProps.onUp = this.gridPageOnUp;
      } else {
        menuProps.onUp = this.props.onUp;
      }

      if (index < last) menuProps.onDown = this.gridPageOnDown;
      let menuItems = this.getDataForCards(collection);

      var that = this;
      const gridPanelOnEnter = function (item, itemIndex) {
        that.gridPageOnEnter(item, itemIndex, collection, index);
      };
      return (
        <GridPanel
          resetList={this.props.resetList}
          collection={collection}
          menuItems={menuItems}
          hidden={index < this.state.currentGrid}
          ref={this.panelRefs[index]}
          {...menuProps}
          key={collection?.id || `collection-${index}`}
          numberOfColumns={this.props.numberOfColumns}
          onBack={() => this.props.setIndexViewMore(menuProps.initialFocusIndex)}
          onEnter={gridPanelOnEnter}
          onLongPress={userAccountActions.toggleIsFavorite}
          shouldScroll={this.props.id}
        />
      );
    });
  }

  render() {
    if (!this.props.show) return null;
    this.lastRenderedViewId = this.props.id;
    let cx = classnames('grid-page', {
      'grid-page--viewAll': this.props.id === 'view-more'
    });
    return <div className={cx}>{this.getGridPanels()}</div>;
  }
}
