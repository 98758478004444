import Reflux from 'reflux';
import _ from 'lodash';
import { playlistActions } from './playlistStore';
import { startActions } from './startStore';
import conf from '../conf';
import api from '../services/api.js';

export const paginationStore = Reflux.createStore({
  state: {
    /* we need to keep track of the last reset time so we can ignore
     * outstanding requests that started before the reset, but return
     * after it. */
    lastResetTimestamp: +new Date(),
    collectionPaginationRequests: {},
    playlistPaginationRequests: {},
    collectionPagesCounter: 0,
    playlistPagesCounter: 0
  },

  storePageByCollectionId: function (collectionId, page, pageIndex) {
    if (!_.has(this.state.collectionPaginationRequests, collectionId)) {
      this.state.collectionPaginationRequests[collectionId] = {};
    }
    this.state.collectionPaginationRequests[collectionId][pageIndex] = page;
    if (page) {
      this.state.collectionPagesCounter++;
    }
  },

  storePageByPlaylistId: function (playlistId, page, pageIndex) {
    if (!_.has(this.state.playlistPaginationRequests, playlistId)) {
      this.state.playlistPaginationRequests[playlistId] = {};
    }
    this.state.playlistPaginationRequests[playlistId][pageIndex] = page;
    if (page) {
      this.state.playlistPagesCounter++;
    }
  },

  addFetchedPagesToCollectionItems: function (collection) {
    let pages = this.getAllPagesByCollectionId(collection.id);
    if (pages) {
      for (let i = 2; pages[i]; i++) {
        let page = pages[i];
        if (page === true) break;
        let start = page.meta.offset;
        page.items.forEach((item, idx) => {
          collection.items[idx + start] = item;
        });
      }
    }
    startActions.preloadVideosByCollection(collection);
  },

  removePageByCollectionId: function (id, pageNumber) {
    if (
      this.state.collectionPaginationRequests &&
      this.state.collectionPaginationRequests.length &&
      this.state.collectionPaginationRequests[id]
    ) {
      delete this.state.collectionPaginationRequests[id][pageNumber];
    }
  },

  removePageByPlaylistId: function (id, pageNumber) {
    if (
      this.state.playlistPaginationRequests &&
      this.state.playlistPaginationRequests.length &&
      this.state.playlistPaginationRequests[id]
    ) {
      delete this.state.playlistPaginationRequests[id][pageNumber];
    }
  },

  getPageByCollectionId: function (collectionId, pageIndex) {
    let requests = this.state.collectionPaginationRequests[collectionId];
    let page = requests ? requests[pageIndex] : 0;
    return page;
  },

  getPageByPlaylistId: function (playlistId, pageIndex) {
    let requests = this.state.playlistPaginationRequests[playlistId];
    let page = requests ? requests[pageIndex] : 0;
    return page;
  },

  getCollectionWithAllRemainingItems: function (collectionId) {
    try {
      let collectionItems = [];
      const firstPage = this.state.collectionPaginationRequests[collectionId]['1'];
      Object.keys(this.state.collectionPaginationRequests[collectionId]).forEach((i) => {
        const page = this.state.collectionPaginationRequests[collectionId][i];
        const pageItems = page.items.slice(0, page.meta.limit);
        collectionItems = collectionItems.concat(pageItems);
      });
      const collection = {
        ...firstPage,
        items: collectionItems,
        meta: {
          total: firstPage.meta.total,
          offset: collectionItems.length,
          limit: firstPage.meta.limit || 20 // Default size of a page
        }
      };
      return _.cloneDeep(collection);
    } catch (e) {
      console.error('Error - getCollectionWithAllRemainingItems', e);
    }
  },

  getAllPagesByCollectionId: function (collectionId) {
    return this.state.collectionPaginationRequests[collectionId];
  },

  removeAllPagesByCollectionId: function (collectionId) {
    delete this.state.collectionPaginationRequests[collectionId];
  },

  removeAllPagesByPlaylistId: function (playlistId) {
    delete this.state.playlistPaginationRequests[playlistId];
  },

  loadCollectionPage: function (id, pageSize, pageNumber, collection) {
    if (!id || !pageNumber) {
      return;
    }
    let collectionType = id.split(':')[0];
    if (collectionType === 'search') {
      this._loadNextPageForSearch(id, pageSize, collection, pageNumber, (newPage) => {
        this.storePageByCollectionId(id, newPage, pageNumber);
        this.addFetchedPagesToCollectionItems(collection);
        this.trigger(this.state);
      });
    } else {
      this._loadNextPageForCollection(id, pageSize, collection, pageNumber, (newPage) => {
        this.storePageByCollectionId(id, newPage, pageNumber);
        this.addFetchedPagesToCollectionItems(collection);
        this.trigger(this.state);
      });
    }
  },

  loadPlaylistPage: function (playlistId, pageNumber, pageSize) {
    this._loadPageForPlaylist(playlistId, pageNumber, pageSize, (newPage) => {
      this.storePageByPlaylistId(playlistId, newPage, pageNumber);
      // FIXME: this could potentially splice into the wrong playlist
      //       if the playlist has changed during the request.
      playlistActions.splicePage(pageNumber, newPage.items, pageSize);
    });
  },

  _loadNextPageForSearch: function (
    id,
    pageSize,
    collection,
    pageNumber,
    successCallback,
    isRetry
  ) {
    let startTime = +new Date();
    let parts = id.split(':');
    let searchTerm = parts[1];
    let key = parts[2];
    this.storePageByCollectionId(id, true, pageNumber); // Prevent multiple API calls firing
    api
      .search(searchTerm, pageNumber, pageSize, key)
      .then(
        (result) => {
          if (startTime < this.state.lastResetTimestamp) {
            console.info('paginationStore was reset while api call was running');
            this.removePageByCollectionId(id, pageNumber);
            if (!isRetry) {
              console.info('retrying...');
              this._loadNextPageForSearch(
                id,
                pageSize,
                collection,
                pageNumber,
                successCallback,
                true
              );
            }
            return;
          }
          return successCallback(result.collections.pop());
        },
        (error) => {
          console.info('[COLLECTION] Error', error);
          this.removePageByCollectionId(id, pageNumber);
        }
      )
      .catch((e) => console.error(e));
  },

  _loadNextPageForCollection: function (
    id,
    pageSize,
    collection,
    pageNumber,
    successCallback,
    isRetry
  ) {
    let startTime = +new Date();
    this.storePageByCollectionId(id, true, pageNumber); // Prevent multiple API calls firing
    api
      .getCollection(collection, pageNumber, pageSize)
      .then(
        (result) => {
          if (conf.disableOldEvents) {
            const liveDashEventsDate = new Date(new Date() - 7776000000);
            _.forEach(result.items, (item) => {
              const isPastLive = !!(
                item.content_type === 'live_program' && item.status?.code === 'post'
              );

              if (isPastLive) {
                const isOldEvent = liveDashEventsDate > new Date(item.status.end_time);
                if (isOldEvent) {
                  item.playable = false;
                  item.isOldEvent = true;
                }
              }
            });
          }
          if (startTime < this.state.lastResetTimestamp) {
            console.info('paginationStore was reset while api call was running');
            this.removePageByCollectionId(id, pageNumber);
            if (!isRetry) {
              console.info('retrying...');
              this._loadNextPageForCollection(
                id,
                pageSize,
                collection,
                pageNumber,
                successCallback,
                true
              );
            }
            return;
          }
          return successCallback(result);
        },
        (error) => {
          console.info('[COLLECTION] Error', error);
          this.removePageByCollectionId(id, pageNumber);
        }
      )
      .catch((e) => console.error(e));
  },

  _loadPageForPlaylist: function (playlistId, pageNumber, pageSize, successCallback, isRetry) {
    let startTime = +new Date();
    this.storePageByPlaylistId(playlistId, true, pageNumber); // Prevent multiple API calls firing
    api
      .getPlaylist(playlistId, pageNumber, pageSize)
      .then(
        (result) => {
          if (conf.disableOldEvents) {
            const liveDashEventsDate = new Date(new Date() - 7776000000);
            _.forEach(result.items, (item) => {
              const isPastLive = !!(
                item.content_type === 'live_program' && item.status?.code === 'post'
              );

              if (isPastLive) {
                const isOldEvent = liveDashEventsDate > new Date(item.status.end_time);
                if (isOldEvent) {
                  item.playable = false;
                  item.isOldEvent = true;
                }
              }
            });
          }
          if (startTime < this.state.lastResetTimestamp) {
            console.info('paginationStore was reset while api call was running');
            this.removePageByPlaylistId(playlistId, pageNumber);
            if (!isRetry) {
              console.info('retrying...');
              this._loadNextPageForPlaylist(
                playlistId,
                pageNumber,
                pageSize,
                successCallback,
                true
              );
            }
            return;
          }
          return successCallback(result);
        },
        (error) => {
          console.info('[PLAYLIST] Error', error);
          this.removePageByPlaylistId(playlistId, pageNumber);
        }
      )
      .catch((e) => console.error(e));
  },

  loadRemainingCollectionPages: function (initialCollectionPage, callback) {
    let limit = initialCollectionPage.meta.limit;
    let total = initialCollectionPage.meta.total;
    let apiCallCount = 0;
    function checkForFollowingPage(newPage) {
      apiCallCount = apiCallCount + 1;
      if (apiCallCount > 10) {
        // Failsafe in case the API returns bad offsets and keeps us in a call loop
        if (callback) {
          callback();
        }
        return;
      }
      let pageNumber = newPage.meta.offset / limit + 1;
      paginationStore.storePageByCollectionId(initialCollectionPage.id, newPage, pageNumber);
      paginationStore.addFetchedPagesToCollectionItems(initialCollectionPage);
      if (newPage.meta.offset + limit < total) {
        paginationStore._loadNextPageForCollection(
          initialCollectionPage.id,
          limit,
          initialCollectionPage,
          pageNumber + 1,
          checkForFollowingPage
        );
      } else if (callback) {
        callback();
      }
    }

    paginationStore._loadNextPageForCollection(
      initialCollectionPage.id,
      limit,
      initialCollectionPage,
      2,
      checkForFollowingPage
    );
  }
});
