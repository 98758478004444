import { startActions, startStore } from './startStore';
import { userAccountActions, USER_RAILS } from './userAccountStore';
import CollectionServiceDelegate from '../services/collectionServiceDelegate';
import PlatformUtils from '../utils/platform';
import { paginationStore } from './paginationStore';
import Reflux from 'reflux';
import _ from 'lodash';

let defaultState = {
  startData: null,
  cardsToTrack: {},
  collectionsToTrack: {},
  updates: {}
};

let liveStatusCode = ['event_window', 'live', 'pre', 'unconfirmed'];
let liveContentType = ['live_event', 'live_program'];

export const cardUpdaterStore = Reflux.createStore({
  init: function () {
    console.info('card updater store: init');
    this.state = _.cloneDeep(defaultState);
    this.listenTo(startActions.finishedLoadingProducts, this.parseSystemViews);
    this.listenTo(userAccountActions.login, this.scheduleUserCollectionsUpdate);
    this.listenTo(userAccountActions.favoritesListUpdated, this.scheduleFavoriteUpdate);
  },

  clearUpdates() {
    let updates = this.state.updates;
    if (!updates) return;
    Object.keys(updates).forEach((key) => clearTimeout(this.state.updates[key]));
  },

  reset() {
    console.log(' resetting cardUpdaterStore ');
    this.clearUpdates();
    this.state = _.cloneDeep(defaultState);
    this.state.lastResetTimestamp = +new Date();
  },

  parseSystemViews: function () {
    console.info('card updater store: parse system views');
    let data = startStore.state.data;
    let { system_views } = data;
    this.reset();
    system_views.forEach((view) => {
      if (_.has(view, 'collections')) {
        let trackableCollections = view.collections.filter((coll) => {
          // skip channels
          if (coll.homeRailId === 'hero_channels') {
            return false;
          }

          // track even has no items
          if (_.has(coll, 'request_data')) {
            return true;
          }

          return !!(coll.items || []).find((item) => {
            return (
              item.isLive ||
              _.includes(liveContentType, item.content_type) ||
              _.includes(liveStatusCode, item.status?.code)
            );
          });
        });

        trackableCollections.forEach((collection) => {
          this.state.collectionsToTrack[collection.id] = {
            id: collection.id,
            offset: 0,
            view: view.id
          };

          if (collection.homeRailAction) {
            this.state.collectionsToTrack[collection.id].homeRailAction = collection.homeRailAction;
          }

          if (collection.homeRailId) {
            this.state.collectionsToTrack[collection.id].homeRailId = collection.homeRailId;
          }

          if (collection.request_data) {
            this.state.collectionsToTrack[collection.id].request_data = collection.request_data;
          }
        });
      }
    });
  },

  updateStartData: function (id, updatedCollection, pageNumber) {
    let dirty = false;
    let startData = startStore.state.data;

    // For Servus: makes sure that row isn't shown
    // For Red Bull: makes sure that only default linear channel is presented in the row
    if (
      (id === 'linear-channels' ||
        updatedCollection?.homeRailId === 'hero_channels' ||
        updatedCollection?.homeRailId === 'hero_cards') &&
      updatedCollection?.items
    ) {
      if (PlatformUtils.isMultiLinearDisabled()) {
        updatedCollection.items = updatedCollection.items.filter(
          (channel) => channel.id === 'linear-borb'
        );
      }
    }

    if (_.get(startData, 'system_views.length', 0)) {
      startData.system_views.forEach((sysview) => {
        if (_.has(sysview, 'collections')) {
          sysview.collections.forEach((collection) => {
            let collectionId = _.isObject(collection) ? collection.id : collection;
            if (collectionId === id) {
              dirty = true;
              collection.content_type = updatedCollection.content_type;
              collection.homeRailAction = updatedCollection.homeRailAction;
              collection.item_type = updatedCollection.item_type;
              collection.meta = updatedCollection.meta;
              collection.offset = updatedCollection.offset;
              collection.publish_state = updatedCollection.publish_state;
              collection.type = updatedCollection.type;
              collection.view = updatedCollection.view;
              collection.whitelist_countries = updatedCollection.whitelist_countries;
              collection.items = [].concat(
                collection.items.slice(0, updatedCollection.meta?.offset || 0),
                updatedCollection.items,
                collection.items.slice(
                  (updatedCollection.meta?.offset || 0) + (updatedCollection.meta?.limit || 20),
                  collection.items.length
                )
              );
            }
          });
        }
      });

      if (dirty) startStore.forceDataUpdate(startData);
    }
  },

  scheduleCollectionUpdates: function (spacing = 0, include = null, exclude = null, view = null) {
    // console.log(`%c [debug] scheduleCollectionUpdates`, 'color:deeppink', spacing, include, exclude);
    if (!startStore.state.ready) {
      console.info(
        '[debug] Skipped a scheduled collection update due to the app not being ready yet.'
      );
      return Promise.resolve();
    }

    if (PlatformUtils.isMagenta) {
      return Promise.resolve();
    }

    CollectionServiceDelegate.cancelCollectionRequests();

    const updateCollectionStatus = (coll, timeStamp) => {
      /* if we have been reset since this update loop started,
       * we need to stop this update loop. */
      if (timeStamp < this.state.lastResetTimestamp) {
        // do nothing, do not reschedule.
        console.log('ignoring stale collection update. (1)', coll.id);
        return Promise.resolve();
      }

      let requestedPages = paginationStore.getAllPagesByCollectionId(coll.id);
      if (!requestedPages) {
        requestedPages = {};
      }

      const promises = [];
      _.forEach(requestedPages, (page, index) => {
        let pageNumber = parseInt(index, 10);

        if (page === true) {
          return;
        }

        promises.push(
          CollectionServiceDelegate.getCancellableCollectionData(
            coll,
            pageNumber,
            page.meta.limit,
            spacing
          )
            .then((collection) => {
              /* if we have been reset since this update loop
               * started, we need to stop this update loop. */
              if (timeStamp < this.state.lastResetTimestamp) {
                // do nothing, do not reschedule.
                console.log('ignoring stale collection update. (2)');
                return Promise.resolve();
              }

              if (_.has(collection, 'id')) {
                this.updateStartData(coll.id, collection, pageNumber);
              }

              return Promise.resolve();
            })
            .catch((err) => {
              if (err !== 'cancelled') {
                this.updateStartData(coll.id, { items: [], meta: {} }, pageNumber);
              }

              return Promise.resolve();
            })
        );
      });

      return Promise.all(promises);
    };

    const updateAllPages = () => {
      const promises = [];
      let columns = { ...this.state.collectionsToTrack };
      if (!_.isEmpty(include)) {
        columns = _.pickBy(columns, (col) => _.includes(include, col.homeRailId || col.id));
      }

      if (!_.isEmpty(exclude)) {
        columns = _.pickBy(columns, (col) => !_.includes(exclude, col.homeRailId || col.id));
      }

      if (!_.isEmpty(view)) {
        columns = _.pickBy(columns, (col) => col.view === view);
      }

      _.each(columns, (col) => {
        promises.push(updateCollectionStatus(col, +new Date()));
      });

      return Promise.all(promises);
    };

    return updateAllPages();
  },

  scheduleUserCollectionsUpdate: function () {
    return this.scheduleCollectionUpdates(100, USER_RAILS, null, 'home');
  },

  scheduleFavoriteUpdate: function () {
    return this.scheduleCollectionUpdates(100, ['favorites'], null, 'home');
  }
});
