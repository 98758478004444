import React from 'react';
import Reflux from 'reflux';

export default class BaseAria extends Reflux.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focused: props.focused
    };
    this.wasFocused = false;
    this.readTimeout = 0;
  }

  componentDidMount() {
    this.checkReadAria();
  }

  componentDidUpdate() {
    this.checkReadAria();
  }

  checkReadAria() {
    if (this.props.focused && this.props.focused !== this.wasFocused) {
      if (this.readAria) {
        this.readTimeout = setTimeout(() => {
          if (this.props.focused) this.readAria();
        }, 25);
      }
    } else if (!this.props.focused && this.readTimeout) {
      clearTimeout(this.readTimeout);
      this.readTimeout = 0;
    }
    this.wasFocused = this.props.focused;
  }

  render() {
    return <div></div>;
  }
}
