import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import apiImageUrl from '../../utils/api-image-url';
import BaseCard from '../card/base-card';
import CardLabel from '../card-label/card-label';
import CardThumbnail from '../card-thumbnail/card-thumbnail';

export default class LiveCard extends Reflux.Component.extend(BaseCard) {
  constructor(p) {
    super(p);

    this.updateImage = true;
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    this.updateImage =
      this.props.id !== prevProps.id ||
      (!this.props.isPlaceholder && prevProps.isPlaceholder) ||
      !isEqual(this.props.resources, prevProps.resources);
  }

  setCardImage() {
    if (this.updateImage) {
      this.image = apiImageUrl.getImageByType('live', this.props.resources, this.props.id);
      this.updateImage = false;
    }
  }

  getThumbnail() {
    this.setCardImage();
    return <CardThumbnail src={this.image} />;
  }

  getDetails() {
    if (this.props.content_type === 'year') {
      return null;
    }

    const title = this.props.title || this.props.headline;

    return (
      <React.Fragment>
        <CardLabel {...this.props} readAria={`${title} : list item`} />
        <h3>{title}</h3>
      </React.Fragment>
    );
  }

  render() {
    const classNames = cx(`card card--live content-type-${this.props.type}`, {
      'is-placeholder': this.props.isPlaceholder,
      'is-focused': this.props.focused,
      offleft: this.props.offleft
    });

    if (this.props.isPlaceholder) {
      return (
        <div ref={this.ref} className={classNames}>
          <div className="card__inner">
            <div className="card__inner__thumb">
              <div className="image-wrapper image-wrapper--loading" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={this.ref} className={classNames} role="button">
        <div className="card__inner">
          <div className="card__inner__thumb">{this.getThumbnail()}</div>
          <div className="card__inner__details">{this.getDetails()}</div>
        </div>
      </div>
    );
  }
}
