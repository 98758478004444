export default function (item) {
  let parentId;
  try {
    parentId = item.parent_id;
    if (!parentId) {
      try {
        parentId = item.links[0].id;
      } catch (_) {
        return;
      }
    }
  } catch (_) {
    return;
  }

  return parentId;
}
