import Reflux from 'reflux';
import { analyticsAddImpressionBatch } from './appkitAnalyticsStore';
import { configDataStore, configDataActions } from './configDataStore';
import _ from 'lodash';

export const iTrackingActions = Reflux.createActions({
  addToBatch: {},
  sendBatchFor: {},
  sendAllBatches: {}
});

export const iTrackingStore = Reflux.createStore({
  listenables: iTrackingActions,

  state: {
    inView: {}
  },

  init: function () {
    this.listenTo(configDataActions.setConfigData, this.initITrackingStore);
  },

  initITrackingStore: function () {
    const analyticsEventsDisabled = configDataStore.getConstant('analytics_events_disabled') || [];
    this.state.isImpressionTrackingDisabled = _.find(
      analyticsEventsDisabled,
      (i) => i === 'module_impression'
    );

    if (this.state.isImpressionTrackingDisabled) {
      console.info('Impression Tracking Disabled');
    }
  },

  getInitialState: function () {
    return this.state;
  },

  addToBatch: function (itemToAdd) {
    if (this.state.isImpressionTrackingDisabled) {
      return;
    }

    if (!itemToAdd) return;
    const row = this.state.inView[itemToAdd.row];
    if (row && row.items) {
      if (_.find(row.items, (item) => item.index === itemToAdd.index)) return;
    }

    const updatedRow = {
      largest: row && row.largest && row.largest >= itemToAdd.index ? row.largest : itemToAdd.index,
      items: row && row.items && row.items.length ? [...row.items, itemToAdd] : [itemToAdd]
    };

    this.state.inView[itemToAdd.row] = updatedRow;
  },

  sendBatchFor: function (row) {
    if (this.state.isImpressionTrackingDisabled) {
      return;
    }
    analyticsAddImpressionBatch(this.state.inView[row] && this.state.inView[row].items, 'view');

    this.state.inView[row] = null;
  },

  sendAllBatches: function () {
    if (this.state.isImpressionTrackingDisabled) {
      return;
    }
    let itemsToSend = [];
    // eslint-disable-next-line
    for (const property in this.state.inView) {
      const batch = this.state.inView[property];
      if (batch && batch.items.length) {
        itemsToSend = itemsToSend.concat(batch.items);
      }
    }
    analyticsAddImpressionBatch(itemsToSend, 'click');

    this.state.inView = {};
  }
});
