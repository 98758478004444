import Reflux from 'reflux';
import api from '../services/api.js';
import { videoTimeProxyStore } from './videoTimeProxyStore';
import { consumptionStore } from './consumptionStore';
import { assetMetadataStore } from './assetMetadataStore';
import { configDataStore } from './configDataStore';
import { playlistStore } from './playlistStore.js';
import hls from '../utils/hls_parser.js';
import { parse } from 'mpd-parser';
import PlatformUtils from '../utils/platform.js';
import _ from 'lodash';

export const manifestActions = Reflux.createActions({
  setIsDVR: {},
  setCaptionAndAudioData: {}
});

export const manifestStore = Reflux.createStore({
  listenables: manifestActions,
  state: {
    captionAndAudioData: {}
  },

  init: function () {
    this.listenTo(videoTimeProxyStore, this.trackSource);
  },

  setIsDVR: function () {
    this.trigger(this.state);
  },

  setCaptionAndAudioData: function (data, isDash) {
    console.info('Got manifest data', data, isDash);
    this.state.captionAndAudioData = data;
    this.state.isDash = isDash;
    this.trigger(this.state);
  },

  checkHlsPlaylistType: async function (parsedHLS) {
    let hlsManifestIsDVR = false;
    // This is for determining whether a stream is DVR or not
    if (parsedHLS.variants && parsedHLS.variants.length) {
      let firstVariantData = await api.getRaw(parsedHLS.variants[0].url);
      let parsedChildPlaylist = await hls.findPlaylistType(firstVariantData);
      const { playlistType } = parsedChildPlaylist;
      console.log('Setting DVR type');
      manifestStore.state.hlsManifestIsDVR = playlistType ? playlistType === 'EVENT' : false;
      if (this.state.hlsManifestIsDVR) {
        manifestActions.setIsDVR();
        hlsManifestIsDVR = true;
      }
    }
    return hlsManifestIsDVR;
  },

  setIfAssetIsDVR: async function (id) {
    try {
      if (
        !consumptionStore.state.isLinear &&
        !(
          !PlatformUtils.doesNotSupportDASH() &&
          assetMetadataStore.state.metadata &&
          assetMetadataStore.state.metadata.dash_available
        )
      ) {
        manifestStore.state.currentManifestType = 'HLS';
        let result = await api.getManifestData(id);
        const parsedHLS = hls.findSubtitlesAndAudioTracks(result);
        manifestActions.setCaptionAndAudioData(parsedHLS, false);
        let checkHlsPlaylistType = await this.checkHlsPlaylistType(parsedHLS);
        return checkHlsPlaylistType && playlistStore.isLive();
      }
      return false;
    } catch (err) {
      console.log('manifestStore error isDvr', err);
      manifestActions.setCaptionAndAudioData(null, false);
      return false;
    }
  },

  trackSource: function (state) {
    if (state.data.currentSrc === 'clear' || state.data.currentSrc !== this.state.currentSrc) {
      this.state.currentSrc = state.data.currentSrc;
      let id = consumptionStore.state.currentAssetID;
      if (!id || id === 'unknown') {
        console.warn('No currentAssetID, returning');
        return false;
      }

      if (consumptionStore.state.isLinear) {
        this.state.hlsManifestIsDVR = false;
        // No need to load audio or subtitle info for linear
        manifestActions.setCaptionAndAudioData(null, false);
        // TODO: Check this via the URL
        this.state.currentManifestType = 'HLS'; //now all linear is HLS
      } else if (
        !PlatformUtils.doesNotSupportDASH() &&
        assetMetadataStore.state.metadata &&
        assetMetadataStore.state.metadata.dash_available
      ) {
        this.state.hlsManifestIsDVR = false;
        api.getManifestData(id, true).then((dashData) => {
          this.state.currentManifestType = 'DASH';
          const parsedDash = parse(dashData);
          // Modify to match existing HLS structure
          const modifiedParsedDash = {
            isDash: true,
            subtitles: [],
            audioTracks: []
          };
          if (parsedDash.mediaGroups) {
            if (parsedDash.mediaGroups.SUBTITLES && parsedDash.mediaGroups.SUBTITLES.subs) {
              Object.keys(parsedDash.mediaGroups.SUBTITLES.subs).forEach((langCode, idx) => {
                let subtitle = parsedDash.mediaGroups.SUBTITLES.subs[langCode];
                modifiedParsedDash.subtitles.push({
                  name: subtitle.language,
                  languageCode:
                    subtitle.playlists &&
                    subtitle.playlists[0] &&
                    subtitle.playlists[0].attributes &&
                    subtitle.playlists[0].attributes.NAME,
                  url:
                    subtitle.playlists &&
                    subtitle.playlists[0] &&
                    subtitle.playlists[0].resolvedUri,
                  originalIndex: idx,
                  forced: subtitle.language && subtitle.language.includes('FNS')
                });
              });
            }

            if (parsedDash.mediaGroups.AUDIO && parsedDash.mediaGroups.AUDIO.audio) {
              const dashAudioTracks = Object.keys(parsedDash.mediaGroups.AUDIO.audio);
              if (dashAudioTracks.length > 0) {
                const appLanguageConfig = configDataStore.getConstant('languages') || [];
                dashAudioTracks.forEach((langCode, idx) => {
                  let audio = parsedDash.mediaGroups.AUDIO.audio[langCode];
                  const languageName = _.find(appLanguageConfig, (langItem) => {
                    return (
                      langItem['iso-639-2B'] === audio.language ||
                      langItem['iso-639-1'] === audio.language
                    );
                  });
                  modifiedParsedDash.audioTracks.push({
                    name: languageName && languageName.name,
                    languageCode: audio.language,
                    url: null,
                    originalIndex:
                      audio.playlists &&
                      audio.playlists[0] &&
                      audio.playlists[0].attributes &&
                      audio.playlists[0].attributes.NAME
                  });
                });
              }
            }
          }

          manifestActions.setCaptionAndAudioData(modifiedParsedDash, true);
        });
      } else {
        this.setIfAssetIsDVR(id);
      }
    }
  }
});
