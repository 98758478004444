import Reflux from 'reflux';

export const scheduleActions = Reflux.createActions({
  reset: {},
  updatePositionIndex: {}
});

export const scheduleStore = Reflux.createStore({
  listenables: scheduleActions,
  state: {
    focusPosition: 0
  },

  getInitialState: function () {
    return this.state;
  },

  reset: function () {
    this.state = {
      focusPosition: 0
    };
    this.trigger(this.state);
  },

  updatePositionIndex: function (index = 0) {
    this.state.focusPosition = index;
    this.trigger(this.state);
  }
});
