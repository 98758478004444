import Reflux from 'reflux';
import PlatformUtils from '../utils/platform.js';
import _ from 'lodash';
import conf from '../conf';

export const ariaTTSServiceActions = Reflux.createActions({
  readText: {},
  speak: {}
});

let badTizen = false;

export const ariaTTSServiceStore = Reflux.createStore({
  listenables: ariaTTSServiceActions,

  init: function () {
    window.tts = this;
  },

  state: {
    textRead: '',
    lastTextRead: '',
    textAry: [],
    busy: false,
    currentlyReading: {},
    isCancelling: false,
    ttsVoiceLang: 'en-US'
  },

  utterance: null,

  readText(rawText, cannotBeCancelled, forceCancel) {
    if (!PlatformUtils.isTizen || !rawText || conf.appNamespace === 'servustv') return;
    if (badTizen) return;

    /* global webapis:true */
    // replace 'S2 E12' with 'season 2 episode 12'
    let textRead = rawText && rawText.replace(/s(\d{1,2})\se(\d{1,2})/gi, 'season $1 episode $2');

    var isTTSSupported = false;
    try {
      // throws an exception on older tizen device.
      isTTSSupported = webapis.tvinfo.getMenuValue(webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY);
    } catch (ex) {
      badTizen = true;
    }

    if (!isTTSSupported) return;

    let override = !this.state.currentlyReading.cannotBeCancelled || forceCancel;
    let item = { textRead, cannotBeCancelled, forceCancel };

    if (override) {
      this.state.isCancelling = true;

      speechSynthesis.cancel();
      this.state.textAry = [];
      this.state.busy = false;
      this.state.lastTextRead = '';
    } else {
      // console.log("2222222   NOT CANCELING");
    }

    if (this.state.lastTextRead !== textRead) {
      let { textAry } = this.state;
      if (textAry.length === 0) textAry.push(item);
      else if (textAry.length === 1) textAry[0] = item;
    }

    if (!this.state.busy) {
      if (this.state.textAry.length === 1) {
        //   console.log("5555555    calling speak from read");
        if (override) {
          /* safari throws errors and aborts some reads unless we
           * allow for enough delay between cancel and starting the next
           * read. 300ms seems to do the trick */
          this.speakNext(null, 150);
        } else {
          this.speakNext(null, 0);
        }
      }
    } else {
      // console.log(">>>>>>> BUSY");
    }
  },

  speak(text, callback) {
    console.log('666666666   speaking - ' + text.substr(0, 50));

    if (this.state.lastTextRead === text) {
      // console.log("99999999 ignoring duplicate speech.");
      callback();
      return;
    }

    this.state.lastTextRead = text;
    this.state.busy = true;
    this.utterance = new SpeechSynthesisUtterance();
    this.utterance.text = text;
    this.utterance.lang = this.state.ttsVoiceLang.replace('-', '_');
    this.state.isCancelling = false;
    // Required for 2018,
    if (speechSynthesis.getVoices && speechSynthesis.getVoices().length > 0) {
      var maleVoice = speechSynthesis.getVoices().filter((voice) => {
        console.log(
          voice.name.toLowerCase(),
          voice.lang,
          'this.utterance.lang=',
          this.utterance.lang
        );
        return voice.name.toLowerCase() === 'male' && voice.lang === this.utterance.lang;
      });

      this.utterance.voice = maleVoice[0];
    } else {
      // this worked on legacy platforms kept in as a fallback
      this.utterance.voice = 'Male';
    }

    this.utterance.onend = () => {
      if (this.state.isCancelling) {
        // console.log("SPEECH ON END WHILE CANCELLING ");
        return;
      }
      // console.log("SPEECH ON END - " + that.state.textAry.length);
      if (callback) {
        callback();
      }
    };

    this.utterance.onerror = (e) => {
      // console.error("SPEECH ERROR  - SETTING BUSY = FALSE", e);

      if (this.state.isCancelling) {
        //   console.log("SPEECH ERROR WHILE CANCELLING ");
        return;
      }

      if (callback) {
        callback(e);
      }
    };

    speechSynthesis.speak(this.utterance);
  },

  counter: 0,

  speakNext(ignored, delay = 1000) {
    let nextItem = this.state.textAry.shift();

    // use a unique number to identify this speech event
    this.counter++;
    let counter = this.counter;

    if (nextItem) {
      //  console.log("777777777  speech callback calling next");
      this.state.busy = true;
      this.state.currentlyReading = nextItem;
      setTimeout(() => {
        // if another speech event began during our timeout, bail
        if (counter !== this.counter) {
          //       console.log("counter out of sync, not speaking.");
          return;
        }

        //     console.log("88888888 calling speak from speakNext");
        this.speak(this.state.currentlyReading.textRead, this.speakNext);
      }, delay);
    } else {
      //   console.log("777777777   setting busy = false");
      this.state.busy = false;
    }
  },

  setTTSVoice(langCode) {
    if (!PlatformUtils.isTizen) return;
    try {
      const deviceLanguage = PlatformUtils.getDeviceLanguage();
      const splitDeviceLang = PlatformUtils.getModifiedDeviceLanguage();
      const availableVoices = speechSynthesis.getVoices();
      let deviceLanguageIsSupported = _.findIndex(availableVoices, (voice) => {
        let voiceLang = voice.lang && voice.lang.replace('_', '-');
        return voiceLang === deviceLanguage;
      });
      if (deviceLanguageIsSupported !== -1) {
        this.state.ttsVoiceLang = availableVoices[deviceLanguageIsSupported].lang;
      } else {
        deviceLanguageIsSupported = _.findIndex(availableVoices, (voice) => {
          let voiceLang = voice.lang && voice.lang.replace('_', '-');
          let splitVoiceLang = voiceLang.split('-')[0];
          return splitVoiceLang === splitDeviceLang;
        });
        if (deviceLanguageIsSupported !== -1) {
          // If the first part of the device language matches the first part of a voice language, use that (ie matching 'de-CH' to 'de-DE')
          this.state.ttsVoiceLang = availableVoices[deviceLanguageIsSupported].lang;
        }
      }
    } catch (e) {
      console.error('Error setting TTS voice', e);
    }
  }
});
