import Reflux from 'reflux';
import PlatformUtils from '../utils/platform.js';

export const appActions = Reflux.createActions([
  'showBuffering',
  'hideBuffering',
  'setDeviceAudioVideoAttributes',
  'setPlayerUsed',
  'setAuthToken'
]);

const isMagenta = PlatformUtils.isMagenta;

export const appStore = Reflux.createStore({
  listenables: appActions,

  state: {
    buffering: false,
    uid: PlatformUtils.sharedPlatform.uid(),
    inactivityTimeout: null,
    authToken: null,
    deeplinkAuthToken: null,

    // anonymouse session id vars (used for google analytics)
    lastRequestTime: new Date().getTime(),
    lastSessionTime: new Date().getTime(),
    sessionRefreshTime: 3.5 * 60 * 60 * 1000, // 3.5 hours
    sessionInactivityTime: 30 * 60 * 1000, // 30 min

    playerUsed: 'html5'
  },

  getInitialState: function () {
    return this.state;
  },

  onShowBuffering: function () {
    this.state.buffering = true;
    this.trigger(this.state);
  },

  onHideBuffering: function () {
    this.state.buffering = false;
    this.trigger(this.state);
  },

  setAuthToken: function (authObj) {
    this.authToken = authObj.token;
    this.countryCode = authObj.country_code;
    this.sessionLocale = authObj.locale;
  },

  getCountryCode: function () {
    return this.countryCode;
  },

  getSessionLocale: function () {
    return this.sessionLocale;
  },

  setDeeplinkAuthToken: function (authObj) {
    this.deeplinkAuthToken = authObj.token;
  },

  getAuthToken: function () {
    if (this.authToken) {
      return this.authToken;
    } else {
      console.error('Auth token not set yet, start API request not done yet');
      return null;
    }
  },

  getDeeplinkAuthToken: function () {
    if (this.deeplinkAuthToken) {
      return this.deeplinkAuthToken;
    } else {
      return null;
    }
  },

  setDeviceAudioVideoAttributes: function (attribs) {
    if (!attribs) return;
    const AVAttributes = {
      category: attribs.category || null,
      os_family: attribs.os_family || null,
      model: attribs.model || null,
      os_version: attribs.os_version || null,
      codec: isMagenta ? null : attribs.codec || null,
      format: isMagenta ? '720p30' : attribs.format || null,
      quality: isMagenta ? null : attribs.quality || null,
      locale: attribs.locale || this.sessionLocale || 'en'
    };
    this.state = Object.assign(this.state || {}, { deviceAVAttributes: AVAttributes });
    this.trigger(this.state);
  },

  setPlayerUsed: function (value) {
    this.state = Object.assign(this.state || {}, { playerUsed: value });
    this.trigger(this.state);
  },

  getDeviceAudioVideoAttributes: function () {
    if (this.state.deviceAVAttributes) {
      return this.state.deviceAVAttributes;
    } else {
      return null;
    }
  }
});
