import React from 'react';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import getParentId from '../../utils/get-parent-id';
import { getTitleTreatment, getThumbnail } from '../../utils/api-image-url';
import BaseCard from '../card/base-card';
import CardLabel from '../card-label/card-label';
import CardThumbnail from '../card-thumbnail/card-thumbnail';
import FeaturedCardSmallTitle from './featured-card-small-title';

const ALLOWED_LABELS = ['live', 'unavailable', 'watching', 'pre'];

export default class FeaturedCardSmall extends BaseCard {
  constructor(p) {
    super(p);
    this.state = {
      titleTreatment: null,
      artwork: null
    };
    this.ref = React.createRef();
    this.setArtworks = this.setArtworks.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.setArtworks();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      this.props.id !== prevProps.id ||
      !isEqual(this.props.resources, prevProps.resources) ||
      (!this.props.isPlaceholder && prevProps.isPlaceholder)
    ) {
      this.setArtworks();
    }
  }

  setArtworks() {
    const { content_type, id, resources } = this.props;
    const parentId = content_type === 'episode' && getParentId(this.props);
    const artwork = getThumbnail(resources, id, 'featured-small');
    const titleTreatment = getTitleTreatment(resources, parentId || id);
    this.setState({
      artwork,
      titleTreatment
    });
  }

  render() {
    const classNames = cx('card card--featured-small', {
      'is-placeholder': this.props.isPlaceholder,
      'is-focused': this.props.focused,
      offleft: this.props.offleft
    });

    if (this.props.isPlaceholder) {
      return (
        <div ref={this.ref} className={classNames}>
          <div className="card__inner">
            <div className="card__inner__thumb">
              <div className="image-wrapper image-wrapper--loading" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={this.ref} className={classNames} role="button">
        <div className="card__inner">
          <div className="card__inner__thumb">
            <CardThumbnail src={this.state.artwork} />
          </div>
          {this.state.titleTreatment && (
            <div className="card__inner__thumb__title-treatment">
              <img src={this.state.titleTreatment} />
            </div>
          )}
          <CardLabel
            {...this.props}
            allowed={ALLOWED_LABELS}
            readAria={`${this.props.title} : ${this.props.subheading} : list item`}
          />
        </div>
        {this.props.focused && <FeaturedCardSmallTitle title={this.props.title} />}
      </div>
    );
  }
}
