import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import _ from 'lodash';

let isInit = false;
/**
 * Initialize the error reporting library
 * @param key {string} Api key used by the error reporting library
 * @param appVersion {string} App version number, used to group errors
 * @param options Configuration options passed to the error reporting library
 *
 */
export function init(apiKey, appVersion, options = {}) {
  if (isInit) {
    throw new Error('init can only be run once');
  }
  isInit = true;

  Bugsnag.start({
    apiKey,
    appVersion,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: options.tags.env,
    metadata: { custom: { ...options.tags } },
    notifyHandler: 'xhr'
    // onError: (event) => { console.info('Received error:', error); }
    // enabledReleaseStages: ['release','production'],
  });

  console.log('error-reporter', 'init', arguments);
  // bugsnag will only send the first 10 events from a page
  // see Bugsnag.refresh() for more information
  return Bugsnag;
}

/**
 * Add extra tags
 * @param tags An object with the new tags to be included
 */
export function addTags(tags) {
  if (Bugsnag) {
    Bugsnag.addMetadata('custom', { ...tags });
  }
}

/**
 * Manually log an exception
 *
 * @param {error} err An exception to be logged
 */
export function captureException(err, message, data) {
  console.warn('captureException:', err, message);
  if (!_.isError(err)) {
    err = new Error(message);
  }
  if (Bugsnag) {
    Bugsnag.notify(err, (event) => {
      event.addMetadata('errorMsg', {
        message,
        err,
        data
      });
    });
  }
}

/**
 * Manually log a message
 * @param {string} msg A plain text message to be captured in Bugsnag
 * @param level The log level associated with this event. Default: error
 */
export function captureMessage(name, msg, data, level = 'error') {
  console.info('error-reporter', 'captureMessage', level);
  if (Bugsnag) {
    Bugsnag.notify(new Error(name), (event) => {
      event.addMetadata('messageData', {
        msg,
        data,
        level
      });
    });
  }
}

export default {
  init,
  captureException,
  captureMessage,
  addTags
};
