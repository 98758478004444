import _ from 'lodash';
import API from '../services/api';
import CollectionServiceDelegate from './collectionServiceDelegate';
import { paginationStore } from '../reflux/paginationStore';
import { configDataStore } from '../reflux/configDataStore';
import ErrorReporter from '../utils/error-reporter';

export default class ProductServiceDelegate {
  static getProductData(productID) {
    return API.getProduct(productID)
      .then(ProductServiceDelegate.onSuccess)
      .catch(ProductServiceDelegate.onError);
  }

  static getHydratedProductData(productID) {
    // RBMN-9695: This is a hack for home name change to work
    if (productID === 'home') productID = 'discover';
    let result = API.getProduct(productID).then((product) => {
      return this.hydrateUnhydratedProduct(product);
    });
    return result.then(ProductServiceDelegate.onSuccess).catch(ProductServiceDelegate.onError);
  }

  static getCollectionError(err, coll) {
    ErrorReporter.captureException(err, 'getCollectionData failed');
    return { ...coll, items: [], meta: {} };
  }

  static hydrateUnhydratedProduct(product) {
    // RBMN-9695: This is a hack for home name change to work
    if (product.id === 'discover') {
      product.id = 'home';
      try {
        const referenceCollections = configDataStore.getConstant('reference_collections') || {};
        const referenceCollectionsIds = _.keys(referenceCollections);
        product.collections = product.collections
          .filter((coll) => {
            if (coll.request_data === undefined) {
              return true;
            }

            return _.includes(referenceCollectionsIds, coll.id);
          })
          .map((coll) => {
            if (coll.request_data === undefined) {
              return coll;
            }

            const referenceCollection = referenceCollections[coll.id];

            const railSettings = {
              homeRailId: referenceCollection?.type || 'recommender'
            };

            if (referenceCollection?.type === 'hero_channels') {
              railSettings.homeRailAction = referenceCollection?.action;
            }

            if (referenceCollection?.type === 'hero_cards') {
              railSettings.homeRailAction = 'linear';
              railSettings.badgeType = referenceCollection?.badge_type;
              railSettings.rowTypeUIOverride = 'page';
              railSettings.cardTypeUIOverride = 'page';
              railSettings.disableFavorite = true;
            }

            return {
              ...coll,
              ...railSettings
            };
          });
      } catch (e) {
        console.error(e);
      }
    }

    if (product.collections) {
      let colls = Promise.allSettled(
        product.collections.map((collection) =>
          CollectionServiceDelegate.getCollectionData(collection).catch((err) =>
            this.getCollectionError(err, collection)
          )
        )
      );
      return colls.then((collections) => {
        collections = collections.map(({ value }) => value);
        collections.forEach((collection) => {
          paginationStore.storePageByCollectionId(collection.id, collection, 1); // TODO: Investigate. leads to double set of collection
        });

        product.collections = collections;
        return product;
      });
    }
    return Promise.resolve(product);
  }

  static onSuccess(result) {
    return new Promise(function (resolve, reject) {
      resolve(result);
    });
  }

  static onError(err) {
    return new Promise(function (resolve, reject) {
      let error = { errorCode: err };
      reject(error);
    });
  }
}
