/* globals tizen, webapis */

import BasePlatform from './base.js';
import ErrorReporter from '../../error-reporter';

/**
 * Samsung platform
 */
export default class SamsungPlatform extends BasePlatform {
  /**
   * this platform does not need a proxy.
   */
  constructor() {
    super();
    this.needsProxy = false;
    this.previousVolume = null;
    this.ls = typeof window !== 'undefined' ? this.ls : null;
    this.deviceLanguage = 'en-us';

    this.isFetchingDeviceLanguage = true;
    try {
      tizen.systeminfo.getPropertyValue(
        'LOCALE',
        (systemInfoLocale) => {
          const lng = (systemInfoLocale.language || '').trim().toLowerCase();
          if (lng) {
            console.log('Got system language:', lng);
            this.deviceLanguage = lng;
            this.isFetchingDeviceLanguage = false;
          }
        },
        (err) => {
          console.log('Failed to get system language', err);
          this.isFetchingDeviceLanguage = false;
        }
      );
    } catch (e) {
      console.error('Cannot use `tizen.systeminfo.getPropertyValue`', e);
      this.isFetchingDeviceLanguage = false;
    }

    if (webapis.bixby) {
      // Set connection to Bixby client - NOTE: this has a remove in EXIT call below
      try {
        webapis.bixby.initialize();
        console.info('Bixby initialized');
      } catch (e) {
        ErrorReporter.captureException(e, 'Bixby present but failed to init');
      }
    }
    try {
      this.tizenVersion = tizen.systeminfo.getCapability(
        'http://tizen.org/feature/platform.version'
      );
      console.log('TIZEN VERSION:', this.tizenVersion);
      this.AVAttributes = {
        category: 'smart_tv',
        os_family: 'tizen',
        model: webapis.productinfo.getRealModel() || null,
        os_version: this.tizenVersion || null,
        format: webapis.productinfo.is8KPanelSupported()
          ? '4320p60'
          : webapis.productinfo.isUdPanelSupported()
          ? '2160p60'
          : '1080p30',
        quality: 'HLG,HDR10,HDR10+',
        codec: webapis.systeminfo
          ? ['H264', 'H265', 'H263', 'AC3', 'AAC', 'WMA']
              .filter(
                (codec) =>
                  webapis.systeminfo.isSupportedVideoCodec(codec) ||
                  webapis.systeminfo.isSupportedAudioCodec(codec)
              )
              .toString()
          : null,
        locale: this.deviceLanguage || 'en'
      };
    } catch (error) {
      console.log('Tizen error code = ' + error.code, error);
    }
  }

  /**
   * samsung platform
   * @returns {string}
   */

  name() {
    return {
      name: 'Samsung',
      variation: 'Tizen'
    };
  }

  localStorage() {
    if (this.ls) return this.ls;
    else return super.localStorage();
  }

  /**
   * exit to menu
   * @params {boolean} [toMenu = false] - whether to exit to menu or TV.
   */
  exit(toMenu = false) {
    if (toMenu) {
      tizen.application.getCurrentApplication().hide();
    } else {
      tizen.application.getCurrentApplication().exit();
    }
    if (webapis.bixby) {
      try {
        webapis.bixby.deinitialize();
      } catch (e) {
        ErrorReporter.captureException(e, 'Bixby present but failed to set action listeners');
      }
    }
  }

  /**
   * Samsung initialization
   * @params {function} [callback] callback to be called once init is done.
   */
  init(callback) {
    console.info('Init Samsung Tizen platform');

    tizen.tvinputdevice.registerKey('0');
    tizen.tvinputdevice.registerKey('1');
    tizen.tvinputdevice.registerKey('2');
    tizen.tvinputdevice.registerKey('3');
    tizen.tvinputdevice.registerKey('4');
    tizen.tvinputdevice.registerKey('5');
    tizen.tvinputdevice.registerKey('6');
    tizen.tvinputdevice.registerKey('7');
    tizen.tvinputdevice.registerKey('8');
    tizen.tvinputdevice.registerKey('9');
    tizen.tvinputdevice.registerKey('MediaPlay');
    tizen.tvinputdevice.registerKey('MediaPause');
    tizen.tvinputdevice.registerKey('MediaFastForward');
    tizen.tvinputdevice.registerKey('MediaRewind');
    tizen.tvinputdevice.registerKey('MediaStop');

    document.addEventListener('visibilitychange', () => {
      // fired for both hidden and exit commands
      if (document.hidden) {
        if (this.previousVolume) this.setVolume(this.previousVolume);
      } else {
        // Behavior when application is resumed
      }
    });

    if (this.isFetchingDeviceLanguage) {
      const interval = setInterval(() => {
        if (!this.isFetchingDeviceLanguage) {
          clearInterval(interval);

          if (typeof callback === 'function') callback();
        }
      }, 100);
    }
  }

  disableScreenSaver() {
    if (typeof webapis !== 'undefined') {
      console.info('On Samsung Tizen, disabling screensaver');
      webapis.appcommon.setScreenSaver(
        webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF
      );
    } else {
      console.error('Tizen TV webapis are not loaded can not disable screensaver');
    }
  }

  deviceId() {
    return webapis.productinfo.getDuid();
  }

  model() {
    return webapis.productinfo.getModel();
  }

  firmware() {
    try {
      return webapis.productinfo.getFirmware();
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  supportsHDR() {
    return webapis.avinfo.isHdrTvSupport();
  }

  getVolume() {
    return tizen.tvaudiocontrol.getVolume() / 100;
  }

  setVolume(value, restoreVolumeOnExit) {
    if (this.model().search('UBD') > -1) {
      console.warn('BDP - do not set volume');
      return;
    }

    let isTTSSupported = false;
    try {
      // throws an exception on older tizen device.
      isTTSSupported = webapis.tvinfo.getMenuValue(webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY);
    } catch (ex) {}

    // we don't want to adjust volume when reading TTS
    if (isTTSSupported) return;

    this.previousVolume = restoreVolumeOnExit || null;
    tizen.tvaudiocontrol.setVolume(value * 100);
  }

  getDeviceLanguage() {
    return this.deviceLanguage;
  }

  areCaptionsEnabled() {
    const captionsEnabled = webapis.tvinfo.getMenuValue(
      webapis.tvinfo.TvInfoMenuKey.CAPTION_ONOFF_KEY
    );
    return captionsEnabled === 1;
  }

  getDeviceAudioVideoAttributes() {
    return this.AVAttributes;
  }
}
